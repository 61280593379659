import { useCallback } from 'react';
import { useRouter } from './routing/useRouter';

export const useRemoveQueryParams = (paramsToRemove: readonly string[]) => {
  const { replaceParam } = useRouter();
  return useCallback(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const { searchParams } = new URL(window.location.href);
    const paramsToUpdate = paramsToRemove
      .filter((name) => {
        return searchParams.has(name);
      })
      .map((name) => {
        return {
          name,
          value: undefined,
          silent: true,
        };
      });
    if (paramsToUpdate.length > 0) {
      return replaceParam(...paramsToUpdate);
    }
  }, [paramsToRemove, replaceParam]);
};

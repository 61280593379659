import { useRemoveQueryParams } from './useRemoveQueryParams';

export const AUTH_QUERY_PARAMS = [
  'apiKey',
  'mode',
  'oobCode',
  'continueUrl',
  'code',
  'customProvider',
  'email',
  'lang',
] as const;

export const useRemoveAuthQueryParams = () => {
  return useRemoveQueryParams(AUTH_QUERY_PARAMS);
};

import { useContext } from 'react';
import { memo } from '../../../util/memo';
import { PayoutSummary } from '../../tournaments/PayoutSummary';
import { TournamentContext } from '../../../contexts/docs/TournamentContext';
import { GradientTypographyProps } from '../../gradients/GradientTypography';
import { useCurrency } from '../../../contexts/CurrencyContext';

export type TournamentPayoutProps = Omit<
  GradientTypographyProps,
  'gradientColor'
>;

const TournamentPayoutUnmemoized = (props: TournamentPayoutProps) => {
  const { payouts, payoutGiveaway, bracketType } =
    useContext(TournamentContext);

  const { formatTotalPayout, formatPayout, exchangeRatesReady } = useCurrency();
  const totalPayout = formatTotalPayout(payouts, bracketType);

  const giveawayPayout = formatPayout(payoutGiveaway);

  const {
    amountFiat: payoutAmount,
    countIlliquid: payoutIlliquidItems,
    countUnassured: payoutCountUnassured,
  } = totalPayout;
  const {
    amountFiat: giveawayAmount,
    countIlliquid: giveawayIlliquidItems,
    countUnassured: giveawayCountUnassuredItems,
  } = giveawayPayout;
  const amountFiat = payoutAmount + giveawayAmount;
  const countIlliquid = payoutIlliquidItems + giveawayIlliquidItems;
  const countUnassured = payoutCountUnassured + giveawayCountUnassuredItems;

  if (!exchangeRatesReady) {
    // TODO: Remove temporary fix until we inject exchange rates from SSR
    return <></>;
  }

  return (
    <PayoutSummary
      amountFiat={amountFiat}
      countIlliquid={countIlliquid}
      countUnassured={countUnassured}
      {...props}
    />
  );
};

export const TournamentPayout = memo(TournamentPayoutUnmemoized);

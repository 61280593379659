import Box from '@mui/material/Box';
import { useAuth } from 'src/contexts/AuthContext';
import { SignUpButton } from './SignUpButton';
import { useMemo } from 'react';
import { SignInDropdown } from './SignInDropdown';
import { LoadingWrapper } from '../LoadingWrapper';

export function UserButton() {
  const { uid, isSubscribingUserData, isUserDeleted = false } = useAuth();

  const userButton = useMemo(() => {
    return (
      <LoadingWrapper
        isLoading={isSubscribingUserData || isUserDeleted}
        wrapperSx={{ height: '36px', width: '36px' }}
      >
        {!uid ? <SignUpButton /> : <SignInDropdown />}
      </LoadingWrapper>
    );
  }, [isSubscribingUserData, uid, isUserDeleted]);

  return <Box>{userButton}</Box>;
}

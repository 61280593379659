import { deploymentUrlSchemed } from '../../../functions/src/util/deploymentUrl';

export const MATCH_DIALOG_KEY = 'match-dialog';
export const SESSION_DIALOG_KEY = 'session-dialog';
export const MATCH_ENTRY_KEY = 'match-entry';

export const formScoringUrl = (
  gameId?: string,
  tournamentId?: string,
  matchId?: string,
  sessionIndex?: number,
) => {
  const eventPath = `Game/${gameId}/Tournament/${tournamentId}`;
  return `${deploymentUrlSchemed()}?event=${eventPath}&tournamentTab=bracket&${MATCH_DIALOG_KEY}=${matchId}&${SESSION_DIALOG_KEY}=${sessionIndex}&${MATCH_ENTRY_KEY}=scoring`;
};

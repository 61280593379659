import { AvatarNextProps } from '../../components/avatar/AvatarNext';

const transformValue = (
  value: string | number,
  operation: (n: number) => number,
): string => {
  const numValue = typeof value === 'string' ? parseFloat(value) : value;
  return `${operation(numValue)}px !important`;
};

export const adjustSize = (
  size: AvatarNextProps['width'] | AvatarNextProps['height'],
  operation: (n: number) => number,
) => {
  if (!size) {
    return;
  }

  if (typeof size === 'object') {
    return Object.entries(size).reduce((acc, [breakpoint, value]) => {
      return {
        ...acc,
        [breakpoint]: transformValue(value, operation),
      };
    }, {});
  }

  return transformValue(size, operation);
};

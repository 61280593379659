import Stack from '@mui/material/Stack';
import { memo } from '../../util/memo';
import { GradientTypography } from 'src/components/gradients/GradientTypography';
import { useDateFormatter } from 'src/hooks/useDateFormatter';
import { WEEKDAY_MONTH } from 'src/util/dates/presets/dates';

export type CalendarDateProps = {
  date: Date;
};

const CalendarDateUnmemoized: React.FC<CalendarDateProps> = ({ date }) => {
  const format = useDateFormatter({
    dateFormat: WEEKDAY_MONTH,
    timeFormat: false,
    separator: '',
  });
  const dateFormatted = format(date);
  return (
    <Stack
      sx={{
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        px: 2,
      }}
    >
      <GradientTypography
        variant={'subtitle1'}
        textTransform={'uppercase'}
        justifyContent="center"
      >
        {dateFormatted}
      </GradientTypography>
    </Stack>
  );
};

export const CalendarDate = memo(CalendarDateUnmemoized);

import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded';
import Typography from '@mui/material/Typography';
import { AccordionSummaryCompetitor } from './AccordionSummaryCompetitor';
import { AccordionDetailsCompetitor } from './AccordionDetailsCompetitor';
import { useAuth } from 'src/contexts/AuthContext';
import { useCompetitor } from './AccordionCompetitor';
import { memo } from '../../../../util/memo';

// eslint-disable-next-line react-memo/require-memo
const AccordionCompetitorTeamUnmemoized: React.FC = () => {
  const { participants, teamMax } = useCompetitor();
  const { uid } = useAuth();
  const isMyTeam = participants?.some((participant) => {
    return participant?.userId === uid;
  });

  return (
    <Accordion disableGutters>
      <AccordionSummaryCompetitor
        expandIcon={<ExpandMoreIcon />}
        RightChildren={
          <>
            {!!isMyTeam && (
              <Typography
                variant="overline"
                color="text.secondary"
                sx={{ alignSelf: 'center' }}
              >
                your team
              </Typography>
            )}
          </>
        }
        LeftChildren={
          <Typography
            variant="overline"
            color="text.secondary"
            sx={{ alignSelf: 'center' }}
          >
            {participants.length} / {teamMax}
          </Typography>
        }
      />
      <AccordionDetailsCompetitor />
    </Accordion>
  );
};
export const AccordionCompetitorTeam = memo(AccordionCompetitorTeamUnmemoized);

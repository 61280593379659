import { Link, LinkProps } from '../Link';
import { memo } from '../../util/memo';
import Button from '@mui/material/Button';

function ContactUsButtonUnmemoized({
  style,
  ...props
}: Omit<LinkProps, 'href'>) {
  return (
    <Link
      href="/contact"
      style={{
        textDecoration: 'none',
        ...style,
      }}
      {...props}
    >
      <Button
        variant="text"
        size="large"
        sx={{
          whiteSpace: 'nowrap',
          // display: { xs: 'none', md: 'block' },
        }}
      >
        Contact
      </Button>
    </Link>
  );
}

export const ContactUsButton = memo(ContactUsButtonUnmemoized);

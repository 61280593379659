import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { useAuth } from './AuthContext';
import { getSessionId } from '../util/session/getSessionId';
import { useDynamic } from '../hooks/useDynamic';
import { memo } from '../util/memo';
import type { SessionManager as SessionManagerType } from '../util/session/SessionManager';

type SessionContextType = {
  sessionId: string | undefined;
  disconnect: () => Promise<void>;
};

const SessionContext = createContext<SessionContextType | undefined>(undefined);

export const useSession = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error('useSession must be used within SessionProvider');
  }
  return context;
};

type SessionProviderProps = {
  children: ReactNode;
};

const SessionProviderUnmemoized = ({ children }: SessionProviderProps) => {
  const { user } = useAuth();
  const { uid, isAnonymous } = user || {};
  const sessionId = getSessionId();
  const managerRef = useRef<SessionManagerType | undefined>();

  const SessionManagerModule = useDynamic(
    import('../util/session/SessionManager'),
  );

  useEffect(() => {
    if (!SessionManagerModule) {
      return;
    }

    const { SessionManager } = SessionManagerModule;

    const setupManager = async () => {
      if (managerRef.current) {
        await managerRef.current.disconnect();
        managerRef.current = undefined;
      }

      if (uid && !isAnonymous && sessionId) {
        managerRef.current = new SessionManager(uid, sessionId);
        managerRef.current.connect();
      }
    };

    setupManager();

    return () => {
      if (managerRef.current) {
        managerRef.current.disconnect().catch(console.error);
      }
    };
  }, [SessionManagerModule, uid, isAnonymous, sessionId]);

  const disconnect = async () => {
    if (managerRef.current) {
      await managerRef.current.disconnect();
      managerRef.current = undefined;
    }
  };

  const value = useMemo(() => {
    return {
      sessionId,
      disconnect,
    };
  }, [sessionId]);

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};

export const SessionProvider = memo(SessionProviderUnmemoized);

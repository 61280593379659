import { useEffect } from 'react';
import { useUserPresence } from '../../contexts/UserPresenceContext';
import { useEngagement } from '../engagement/useEngagement';
import {
  useVisibilityObserver,
  UseVisibilityObserverParams,
} from '../visibility/useVisibilityObserver';

export type UseDetectPresenceParams<
  TElement extends HTMLElement = HTMLElement,
> = UseVisibilityObserverParams<TElement> & {
  elementId?: string;
};

export const useDetectPresence = <TElement extends HTMLElement = HTMLElement>({
  elementId,
  ...params
}: UseDetectPresenceParams<TElement>) => {
  const { addUserPresence, removeUserPresence } = useUserPresence();
  const { isIntersecting } = useVisibilityObserver(params);
  const isEngaged = useEngagement();

  useEffect(() => {
    if (!elementId) {
      return;
    }

    if (isIntersecting && isEngaged) {
      addUserPresence(elementId);
    } else {
      removeUserPresence(elementId);
    }

    return () => {
      return removeUserPresence(elementId);
    };
  }, [
    elementId,
    isEngaged,
    isIntersecting,
    addUserPresence,
    removeUserPresence,
  ]);
};

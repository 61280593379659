import { useTheme } from '@mui/material/styles';
import { normalizeGradient } from '../util/color';

export const SSR_GRADIENTS = [
  'primary',
  'secondary',
  'warning',
  'error',
  'info',
  'success',
  'royal',
];

/**
 *
 * @remarks
 * This only supports gradients that are defined in the src/styles/colors.ts file of the theme.
 */
export function useLinearGradient(gradientColor = 'primary.vertical') {
  const theme = useTheme();

  const inTheme = !gradientColor.includes('linear-gradient');
  const [section, gradient] = inTheme
    ? gradientColor.split('.')
    : [undefined, undefined];
  const gradientCss =
    section && gradient
      ? theme.palette[`${section}`][`${gradient}`]
      : gradientColor;

  return normalizeGradient(gradientCss);
}

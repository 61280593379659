import { useMemo, useCallback } from 'react';
import { useRouterState } from './routing/useRouterState';

export const SOCIAL_DRAWER_OPTIONS = ['false', 'true'] as const;
export type SocialDrawerOption = (typeof SOCIAL_DRAWER_OPTIONS)[number];

export const useSocialDrawer = () => {
  const [isOpen, setIsOpen] = useRouterState({
    key: 'drawer',
    default: 'false' as SocialDrawerOption,
  });

  const openSocialDrawer = useCallback(() => {
    setIsOpen('true' as SocialDrawerOption);
  }, [setIsOpen]);

  const closeSocialDrawer = useCallback(() => {
    setIsOpen('false' as SocialDrawerOption);
  }, [setIsOpen]);

  const isSocialDrawerOpen = useMemo(() => {
    return isOpen === ('true' as SocialDrawerOption);
  }, [isOpen]);

  return { openSocialDrawer, closeSocialDrawer, isSocialDrawerOpen };
};

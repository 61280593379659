import { useCallback } from 'react';
import { memo } from '../../../util/memo';
import { useAuth } from '../../../contexts/AuthContext';

import { ImageInputBasic } from './ImageInputBasic';

export type ImageInputProps = {
  imageProperties: {
    width: number;
    height: number;
  };
  value: string;
  onChange: (value: string) => Promise<void>;
  label?: string;
  required: boolean;
  storagePath?: string;
};

export const ImageInput = memo(function ImageInputUnmemoized({
  imageProperties,
  value,
  onChange,
  label,
  required,
  storagePath,
}: ImageInputProps) {
  const { uid } = useAuth();

  const upload = useCallback(
    async (event) => {
      if (!storagePath || !uid) return;
      const { currentTarget } = event;
      if (!currentTarget) return;
      const { files } = currentTarget;
      if (!files?.length) return;

      const [fileForUpload] = files;
      try {
        const { uploadFile: uploadImage } = await import(
          '../../../util/edit/uploadFile'
        );
        const url = await uploadImage({
          file: fileForUpload,
          storagePath,
        });
        await onChange(url);
      } catch (error) {
        console.error(error);
      }
      currentTarget.value = '';
    },
    [onChange, storagePath, uid],
  );

  return (
    <ImageInputBasic
      imageProperties={imageProperties}
      value={value}
      onUpload={upload}
      label={label}
      required={required}
    />
  );
});

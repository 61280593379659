import Typography from '@mui/material/Typography';
import MuiListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CheckCircleIcon from '@mui/icons-material/CheckCircleRounded';
import StarIcon from '@mui/icons-material/StarRounded';
import { GradientIcon } from 'src/components/gradients/GradientIcon';
import { useTheme } from '@mui/material/styles';
import { Link } from '../../../components/Link';
import { Member } from 'functions/src/types/firestore/Game/Tournament/Guestlist';
import { AvatarNext } from 'src/components/avatar/AvatarNext';
import { TypographyTruncated } from 'src/components/TypographyTruncated';

export type ParticipantProps = {
  isSolo?: boolean;
  attributes?: string[];
} & Member;

export default function Participant({
  isSolo = false,
  ...rest
}: ParticipantProps) {
  const theme = useTheme();
  const { imgUrl, username, userId, checkedIn, status } = rest;

  return (
    <Link
      href={`/profile/${userId}`}
      style={{ textDecoration: 'inherit', color: 'inherit' }}
    >
      <MuiListItem
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderTop: `1px solid rgba(0, 100, 233, 0.12)`,
        }}
      >
        <Stack
          spacing={1}
          direction="row"
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <AvatarNext
            sx={{
              backgroundColor: '#242A62',
              color: '#242A62',
            }}
            alt="competitor image"
            src={imgUrl}
          />
          <TypographyTruncated
            variant="subtitle2"
            sx={{ textTransform: 'uppercase', pl: 2 }}
            color={username ? 'text.primary' : 'text.secondary'}
            text={username}
            textMaxLength={25}
          />
        </Stack>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {status === 'captain' && (
            <GradientIcon
              IconComponent={StarIcon}
              gradientColor="primary.horizontal"
              sx={{ mr: 1, p: 0.5 }}
            />
          )}
          <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
            {isSolo && 'Solo'}
            {status === 'captain' && 'Team Captain'}
          </Typography>
          {checkedIn && (
            <CheckCircleIcon
              sx={{
                p: 0.5,
                ml: 1,
                alignSelf: 'center',
                color: `${theme.palette.secondary.main}`,
              }}
            />
          )}
        </Box>
      </MuiListItem>
    </Link>
  );
}

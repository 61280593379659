import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import { AccordionSummaryCompetitor } from './AccordionSummaryCompetitor';
import { Link } from 'src/components/Link';
import { useCompetitor } from './AccordionCompetitor';
import { ParticipantAdminDetails } from '../ParticipantAdminDetails';
import Box from '@mui/material/Box';
import { useMemo } from 'react';
import { memo } from '../../../../util/memo';
import { useTournamentPermissions } from '../../../../hooks/tournaments/useTournamentPermissions';

const AccordionCompetitorSoloUnmemoized: React.FC = () => {
  const { participants } = useCompetitor();
  const { imgUrl, username, userId, checkedIn, status, tournamentId, ...rest } =
    participants[0];
  const { isTournamentAdmin } = useTournamentPermissions();

  const SoloTypography = useMemo(() => {
    return (
      <Typography
        variant="overline"
        color="text.secondary"
        sx={{ alignSelf: 'center' }}
      >
        SOLO
      </Typography>
    );
  }, []);
  return (
    <Box>
      <Link
        href={`/profile/${participants[0].userId}`}
        style={{
          textDecoration: 'inherit',
          color: 'inherit',
        }}
      >
        <Accordion
          disableGutters
          sx={{
            '& .MuiAccordionSummary-root': {
              pointerEvents: 'none',
            },
          }}
        >
          <AccordionSummaryCompetitor LeftChildren={SoloTypography} />
        </Accordion>
      </Link>
      {isTournamentAdmin && <ParticipantAdminDetails {...rest} />}
    </Box>
  );
};
export const AccordionCompetitorSolo = memo(AccordionCompetitorSoloUnmemoized);

import { useCallback } from 'react';
import Typography from '@mui/material/Typography';
import { useDialog } from '../hooks/useDialog';
import { Link } from '../components/Link';
import { useRouterState } from './routing/useRouterState';
import Button from '@mui/material/Button';

export const AD_BLOCK_DIALOG_ID = 'AD_BLOCK_DIALOG';

export const useAdBlockDialog = () => {
  const { open } = useDialog(AD_BLOCK_DIALOG_ID);

  const [_, setAdblock] = useRouterState({ key: 'adblock' });

  const openAdBlockDialog = useCallback(() => {
    setAdblock('detected');
    open({
      title: 'Ad Block Detected',
      preventBackdropClick: true,
      showCloseIcon: false,
      description: (
        <>
          <Typography>
            It looks like an ad blocker, browser extension, or privacy setting
            is preventing ads from loading on our website.
          </Typography>
          <Typography>
            To continue using our services, please disable these settings for
            our domain.
          </Typography>
          <Typography sx={{ mt: 2 }}>
            If you’ve disabled ad blockers and still encounter issues, we’re
            here to help! Reach out to us at{' '}
            <Link href="https://blumint.com/contact">Contact Support</Link>.
          </Typography>
          <Typography sx={{ mt: 2 }}>
            Alternatively, connect with us on{' '}
            <Link href="https://twitter.com/blumint_">Twitter</Link> or{' '}
            <Link href="https://discord.gg/QfcJRBkrGX">Discord</Link> for
            further assistance.
          </Typography>
        </>
      ),
      children: (
        <Button
          variant="contained"
          onClick={() => {
            return window.location.reload();
          }}
          sx={{ mt: 2 }}
        >
          Refresh
        </Button>
      ),
    });
  }, [open]);

  return { openAdBlockDialog };
};

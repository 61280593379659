import { useCallback } from 'react';
import { useWagmiFunc } from '../web3/useWagmiFunc';
import { useSession } from '../../contexts/SessionContext';
import { useAuth } from '../../contexts/AuthContext';

export const useSignOut = () => {
  const disconnectWagmi = useWagmiFunc('disconnect');
  const { disconnect: disconnectSession } = useSession();
  const { unsubscribeUserData } = useAuth();

  const signOutFirebase = useCallback(async () => {
    unsubscribeUserData();

    await disconnectSession();

    const authImport = import('../../config/firebase-client/auth');
    const { auth } = await authImport;
    const firebaseAuthImport = import('firebase/auth');
    const { signOut: signOutUser } = await firebaseAuthImport;

    return await signOutUser(auth);
  }, [disconnectSession, unsubscribeUserData]);

  const signOut = useCallback(async () => {
    await Promise.all([disconnectWagmi(), signOutFirebase()]);
  }, [disconnectWagmi, signOutFirebase]);

  return { signOut };
};

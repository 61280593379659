import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { memo } from '../../util/memo';
import { LottieTyping } from '../LottieTyping';
import { useTyping } from './TypingContext';
import { useTheme } from '@mui/material/styles';
import { useAuth } from '../../contexts/AuthContext';

export const TYPING_INDICATOR_HEIGHT = 24 as const;

const TypingIndicatorUnmemoized = () => {
  const theme = useTheme();
  const { uid } = useAuth();
  const { typingIndicatorText } = useTyping();

  return (
    <>
      {!!typingIndicatorText && !!uid && (
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          sx={{
            width: '100%',
            background: theme.palette.background.elevation['0'],
          }}
        >
          <LottieTyping sx={{ width: '20px', height: '20px' }} />
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ height: '20px' }}
          >
            {typingIndicatorText} typing...
          </Typography>
        </Stack>
      )}
    </>
  );
};

export const TypingIndicator = memo(TypingIndicatorUnmemoized);

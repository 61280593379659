import { useCallback } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { uuidv4Base62 } from '../../../functions/src/util/uuidv4Base62';

export const UGC_STORAGE_DIRECTORY = 'Ugc' as const;
export const GUEST_USER_ID = 'guest' as const;

type UseFileUploadProps = {
  onChange: (url: string) => Promise<void>;
};

export function useFileUpload({ onChange }: UseFileUploadProps) {
  const { uid } = useAuth();

  return useCallback(
    async (files: FileList | File[]) => {
      const file = Array.from(files)[0];
      if (!file) return;

      const uniqueId = uuidv4Base62();
      const storagePath = `${UGC_STORAGE_DIRECTORY}/${
        uid || GUEST_USER_ID
      }/${uniqueId}`;

      let url = '';
      try {
        const { uploadFile: uploadStorageFile } = await import(
          '../../util/edit/uploadFile'
        );
        url = await uploadStorageFile({
          storagePath,
          file,
        });
      } catch (error) {
        console.error('Error uploading file:', error);
      } finally {
        if (!url) return;
        await onChange(url);
      }
    },
    [uid, onChange],
  );
}

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { memo } from '../../../util/memo';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useTheme } from '@mui/material/styles';
import { useViewerCount } from './ViewerCountProvider';
import { useLivestream } from '../../../contexts/LivestreamContext';
import { LiveBadge } from '../../LiveBadge';
import { UserContentOverlay } from '../../UserContentOverlay';
import { useMemo } from 'react';
const ViewerCountUnmemoized = () => {
  const theme = useTheme();
  const { isLive } = useLivestream();
  const { liveViewers, totalViews } = useViewerCount();

  const isValidLiveCount =
    isLive && liveViewers !== 'unknown' && liveViewers > 0;
  const isValidTotalCount =
    !isLive && totalViews !== 'unknown' && totalViews > 0;
  const shouldDisplay = isValidLiveCount || isValidTotalCount;

  const count = isLive ? liveViewers : totalViews;

  const liveBadge = useMemo(() => {
    return isLive && <LiveBadge />;
  }, [isLive]);

  const viewsIndicator = useMemo(() => {
    return isLive ? (
      <VisibilityOutlinedIcon sx={{ height: '18px', mt: '2px' }} />
    ) : (
      <Typography variant="subtitle2">
        {`VIEW${totalViews !== 1 ? 'S' : ''}`}
      </Typography>
    );
  }, [isLive, totalViews]);

  const viewerCount = useMemo(() => {
    return (
      shouldDisplay && (
        <UserContentOverlay
          direction="row"
          alignItems="center"
          sx={{
            pr: 2,
            pl: isValidLiveCount ? 0 : 2,
            background: theme.palette.background.shades.opacity60,
          }}
        >
          {liveBadge}
          <Stack
            alignItems="center"
            direction={isValidLiveCount ? 'row' : 'row-reverse'}
            spacing={isValidLiveCount ? 0 : 1}
            sx={{ height: '20px' }}
          >
            {viewsIndicator}
            <Typography>{count}</Typography>
          </Stack>
        </UserContentOverlay>
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldDisplay, isValidLiveCount, liveBadge, viewsIndicator, count]);

  // eslint-disable-next-line @blumintinc/blumint/no-useless-fragment
  return <>{viewerCount}</>;
};

export const ViewerCount = memo(ViewerCountUnmemoized);

import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useCallback, useMemo } from 'react';
import { memo } from '../../../util/memo';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/CloseRounded';
import PushPinIcon from '@mui/icons-material/PushPinRounded';
import { useDateFormatter } from '../../../hooks/useDateFormatter';
import { NUMERIC_NOZONE_NOCOMMA } from '../../../util/dates/presets/dateTimes';
import { SIDEBAR_WIDTH, SIDEBAR_WIDTH_MOBILE } from '../../Sidebar';
import { useTheme } from '@mui/material/styles';
import { AvatarGroupNext } from '../../avatar/AvatarGroupNext';
import { PulsateUnreadMatch } from '../../PulsateUnreadMatch';
import { Pulsate } from '../../animations/Pulsate';
import { ImageOptimized } from '../../image/ImageOptimized';
import { Attendable } from '../Attendable';
import { useParseChannelPreview } from '../../../hooks/messaging/useParseChannelPreview';
import { useRemoveChannelGroupDialog } from '../../../hooks/messaging/useRemoveChannelGroupDialog';
import { useActiveChannelGroup } from '../../../contexts/ActiveChannelGroupContext';
import { TextOverlayAvatar } from '../TextOverlayAvatar';
import { useRoomContext as useLivekitRoom } from '@livekit/components-react';
import { useConnectionStatus } from '../../../hooks/voice-chat/useConnectionStatus';
import HeadphonesRoundedIcon from '@mui/icons-material/HeadphonesRounded';
import { IconOverlayAvatar } from '../IconOverlayAvatar';
import {
  GroupFilter,
  ChannelGroup,
  GroupFilterMap,
} from '../../../../functions/src/types/firestore/User/ChannelGroup';
import {
  TOURNAMENT_PHASE_EDITABLE,
  TOURNAMENT_PHASE_PAST,
  TOURNAMENT_PHASE_PRESENT,
  TournamentPhaseEditable,
  TournamentPhasePresent,
  TournamentPhasePast,
} from '../../../../functions/src/types/firestore/Game/Tournament';

export const ANCHOR_ORIGIN_TOP_RIGHT = {
  horizontal: 'right',
  vertical: 'top',
} as const;

export const ANCHOR_ORIGIN_BOTTOM_RIGHT = {
  horizontal: 'right',
  vertical: 'bottom',
} as const;

export type ChannelGroupSidebarProps = {
  channelGroup: ChannelGroup<keyof GroupFilterMap, Date>;
  isLastTournamentChannelGroup: boolean;
};

const ChannelGroupSidebarUnmemoized = ({
  channelGroup,
  isLastTournamentChannelGroup,
}: ChannelGroupSidebarProps) => {
  const theme = useTheme();
  const room = useLivekitRoom();
  const { isConnected } = useConnectionStatus();
  const { parseChannelPreview } = useParseChannelPreview();
  const format = useDateFormatter(NUMERIC_NOZONE_NOCOMMA);
  const {
    openChannelGroup,
    closeChannelGroup,
    closeChannel,
    channelGroupId: channelGroupActiveId,
  } = useActiveChannelGroup();

  const {
    channelGroupUnreadCount,
    permanence,
    isTournament,
    isSelected,
    isSupport,
    groupFilter,
    title,
    tournamentId,
    id: channelGroupId,
    phase,
    date,
    avatarUsers,
    isTeam,
  } = parseChannelPreview(channelGroup);

  const isTemporary = permanence === 'temporary';
  const isPinned = permanence === 'pinned';
  const dateFormatted = date ? format(date) : undefined;

  const avatarBorderColor = isSupport
    ? theme.palette.primary.main
    : (phase &&
        (TOURNAMENT_PHASE_PRESENT.includes(phase as TournamentPhasePresent)
          ? theme.palette.warning.main
          : TOURNAMENT_PHASE_PAST.includes(phase as TournamentPhasePast)
          ? theme.palette.text.disabled
          : TOURNAMENT_PHASE_EDITABLE.includes(phase as TournamentPhaseEditable)
          ? theme.palette.secondary.main
          : undefined)) ??
      undefined;

  const showDivider = isLastTournamentChannelGroup && isTournament;
  const isAvatarGroup = avatarUsers.length >= 2;

  const toggleSelected = useCallback(() => {
    if (channelGroupActiveId === channelGroupId) {
      closeChannelGroup();
    } else {
      closeChannel();
      openChannelGroup(channelGroupId);
    }
  }, [
    channelGroupActiveId,
    channelGroupId,
    closeChannel,
    closeChannelGroup,
    openChannelGroup,
  ]);

  const togglePermanence = useCallback(async () => {
    const { setTournamentChannelGroupExternal } = await import(
      '../../../firebaseCloud/messaging/setTournamentChannelGroupExternal'
    );

    await setTournamentChannelGroupExternal({
      tournamentId: (groupFilter as GroupFilter<'Tournament'>)[0].tournamentId,
      permanence: isTemporary ? 'pinned' : 'temporary',
    });
  }, [groupFilter, isTemporary]);

  const { open: openRemoveChannelGroupDialog } = useRemoveChannelGroupDialog({
    groupFilter,
    isSupport,
  });

  const badgeContentUnread = useMemo(() => {
    return channelGroupUnreadCount > 0 && channelGroupUnreadCount < 10
      ? channelGroupUnreadCount
      : channelGroupUnreadCount >= 10
      ? '9+'
      : undefined;
  }, [channelGroupUnreadCount]);

  const badgeContentActions = useMemo(() => {
    return isTemporary ? (
      <Pulsate
        pulsate={isTemporary}
        opacityRange={[0, 1]}
        sx={{
          borderRadius: '50%',
          p: '9px',
          mt: '-3.5px',
          ml: '-4px',
        }}
      >
        <PushPinIcon
          sx={{
            width: '14px',
            height: '14px',
            color: theme.palette.primary.dark,
            transform: 'rotate(45deg)',
          }}
        />
      </Pulsate>
    ) : isPinned ? (
      <Box
        sx={{
          width: '100%',
          height: '100%',
        }}
      >
        <ImageOptimized
          src="/assets/icons/unpin-primary-dark.svg"
          layout={'fill'}
        />
      </Box>
    ) : isSupport ? (
      <CloseIcon
        sx={{
          width: '16px',
          height: '16px',
          color: theme.palette.primary.dark,
        }}
      />
    ) : undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTemporary, isPinned, isSupport]);

  const isConnectedToVoice = useMemo(() => {
    return (
      isConnected && !!room.name && room.name.split('-')[0] === channelGroupId
    );
  }, [isConnected, room.name, channelGroupId]);

  const overlayAvatar = useMemo(() => {
    return isConnectedToVoice ? (
      <IconOverlayAvatar
        IconComponent={HeadphonesRoundedIcon}
        iconSx={{ color: theme.palette.secondary.main }}
      />
    ) : !!dateFormatted || isTeam ? (
      <TextOverlayAvatar text={isTeam ? 'TEAM' : dateFormatted!} />
    ) : undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFormatted, isConnectedToVoice, isTeam]);

  const avatarGroupSx = isAvatarGroup
    ? {
        '.MuiAvatar-root': {
          ml: { xs: '-24px !important', md: '-32px !important' },
          mt: '-16px !important',
          backgroundColor: theme.palette.action.disabled,
          border: `2px solid ${
            theme.palette.background.elevation[isSelected ? 12 : 4]
          }`,
        },
        '.MuiBadge-root > .MuiAvatar-root': {
          ml: '0px !important',
          mt: '0px !important',
        },
        pt: '12px',
      }
    : {};

  const surplusSx =
    avatarUsers.length <= 9
      ? {
          mt: '-10px',
          mr: '-4px',
        }
      : { mt: '-8px' };

  return (
    <Stack alignItems="center">
      <Tooltip arrow title={title} placement="left">
        <Stack
          width={{
            xs: SIDEBAR_WIDTH_MOBILE,
            md: SIDEBAR_WIDTH,
          }}
          alignItems="center"
          p={2}
          sx={{
            ':hover': {
              cursor: 'pointer',
            },
            background: isSelected
              ? theme.palette.background.elevation[12]
              : undefined,
          }}
        >
          <PulsateUnreadMatch
            tournamentId={tournamentId}
            sx={{ borderRadius: '50%' }}
          >
            <Attendable
              badgeContent={badgeContentUnread}
              color={'error'}
              anchorOrigin={ANCHOR_ORIGIN_TOP_RIGHT}
            >
              <Attendable
                badgeContent={badgeContentActions}
                color={'primary'}
                anchorOrigin={ANCHOR_ORIGIN_BOTTOM_RIGHT}
                onClick={
                  isTournament
                    ? togglePermanence
                    : isSupport
                    ? openRemoveChannelGroupDialog
                    : undefined
                }
                sx={{
                  '.MuiBadge-badge': {
                    height: '17px',
                    minWidth: '17px',
                    width: '100%',
                    right: '8px',
                  },
                }}
              >
                <Box onClick={toggleSelected} position="relative">
                  <AvatarGroupNext
                    borderColor={avatarBorderColor}
                    showStatus={!isTournament && !isSupport}
                    max={2}
                    users={avatarUsers}
                    total={avatarUsers.length}
                    sx={avatarGroupSx}
                    surplusSx={surplusSx}
                    badgeSx={{
                      '& .MuiBadge-badge': {
                        borderRadius: '50%',
                        height: '16px',
                        width: '16px',
                        border: `2px solid ${theme.palette.background.elevation[4]}`,
                        boxShadow: `inset 0 0 0 1px ${theme.palette.text.primary}`,
                      },
                    }}
                  />
                  {overlayAvatar}
                </Box>
              </Attendable>
            </Attendable>
          </PulsateUnreadMatch>
        </Stack>
      </Tooltip>

      {showDivider && (
        <Divider
          sx={{
            color: theme.palette.border,
            height: '0.5px',
            width: {
              xs: '44px',
              md: '64px',
            },
          }}
        />
      )}
    </Stack>
  );
};
export const ChannelGroupSidebar = memo(ChannelGroupSidebarUnmemoized);

import { ChangeEvent } from 'react';
import { randomAvatarUrl } from '../../../functions/src/util/user/defaultAvatar';
import { useTournamentRegistration } from '../../contexts/TournamentRegistrationContext';

export const useUpdateTeamAvatar = () => {
  const {
    gameId,
    foundTeam,
    id: tournamentId,
    isUserCaptain,
  } = useTournamentRegistration();
  const { id: teamId } = foundTeam!;

  const updateAvatar = async (event?: ChangeEvent<HTMLInputElement>) => {
    if (!isUserCaptain) {
      return;
    }
    if (!event?.currentTarget.files?.length) {
      await updateTeamDoc(randomAvatarUrl());
      return;
    }
    try {
      const { currentTarget } = event;
      const { files } = currentTarget;
      if (!files?.length) {
        return;
      }

      const fileForUpload = files[0];
      const { uploadFile: uploadImage } = await import(
        '../../util/edit/uploadFile'
      );
      const url = await uploadImage({
        file: fileForUpload,
        storagePath: `Teams/${teamId}/teamAvatar`,
      });

      await updateTeamDoc(url);
      currentTarget.value = '';
    } catch (error) {
      console.error(error);
    }
  };

  const updateTeamDoc = async (imgUrl: string) => {
    try {
      const { updateTeamAvatar } = await import(
        '../../firebaseCloud/tournament/updateTeamAvatar'
      );
      await updateTeamAvatar({ imgUrl, gameId, tournamentId, teamId });
    } catch (error) {
      console.error(error);
    }
  };

  return { updateAvatar };
};

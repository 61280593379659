import { truncateIfTooLong } from '../truncate';

export const toNoContentText = (
  username = 'This user',
  extra = 'group',
  variant: string,
) => {
  const truncatedUsername = truncateIfTooLong(username, 20);

  const variants = {
    friends: {
      self: 'Add some friends above.',
      other: 'Add as a friend?',
    },
    'friend requests': {
      self: 'You can send friend requests instead.',
      other: 'No pending requests.',
    },
    'membership requests': {
      self: 'You can send membership requests or invite others to join your group.',
      other: 'Share your group with friends to get more members.',
    },
    groups: {
      self: 'Join or create a group to get started!',
      other: `${truncatedUsername} hasn't joined any ${extra}s yet.`,
    },
  };

  return variants[variant as string];
};

import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { memo } from '../../util/memo';
// import { SearchBar } from './SearchBar';
import { LogoSlogan } from './LogoSlogan';
import { UserButton } from './UserButton';
import { useTheme } from '@mui/material/styles';
// import { UserLikedCount } from './UserLikedCount';
import { ContactUsButton } from './ContactUsButton';
// import { SelectOutlinedCountry } from '../SelectOutlinedCountry';
import { SIDEBAR_WIDTH } from '../Sidebar';
import { useSocialDrawer } from '../../hooks/useSocialDrawer';
import { SOCIAL_DRAWER_WIDTH_DESKTOP } from '../Framework';
import { ShareUrlButton } from './ShareUrlButton';

export const ROW_GROUP_SX = {
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
};

export const MIN_HEADER_HEIGHT = 60 as const;

export const Header = memo(function HeaderUnmemoized() {
  const { isSocialDrawerOpen } = useSocialDrawer();
  const theme = useTheme();

  return (
    <Stack
      sx={{
        width: {
          xs: '100%',
          md: isSocialDrawerOpen
            ? `calc(100% - ${SOCIAL_DRAWER_WIDTH_DESKTOP}px)`
            : `calc(100% - ${SIDEBAR_WIDTH}px)`,
        },
        transition: { xs: undefined, md: 'width 300ms ease-in-out' },
        borderBottom: {
          xs: 'none',
          md: theme.border,
        },
      }}
    >
      <Toolbar
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
          minHeight: `${MIN_HEADER_HEIGHT}px !important`,
          px: 2,
          borderRight: {
            xs: 'none',
            md: isSocialDrawerOpen ? theme.border : 'none',
          },
        }}
      >
        <Stack
          direction="row"
          sx={{
            ml: '4px',
            flexGrow: 1,
            ...ROW_GROUP_SX,
            justifyContent: 'left',
          }}
        >
          <LogoSlogan />
          {/* <SearchBar /> */}
        </Stack>
        <Stack
          direction="row"
          spacing={4}
          sx={{
            pl: { xs: 2, md: 4 },
            ...ROW_GROUP_SX,
          }}
        >
          <ShareUrlButton />
          <ContactUsButton />
          {/* {!!userData && !!user && <SelectOutlinedCountry />} */}
          <UserButton />
        </Stack>
      </Toolbar>
    </Stack>
  );
});

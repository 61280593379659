import { Fragment, useMemo, ReactNode, useCallback } from 'react';
import { memo } from '../../../util/memo';
import { CalendarDate } from '../CalendarDate';
import { GradientDivider } from '../../gradients/GradientDivider';
import Stack from '@mui/material/Stack';
import { EventKeyed } from 'src/contexts/EventsLazyContext';
import { useTheme } from '@mui/material/styles';

export type CalendarDayProps = {
  date: Date;
  events: EventKeyed[];
  extension: ReactNode;
  onIntersect: () => void;
  intersected: boolean;
  height?: string;
};

export const CALENDAR_DAY_WIDTH = 300 as const;

function CalendarDayUnmemoized({
  date,
  events,
  extension,
  onIntersect,
  intersected,
  height = '100%',
}: CalendarDayProps) {
  const theme = useTheme();

  const scroll = useCallback(() => {
    if (!intersected) {
      onIntersect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onIntersect]);

  const initialEvents = useMemo(() => {
    return events.map(({ key, Node }) => {
      return <Fragment key={key}>{Node}</Fragment>;
    });
  }, [events]);

  return (
    <Stack
      height={height}
      sx={{
        mx: 1,
        pt: 1, 
        width: `${CALENDAR_DAY_WIDTH}px`,
      }}
      spacing={2}
    >
      <CalendarDate date={date} />
      {/* <GradientDivider color="primary" /> */}
      <Stack
        overflow={'auto'}
        sx={theme.scrollbars.invisible}
        onScroll={scroll}
      >
        <Stack spacing={2}>{initialEvents}</Stack>
        <Stack sx={{ mt: 2 }}>{extension}</Stack>
      </Stack>
    </Stack>
  );
}

export const CalendarDay = memo(CalendarDayUnmemoized);

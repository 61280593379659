import { createContext, useContext, ReactNode, useMemo } from 'react';
import { useRealtimeCount } from '../../../hooks/useRealtimeCount';
import { useLivestream } from '../../../contexts/LivestreamContext';
import { toPresencePath } from '../../../../functions/src/util/presence/toPresencePath';
import { memo } from '../../../util/memo';

type ViewerCountContextType = {
  liveViewers: number | 'unknown';
  totalViews: number | 'unknown';
};

const ViewerCountContext = createContext<ViewerCountContextType | null>(null);

export const useViewerCount = () => {
  const context = useContext(ViewerCountContext);
  if (!context) {
    throw new Error(
      'useViewerCountContext must be used within ViewerCountProvider',
    );
  }
  return context;
};

export type ViewerCountProviderProps = {
  children: ReactNode;
};

const ViewerCountProviderUnmemoized = ({
  children,
}: ViewerCountProviderProps) => {
  const { playbackId } = useLivestream();

  const liveViewers = useRealtimeCount(
    toPresencePath({
      elementId: playbackId,
      choice: 'liveViewerCount',
    }),
  );

  const totalViews = useRealtimeCount(
    toPresencePath({
      elementId: playbackId,
      choice: 'totalViews',
    }),
  );

  const valueMemoed = useMemo(() => {
    return { liveViewers, totalViews };
  }, [liveViewers, totalViews]);

  return (
    <ViewerCountContext.Provider value={valueMemoed}>
      {children}
    </ViewerCountContext.Provider>
  );
};

export const ViewerCountProvider = memo(ViewerCountProviderUnmemoized);

import {
  ChannelGroup,
  GroupFilterMap,
} from '../../../functions/src/types/firestore/User/ChannelGroup';
import { useUnreadMessageCount } from '../../contexts/UnreadMessageCountContext';
import { useAuth } from '../../contexts/AuthContext';
import { isGroup } from '../../../functions/src/types/firestore/User/ChannelGroup/util/isGroup';
import { useCallback } from 'react';
import { useActiveChannelGroup } from '../../contexts/ActiveChannelGroupContext';
import { toActiveChannelGroup } from '../../../functions/src/util/messaging/toActiveChannelGroup';

export const useParseChannelPreview = () => {
  const { unreadMessageCount } = useUnreadMessageCount();
  const { channelGroupId } = useActiveChannelGroup();
  const { uid } = useAuth();

  const parseChannelPreview = useCallback(
    (channelGroup: ChannelGroup<keyof GroupFilterMap, Date>) => {
      const {
        id,
        members,
        groupFilter,
        title: titleChannelGroup,
      } = channelGroup;

      const tournamentId = groupFilter[0]?.tournamentId;
      const isSelected = channelGroupId === id;
      const isGroupChannel = isGroup<Date>(channelGroup);
      const isTeamChannel = isGroupChannel && !!tournamentId;

      const channelGroupChannelIds = Object.keys(unreadMessageCount).filter(
        (key) => {
          return key.includes(String(id));
        },
      );

      const channelUnreadCounts = channelGroupChannelIds.map((channelId) => {
        return unreadMessageCount[`${channelId}`];
      });

      const channelGroupUnreadCount = channelUnreadCounts.reduce(
        (prev, curr) => {
          return prev + curr.total;
        },
        0,
      );

      const title =
        members?.length === 2
          ? members?.find(({ userId }) => {
              return userId !== uid;
            })?.username
          : titleChannelGroup;

      return {
        ...channelGroup,
        ...toActiveChannelGroup(channelGroup),
        title,
        isSelected,
        tournamentId,
        channelGroupUnreadCount,
        isGroup: isGroupChannel,
        isTeam: isTeamChannel,
      };
    },
    [channelGroupId, unreadMessageCount, uid],
  );

  return { parseChannelPreview };
};

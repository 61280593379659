import { memo } from '../../util/memo';
import { useTheme } from '@mui/material/styles';
import { useCallback, useMemo } from 'react';
import { PREEMPTIVE_FILTER_TEMPLATES } from '../../../functions/src/util/algolia/preemption/templates';
import { fillTemplate } from '../../../functions/src/util/algoliaRealtime/fillTemplate';
import { useVoiceChat } from '../../contexts/voice-chat/VoiceChatContext';
import { useRoom } from '../../contexts/RoomContext';
import { AlgoliaLayout } from '../algolia/AlgoliaLayout';
import { SearchAlgolia } from '../algolia/search/SearchAlgolia';
import { CallerCard } from './CallerCard';
import { NoCallers } from './NoCallers';
import {
  CallersVerticalCarouselProps,
  CallersVerticalCarousel,
} from './CallersVerticalCarousel';

export const CALLERS_HEIGHT_MOBILE = 362 as const;
export const CALLERS_HEIGHT_DESKTOP = 386 as const;

export const CALLERS_HITS_PER_PAGE = 50 as const;

const CallersUnmemoized = () => {
  const theme = useTheme();
  const { rooms } = useRoom();
  const { mode } = useVoiceChat();
  const { roomId } = rooms[mode as string] || {};

  const searchBar = useMemo(() => {
    return <SearchAlgolia collapsable={false} />;
  }, []);

  const CallersCatalogWrapper = useCallback(
    (
      props: Omit<
        CallersVerticalCarouselProps,
        'RenderCallerHit' | 'header' | 'noCallers' | 'containerSx'
      >,
    ) => {
      return (
        <CallersVerticalCarousel
          {...props}
          RenderCallerHit={CallerCard}
          noCallers={<NoCallers />}
          cardSpacing="0px"
          header={searchBar}
          containerSx={{
            px: 2,
            pt: 3,
            height: '100%',
            maxHeight: {
              xs: CALLERS_HEIGHT_MOBILE,
              md: CALLERS_HEIGHT_DESKTOP,
            },
            borderRadius: '10px 10px 0px 0px',
            background: theme.palette.background.elevationSolid[12],
          }}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchBar],
  );

  const callersFilter = fillTemplate(
    PREEMPTIVE_FILTER_TEMPLATES['caller'],
    roomId,
  );

  const callersConfigureOptions = useMemo(() => {
    return {
      hitsPerPage: CALLERS_HITS_PER_PAGE,
      filters: callersFilter,
    };
  }, [callersFilter]);

  return (
    <AlgoliaLayout
      CatalogWrapper={CallersCatalogWrapper}
      configureOptions={callersConfigureOptions}
      index="CONTENT"
    />
  );
};

export const Callers = memo(CallersUnmemoized);

/* eslint-disable @typescript-eslint/no-explicit-any */
import { createTheme, SxProps, alpha } from '@mui/material/styles';
import { isRare } from '../util/rarityCheck';
import tinyColor from 'tinycolor2';
import type {} from '@mui/x-data-grid/themeAugmentation';
import {
  BUTTON_VARIANTS_CONTAINED,
  BUTTON_VARIANTS_OUTLINED,
  BUTTON_VARIANTS_TEXT,
} from './util/button/variant';
import { PALETTE, RARITIES } from './palette';
import { TYPOGRAPHY } from './typography';
import { BREAKPOINTS, ZINDEX } from './system';
import { BACKDROP_FILTERS, GLOWS, SHADOWS, SHADOWS_HARD } from './shadows';
import { ELEVATIONS, ELEVATIONS_SOLID } from './elevations';
import stringify from 'json-stringify-safe';
import { ELLIPSIS_SX } from '../util/string';
import { SCROLLBARS } from './scrollbars';

export type PaletteRarity = typeof RARITIES;

const LINE_CLAMP = (
  lines: number,
  { wrap, ...ellipsisSx } = {
    wrap: true,
    ...ELLIPSIS_SX,
  },
) => {
  return {
    display: '-webkit-box',
    WebkitLineClamp: lines,
    WebkitBoxOrient: 'vertical',
    ...ellipsisSx,
    whiteSpace: wrap ? ('normal' as const) : ('nowrap' as const),
  };
};

const BORDER = `1px solid ${PALETTE.border}`;

const PANELS = [
  {
    background: ELEVATIONS_SOLID['3'],
    borderRadius: '10px',
    p: 4,
  },
  {
    background: ELEVATIONS_SOLID['6'],
    borderRadius: '10px',
    p: '12px',
  },
  {
    background: ELEVATIONS_SOLID['8'],
    borderRadius: '5px',
    p: '12px',
  },
  {
    //panel list
    borderRadius: '10px',
    position: 'relative',
    margin: 'auto',
    backgroundImage: `linear-gradient(rgba(13,31,50,1), rgba(13,31,50,1)), linear-gradient(180deg, #4DC8FF 0%, #0064E9 100%)`,
    border: 'double 2px transparent',
    backgroundClip: 'content-box, border-box',
    backgroundOrigin: 'border-box',
  },
  {
    //panel list item
    position: 'relative',
    borderRadius: '10px',
    p: '24px 16px 0 16px',
    '&::after': {
      content: "''",
      inset: -2,
      position: 'absolute',
      borderRadius: '10px',
      // border: 'solid 2px transparent',
      backgroundImage:
        'linear-gradient(#000E21, #001533), linear-gradient(180deg, #4DC8FF 0%, #0064E9 100%)',
      backgroundOrigin: 'border-box',
      backgroundClip: 'content-box, border-box',
      zIndex: -1,
    },
  },
] as const;

const theme = createTheme({
  palette: PALETTE,
  border: BORDER,
  spacing: 4,
  typography: TYPOGRAPHY,
  breakpoints: BREAKPOINTS,
  shadows: SHADOWS,
  shadowsHard: SHADOWS_HARD,
  glass: BACKDROP_FILTERS,
  glow: GLOWS,
  zIndex: ZINDEX,
  lineClamp: LINE_CLAMP,
  panels: PANELS,
  scrollbars: SCROLLBARS,
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
          '&>.MuiDataGrid-main': {
            '&>.MuiDataGrid-columnHeaders': {
              borderBottom: 'none',
            },
            '& div div div div >.MuiDataGrid-cell': {
              borderBottom: 'none',
            },
          },
        },
        columnHeaders: {
          borderBottom: '0px',
          fontWeight: 'bold',
          color: PALETTE.text.primary,
          fontSize: '16px',
        },
        cell: {
          borderColor: '#ffffff25',
          '&:focus': {
            outline: 'none',
          },
        },
        row: {
          backgroundColor: PALETTE.background.elevation[9],
          borderRadius: '4px',
          borderBottom: '0px transparent',
          '&:focus': {
            outline: 'none',
          },
          '&:hover': {
            backgroundColor: PALETTE.background.elevation[11],
          },
        },
        columnSeparator: {
          display: 'none',
        },
        footerContainer: {
          border: 'unset',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${alpha(PALETTE.primary.dark, 0.3)}`,
          backgroundColor: PALETTE.background.elevation['4'],
          height: '60px',
        },
      },
    },
    MuiAvatar: {
      variants: [
        {
          props: { variant: 'gradientOutlined' },
          style: {
            border: 'double 1.5px transparent',
            borderRadius: '50%',
            backgroundImage: `linear-gradient(${ELEVATIONS['8']}, ${ELEVATIONS['8']}), linear-gradient(180deg, #4DC8FF 0%, #0064E9 100%)`,
            backgroundOrigin: 'border-box',
            backgroundClip: 'content-box, border-box',
          },
        },
      ],
    },
    MuiCard: {
      variants: [
        {
          props: { variant: 'collection' },
          style: {
            width: '250px',
            height: '330px',
            border: 'double 2px transparent',
            '& .MuiCardContent-root': {
              position: 'relative',
              display: 'flex',
              height: '85.5%',
              alignItems: 'flex-end',
              zIndex: 1,
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: 8,
              paddingRight: 8,
            },
            '& .MuiCardActions-root': {
              position: 'relative',
              zIndex: 2,
              paddingLeft: 4,
              paddingRight: 4,
              paddingTop: 4,
              paddingBottom: 0,
            },
            '& .MuiCardMedia-root': {
              width: '247px',
              height: '323px',
              position: 'absolute',
              borderRadius: '10px',
              backgroundColor: 'rgba(0, 0, 0, 0.08)',
              backgroundPosition: 'center top',
              display: 'block',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              '&:after': {
                content: '""',
                display: 'block',
                position: 'absolute',
                width: '100%',
                height: '20%',
                bottom: 0,
                zIndex: 1,
                background: 'linear-gradient(to top, #000, rgba(0,0,0,0))',
              },
            },
          },
        },
        {
          props: { variant: 'game' },
          style: {
            width: '534px',
            '& .MuiCardMedia-root': {
              borderRadius: '5px',
            },
            '& .MuiCardActions-root': {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'stretch',
              paddingTop: 8,
              paddingBottom: 8,
              paddingLeft: 16,
              paddingRight: 16,
            },
          },
        },
        {
          props: { variant: 'profile' },
          style: {
            width: '240px',
            maxHeight: '140px',
          },
        },
        {
          props: { variant: 'item' },
          style: {
            border: 'double 2px transparent',
            width: '254px',
            '& .MuiCardMedia-root': {
              borderRadius: '5px',
              height: '248px',
              backgroundPosition: 'center top',
            },
            '& .MuiCardContent-root:last-child': {
              paddingBottom: 2,
            },
            '& .MuiCardContent-root': {
              padding: 8,
              paddingBottom: 0,
              paddingTop: 0,
            },
            '& .MuiCardActions-root': {
              padding: 4,
            },
          },
        },
        {
          props: { variant: 'itemEmpty' },
          style: {
            border: 'dashed 2px #051D33',
            minWidth: '254px',
            maxWidth: '254px',
            '& .MuiCardMedia-root': {
              height: '248px',
              backgroundPosition: 'center top',
              borderRadius: '10px',
              backgroundOrigin: 'border-box',
              backgroundClip: 'content-box, border-box',
              backgroundImage:
                'linear-gradient(#051D33, #051D33), linear-gradient(180deg, #4DC8FF 0%, #0064E9 100%)',
              '&:hover': {
                background: 'none',
                cursor: 'auto',
              },
            },
            '& .MuiCardContent-root:last-child': {
              paddingBottom: 2,
            },
            '& .MuiCardContent-root': {
              padding: 8,
              paddingBottom: 0,
              paddingTop: 0,
            },
            '& .MuiCardActions-root': {
              padding: 4,
            },
          },
        },
      ],
      styleOverrides: {
        root: ({ ownerState }) => {
          const rarity = ownerState.rarity as string | undefined;
          const isRareCard = isRare(rarity);
          const glow = JSON.parse(stringify(GLOWS.card));
          if (isRareCard) {
            glow['&:hover'].backgroundImage = `linear-gradient(${
              ELEVATIONS_SOLID[16]
            }, ${ELEVATIONS_SOLID[16]}), ${PALETTE.rarityBackground[rarity!]}`;
            glow['&:hover'].boxShadow = `0px 0px 24.444px ${
              PALETTE.rarityHover[rarity!]
            }, 0px 0px 13.968px ${
              PALETTE.rarityHover[rarity!]
            }, 0px 0px 8.148px ${
              PALETTE.rarityHover[rarity!]
            }, 0px 0px 4.074px ${
              PALETTE.rarityHover[rarity!]
            }, 0px 0px 1.164px ${
              PALETTE.rarityHover[rarity!]
            }, 0px 0px 0.582px ${PALETTE.rarityHover[rarity!]}`;
          }
          return {
            border: 'double 2px transparent',
            borderRadius: '10px',
            backgroundImage: `linear-gradient(${ELEVATIONS_SOLID[8]}, ${
              ELEVATIONS_SOLID[8]
            }), ${
              isRareCard
                ? PALETTE.rarityBackground[rarity!]
                : PALETTE.primary.vertical
            }`,
            backgroundOrigin: 'border-box',
            backgroundClip: 'content-box, border-box',
            ...glow,
          };
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        disableScrollLock: true,
      },
      styleOverrides: {
        root: {
          margin: 'auto 0',
          maxHeight: `calc(100dvh - 48px)`,
          zIndex: ZINDEX.dialog,
          '& .MuiDialog-paper': {
            borderRadius: '10px',
            backgroundColor: ELEVATIONS_SOLID[3],
            border: `2px solid ${PALETTE.border}`,
            boxShadow: 'none',
            margin: '10px',
            ...GLOWS.dialog,
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          border: 'double 2px transparent',
          borderRadius: '10px',
          // backgroundImage: ELEVATIONS_SOLID['3'],
          backgroundOrigin: 'border-box',
          backgroundClip: 'content-box, border-box',
          padding: '0px',
          overflowY: 'auto',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ ownerState }) => {
          if (ownerState.variant === ('text' as any)) {
            return {
              '&::before, &::after': {
                borderColor: `${PALETTE.border}`,
              },
            };
          }
          return {
            background: PALETTE.border,
            height: '1px',
            border: 'none',
          };
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        elevation: 8,
      },
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            background: ELEVATIONS[10],
            backdropFilter: BACKDROP_FILTERS[8],
            borderRadius: '10px',
            boxShadow: 'none',
            border: BORDER,
          },
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        disableScrollLock: true,
      },
      styleOverrides: {
        root: {
          '&.MuiMenu-root .MuiPaper-root': {
            zIndex: `${ZINDEX.menu} !important`,
          },
          '& .MuiList-root': {
            paddingTop: 0,
            paddingBottom: 0,
          },
          '& .MuiList-root > *:first-of-type': {
            '& .MuiMenuItem-root': {
              paddingTop: '10px',
            },
          },
          '& .MuiList-root > .MuiMenuItem-root:first-of-type': {
            paddingTop: '10px',
          },
          '& .MuiList-root > *:last-child': {
            '& .MuiMenuItem-root': {
              paddingBottom: '10px',
            },
          },
          '& .MuiList-root > .MuiMenuItem-root:last-child': {
            paddingBottom: '10px',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight: 'auto',
          paddingTop: '8px',
          paddingBottom: '8px',
          textTransform: 'uppercase',
          color: PALETTE.text.primary,
        },
      },
    },
    MuiSelect: {
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            border: `1.5px solid ${PALETTE.background.elevation[28]}`,
            borderRadius: '60px',
            color: PALETTE.text.primary,
            background: PALETTE.background.elevation[12],
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '24px',
            letterSpacing: '0.46px',
            textTransform: 'uppercase',
            height: '44px',
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none', // Remove the default border
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: 'none', // Remove the default hover border
            },
            '&:hover': {
              background: PALETTE.background.elevation[16],
            },
          },
        },
        {
          props: { variant: 'filled' },
          style: {
            textTransform: 'uppercase',
            '& .MuiSelect-select': {
              padding: 10,
            },
          },
        },
      ],
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '13px',
          lineHeight: '18px',
          letterSpacing: '0.04em',
          textTransform: 'uppercase',
          color: PALETTE.text.primary,
          borderRadius: '32px',
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            border: '1px solid rgba(255,255,255,0.26)',
            background: ELEVATIONS[16],
            color: PALETTE.text.secondary,
          },
        },
        {
          props: { variant: 'outlined', color: 'info' },
          style: {
            border: '1px solid rgba(255,255,255,0.26)',
            background: ELEVATIONS_SOLID[19],
            color: PALETTE.text.secondary,
          },
        },
        {
          props: { variant: 'royalOutlined' },
          style: {
            background: 'unset',
            backgroundColor: 'unset',
            border: 'double 1px transparent',
            backgroundImage: `linear-gradient(#442f6e, #39275e), ${PALETTE.royal.verticalInverse}`,
            backgroundOrigin: 'border-box',
            backgroundClip: 'content-box, border-box',
            boxShadow: 'none',
            filter: 'none',
          },
        },
        {
          props: { variant: 'gradientOutlined' },
          style: {
            border: 'double 1px transparent',
            backgroundImage: `linear-gradient(${ELEVATIONS_SOLID['16']}, ${ELEVATIONS_SOLID['16']}), linear-gradient(180deg, #4DC8FF 0%, #0064E9 100%)`,
            backgroundOrigin: 'border-box',
            backgroundClip: 'content-box, border-box',
          },
        },
        {
          props: { variant: 'gradientOutlinedHorizontal' },
          style: {
            border: 'double 1px transparent',
            backgroundImage: `linear-gradient(${ELEVATIONS_SOLID['16']}, ${ELEVATIONS_SOLID['16']}), linear-gradient(90deg, #4DC8FF 0%, #0064E9 100%)`,
            backgroundOrigin: 'border-box',
            backgroundClip: 'content-box, border-box',
          },
        },
        {
          props: { variant: 'doubleOutlined' },
          style: {
            border: '1px rgba(42, 78, 205, 0.3) double',
            background: 'rgba(42, 78, 205, 0.3)',
            filter: 'unset',
            backgroundColor: 'unset',
            outline: '1px #1376FB double',
            outlineOffset: '1px',
            color: PALETTE.text.secondary,
          },
        },
        ...Object.keys(PALETTE.rarityBackground).map((rarity) => {
          return {
            props: { variant: rarity },
            style: {
              background: PALETTE.rarityBackground[`${rarity}`],
            },
          };
        }),
        ...Object.keys(PALETTE.rarityBackground).map((rarity) => {
          return {
            props: { variant: `${rarity}Outlined` },
            style: {
              background: 'unset',
              backgroundColor: 'unset',
              border: 'double 1px transparent',
              backgroundImage: `linear-gradient(${tinyColor(
                RARITIES[`${rarity}`],
              ).darken(30)}, ${tinyColor(RARITIES[`${rarity}`]).darken(35)}), ${
                PALETTE.rarityBackground[`${rarity}`]
              }`,
              backgroundOrigin: 'border-box',
              backgroundClip: 'content-box, border-box',
              boxShadow: 'none',
              filter: 'none',
            },
          };
        }),
      ],
    },
    MuiSnackbar: {
      styleOverrides: {
        root: ({ ownerState }) => {
          if (ownerState.message) {
            return {
              border: `solid 2px ${PALETTE.border}`,
              borderRadius: '5px',
              backgroundImage: `linear-gradient(${ELEVATIONS_SOLID[30]}, ${ELEVATIONS_SOLID[30]}), linear-gradient(180deg, #4DC8FF 0%, #0064E9 100%)`,
              backgroundOrigin: 'border-box',
              backgroundClip: 'content-box, border-box',
              '& .MuiPaper-root': {
                background: 'transparent',
              },
            };
          }
          return {
            backdropFilter: theme.glass[8],
            borderRadius: '4px',
            '& .MuiPaper-root': {
              background: ELEVATIONS['8'],
            },
            top:
              ownerState.anchorOrigin?.vertical === 'top'
                ? '72px !important'
                : undefined,
          };
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: () => {
          return {
            '& .MuiCircularProgress-colorPrimary': {
              color: PALETTE.primary.horizontal,
            },
          };
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: ({ ownerState }) => {
          return {
            background: PALETTE.background.elevation['4'],
            height: '8px',
            borderRadius: '4px',
            overflow: 'visible',
            '& .MuiLinearProgress-barColorPrimary': {
              background: PALETTE.primary.horizontal,
              boxShadow:
                '0px 0px 5px -1px rgba(57, 156, 250, 0.8), 0px 0px 8px rgba(57, 156, 250, 0.50), 0px 0px 14px rgba(57, 156, 250, 0.40)',
              borderRadius: '4px',
              width: `${ownerState.value}% !important`,
              transform: 'translateX(0%) !important',
            },
            '& .MuiLinearProgress-barColorSecondary': {
              background: PALETTE.secondary.horizontal,
            },
            '& .MuiLinearProgress-dashed': {
              backgroundImage: `conic-gradient(${PALETTE.text.secondary} 50%, ${PALETTE.text.secondary}, transparent 0%)`,
            },
          };
        },
      },
      variants: [
        {
          props: { variant: 'buffer' },
          style: {
            backgroundColor: 'transparent !important',
          },
        },
      ],
    },
    MuiToggleButton: {
      styleOverrides: {
        root: ({ ownerState }) => {
          if (ownerState.disabled) {
            return;
          }
          return {
            '& .MuiSvgIcon-root': {
              color: PALETTE.text.secondary,
            },
            background: PALETTE.primary.vertical,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            backgroundClip: 'text',
          };
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: ({ ownerState }) => {
          return {
            border: 'double 1px transparent',
            borderRadius: '5px',
            backgroundImage: `linear-gradient(${ELEVATIONS_SOLID['10']}, ${ELEVATIONS_SOLID['10']}), ${PALETTE.primary.vertical}`,
            backgroundOrigin: 'border-box',
            backgroundClip: 'content-box, border-box',
            boxShadow: SHADOWS_HARD.primaryElevation2,
            '& .MuiToggleButtonGroup-grouped:not(:last-of-type)': {
              boxShadow: ownerState.hideDivider
                ? 'none'
                : 'inset -1px 0px 0px rgba(77, 198, 255, 0.5)',
            },
            '& .Mui-selected': {
              background: `linear-gradient(${ELEVATIONS_SOLID['6']}, ${ELEVATIONS_SOLID['9']})`,
              WebkitBackgroundClip: 'none',
              WebkitTextFillColor: PALETTE.primary.mid,
              backgroundClip: 'none',
            },
            '& .Mui-disabled': {
              WebkitBackgroundClip: 'none',
              WebkitTextFillColor: PALETTE.text.disabled,
              opacity: '50%',
              backgroundClip: 'none',
            },
          };
        },
      },
    },
    // If the badge is invisible for some reason, make sure to pass badgeContent={''}
    // this is because the MUI checks for visibility rely on the variant not being a custom one.
    MuiBadge: {
      defaultProps: {
        color: 'error',
      },
      styleOverrides: {
        root: ({ ownerState: { color } }) => {
          return {
            fontSize: '16px',
            '.MuiBadge-dot': {
              background: color,
            },
            '& .MuiBadge-colorPrimary': {
              background: PALETTE.primary.horizontal,
            },
            '& .MuiBadge-colorSecondary': {
              background: PALETTE.secondary.horizontal,
            },
            '& .MuiBadge-colorError': {
              background: PALETTE.error.horizontal,
            },
            '& .MuiBadge-colorWarning': {
              background: PALETTE.warning.horizontal,
            },
            '& .MuiBadge-colorInfo': {
              background: PALETTE.info.horizontal,
            },
            '& .MuiBadge-colorSuccess': {
              background: PALETTE.success.horizontal,
            },
          };
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: '10px',
          border: BORDER,
          background: ELEVATIONS['10'],
          backdropFilter: BACKDROP_FILTERS[4],
          '& .MuiTooltip-arrow': {
            color: PALETTE.border,
          },
          '& .MuiTooltip-arrow::before': {
            border: BORDER,
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: ({ ownerState }) => {
          const color = ownerState.color || 'primary';
          return {
            '& .MuiSlider-rail': {
              background: PALETTE[`${color}`].horizontal,
              opacity: 0.38,
              borderRadius: '12px',
            },
            '& .MuiSlider-track': {
              boxShadow: SHADOWS_HARD[`${color}Elevation1`],
              background: PALETTE[`${color}`].horizontal,
              border: 'none',
            },
            '& .MuiSlider-thumb': {
              background: PALETTE[`${color}`].horizontal,
              boxShadow: SHADOWS_HARD[`${color}Elevation1`],
            },
            '& .MuiSlider-mark': {
              background: PALETTE[`${color}`].horizontal,
            },
          };
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: ELEVATIONS[6],
          borderRadius: '5px 5px 0px 0px',
        },
      },
      variants: [
        {
          props: { variant: 'filter' },
          style: {
            '& .MuiAccordionSummary-root': {
              borderRadius: '5px 5px 0px 0px',
              background: 'rgba(0, 101, 232, 0.14)',
              borderBottom: '1px solid transparent',
              borderImage: PALETTE.border,
              borderImageSlice: 1,
            },
            '& .MuiAccordionDetails-root': {
              background: 'rgba(0, 101, 232, 0.14)',
              borderBottom: '1px solid transparent',
              borderImage: PALETTE.border,
              borderImageSlice: 1,
            },
          },
        },
        {
          props: { variant: 'listing' },
          style: {
            '& .MuiAccordionSummary-root': {
              background: 'rgba(69, 198, 255, 0.16)',
              borderBottom: '1px solid transparent',
              borderImage: PALETTE.border,
              borderImageSlice: 1,
            },
            '& .MuiAccordionDetails-root': {
              background: 'rgba(69, 198, 255, 0.16)',
              borderBottom: '1px solid transparent',
              borderImage: PALETTE.border,
              borderImageSlice: 1,
            },
          },
        },
        {
          props: { variant: 'listings' },
          style: {
            background: 'transparent',
            '& .MuiAccordionDetails-root': {
              background: 'transparent',
            },
          },
        },
      ],
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: ({ ownerState }) => {
          return {
            ...GLOWS.selectable,
            background: ELEVATIONS[8],
            padding: '0 16px',
            borderBottom: ownerState.expanded
              ? 'none'
              : '1px solid transparent',
            borderImage: 'none',
            [`@media (max-width: ${BREAKPOINTS.values.md}px)`]: {
              padding: '0 8px',
              '& .MuiAccordionSummary-expandIconWrapper': {
                paddingRight: '0',
                paddingLeft: '4px',
              },
              '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                paddingRight: '0',
                paddingLeft: '4px',
              },
            },
          };
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          '& a.MuiTypography-root': {
            ...TYPOGRAPHY.h6,
            fontWeight: 400,
            background: PALETTE.primary.vertical,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            backgroundClip: 'text',
            textFillColor: 'transparent',
            textDecorationColor: PALETTE.primary.dark,
            // textDecoration: 'none',
            // color: PALETTE.primary.main,
            // textDecoration: 'underline',
            // fontSize: 22,
            // borderBottom: '1px solid transparent',
            // borderImageSlice: 1,
          },
          '& li:last-of-type': {
            // marginBottom: 2,
            '& a.MuiTypography-root': {
              // fontFamily: 'Rubik',
              background: 'none',
              // webkitBackgroundClip: 'unset',
              // webkitTextFillColor: 'unset',
              // backgroundClip: 'unset',
              // textFillColor: 'unset',
              // fontWeight: 600,
              textFillColor: PALETTE.text.secondary,
              // letterSpacing: 0.15,
              textDecoration: 'none',
              // fontSize: 24,
              // marginBottom: 0,
              // '&:hover': { border: 'none' },
            },
          },
        },
        separator: {
          ...TYPOGRAPHY.h6,
          fontWeight: 400,
          background: PALETTE.primary.vertical,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          backgroundClip: 'text',
          textFillColor: 'transparent',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          //background: 'rgba(77, 200, 255, 0.05)',
          // opacity: '80%',
          background: PALETTE.background.elevationSolid[0],
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          backgroundClip: 'text',
          textFillColor: 'transparent',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid transparent',
          borderImage: PALETTE.primary.horizontal,
          borderImageSlice: 1,
          '& .Mui-selected': {
            opacity: '100%',
            background: PALETTE.background.elevation['10'],
            WebkitBackgroundClip: 'unset',
            WebkitTextFillColor: 'unset',
            backgroundClip: 'unset',
            textFillColor: 'unset',
            // 'linear-gradient(0deg, #0064E9 -33%, #4DC8FF 90%)'
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '8px 16px',
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        ...GLOWS.selectable,
        track: {
          backgroundColor: `${PALETTE.background.elevationSolid['30']}`,
          opacity: 'unset',
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState }) => {
          const severityColor =
            theme.palette[`${ownerState.severity || 'primary'}`];
          const backgroundLight = `${severityColor.light}26`; // 20% opacity
          const backgroundDark = `${severityColor.mid}26`; // 20% opacity
          return {
            textTransform: 'uppercase',
            borderRadius: '10px',
            // boxShadow: `${
            //   SHADOWS_HARD[`${ownerState.severity}Elevation2`]
            // } !important`,
            '.MuiAlert-icon': {
              padding: 0,
              marginTop: 'auto',
              marginBottom: 'auto',
            },
            backgroundImage: `linear-gradient(${backgroundLight}, ${backgroundDark})`,
          };
        },
      },
    },
    MuiTextField: {
      variants: [
        ...['primary', 'secondary', 'warning', 'error', 'success', 'info'].map(
          (color) => {
            return {
              props: {
                variant: 'outlined' as any,
                color: color as any,
                disabled: false,
              },
              style: {
                // '.MuiInputLabel-root': {
                //   top: '-8px',
                //   left: '-6px',
                //   color: PALETTE[color as string].main,
                // },
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: `${alpha(
                    PALETTE[color as string].main,
                    0.3,
                  )} !important`, // 30% opacity
                  borderWidth: '1px',
                },
                '.MuiOutlinedInput-root': {
                  background: ELEVATIONS['12'],
                  ...GLOWS.highlight,
                },
              },
            };
          },
        ),
        {
          props: {
            variant: 'outlined' as any,
            disabled: false,
            error: true,
          },
          style: {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: `${PALETTE.error.main} !important`,
              borderWidth: '1.25px',
            },
            '.MuiOutlinedInput-root': {
              borderRadius: '5px',
            },
          },
        },
        // {
        //   props: {
        //     variant: 'outlined' as any,
        //     disabled: false,
        //     multiline: true,
        //   },
        //   style: {
        //     '.MuiOutlinedInput-root': {
        //       padding: 0,
        //     },
        //     '.MuiOutlinedInput-input': {
        //       margin: '16.5px 14px 16.5px 0px',
        //     },
        //     '.MuiInputAdornment-root': {
        //       margin: '16.5px 0px 16.5px 14px',
        //     },
        //   },
        // },
        // {
        //   props: {
        //     variant: 'outlined' as any,
        //     disabled: false,
        //   },
        //   style: {
        //     '.MuiOutlinedInput-notchedOutline': {
        //       color: 'transparent',
        //     },
        //     '.MuiOutlinedInput-root': {
        //       padding: 0,
        //     },
        //     '.MuiInputAdornment-root': {
        //       margin: '0px 8px 0px 8px',
        //     },
        //   },
        // },
        {
          props: {
            variant: 'filled',
            color: 'primary',
            disabled: false,
          },
          style: {
            '.MuiFilledInput-root': {
              '&:before, &:after': {
                'border-bottom': '2px solid transparent',
                'border-image': PALETTE.primary.horizontal,
                'border-image-slice': '1',
              },
              '&:hover:not(.Mui-disabled, .Mui-error):before': {
                'border-image': PALETTE.primary.main,
              },
              '&.Mui-focused:after': {
                'border-image': PALETTE.primary.light,
              },
            },
            '.Mui-error': {
              '&:before, &:after': {
                'border-bottom': '2px solid transparent',
                'border-image': PALETTE.error.horizontal,
                'border-image-slice': '1',
              },
            },
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => {
          const color = ownerState.color || 'primary';
          const background =
            ownerState.variant === 'contained' && !ownerState.disabled
              ? ownerState.rarity &&
                Object.keys(PALETTE.rarity).includes(
                  ownerState.rarity as string,
                )
                ? PALETTE.rarityBackground[ownerState.rarity as string]
                : PALETTE[color as string].horizontal
              : undefined;
          const boxShadow =
            ownerState.variant === 'contained' && !ownerState.disabled
              ? SHADOWS_HARD[`${color}Elevation2`]
              : undefined;
          const textColor = ownerState.disabled
            ? PALETTE.text.disabled
            : undefined;

          return {
            color: textColor,
            boxShadow,
            background,
            borderRadius: 8,
            transition: '0.15s ease-out all',
          };
        },
      },
      variants: [
        ...BUTTON_VARIANTS_CONTAINED,
        ...BUTTON_VARIANTS_OUTLINED,
        ...BUTTON_VARIANTS_TEXT,
        {
          props: { size: 'large' },
          style: {
            borderRadius: 8,
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '26px',
            letterSpacing: '0.56px',
            textTransform: 'uppercase',
          },
        },
        {
          props: { size: 'medium' },
          style: {
            borderRadius: 8,
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '24px',
            letterSpacing: '0.56px',
            textTransform: 'uppercase',
          },
        },
        {
          props: { size: 'small' },
          style: {
            borderRadius: 8,
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '13px',
            lineHeight: '22px',
            letterSpacing: '0.56px',
            textTransform: 'uppercase',
          },
        },
      ],
    },
  },
});

export type SxPropsTheme = SxProps<typeof theme>;

export default theme;

import Box from '@mui/material/Box';
import { MessageActionsMenu } from './MessageActionsMenu';
import { AvatarNext } from '../../avatar/AvatarNext';
import Stack from '@mui/material/Stack';
import { useEffect, useState, useRef, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { useMessage } from './MessageContext';
import { HoverProvider } from '../../../contexts/HoverContext';
import { MessageBubble } from './MessageBubble';
import { Attachments } from '../attachments/Attachments';
import { MessageInteractions } from './MessageInteractions';
import { MESSAGE_MAX_WIDTH } from './Message';
import { useTheme } from '@mui/material/styles';

export const MESSAGE_INTERACTIONS_WIDTH = 168;

const MessageBodyUnmemoized = () => {
  const {
    isUserInfoShown,
    isMine,
    groupStyles,
    message,
    isDm,
    threadList: isThreadMessage,
    isInteractionsShown,
  } = useMessage();

  const { attachments, type, user: messageUser } = message;

  const flexDirection = isMine ? 'row-reverse' : 'row';
  const alignItems = isMine ? 'flex-end' : 'flex-start';
  const showAttachments = !!attachments?.length && type !== 'deleted';
  const messageWidthRef = useRef<HTMLDivElement>(null);
  const [messageWidth, setMessageWidth] = useState<number | null>(null);
  const anchorRef = useRef<SVGSVGElement | null>(null);
  const theme = useTheme();

  const addPadding = !isUserInfoShown && !isMine && !isDm && !isThreadMessage;

  useEffect(() => {
    if (!messageWidthRef.current) {
      return;
    }
    const boundingClient = messageWidthRef.current.getBoundingClientRect();

    setMessageWidth(boundingClient.width);
  }, []);

  const userAvatar = useMemo(() => {
    return (
      isUserInfoShown && (
        <AvatarNext
          src={messageUser?.image}
          sx={{
            height: '32px',
            width: '32px',
            '&& #inline-span': {
              height: '32px',
              width: '32px',
            },
            '&& #inline-span > span': {
              height: '100% !important',
              width: '100% !important',
            },
          }}
        />
      )
    );
  }, [isUserInfoShown, messageUser?.image]);

  const messageInteractionsOwn = useMemo(() => {
    return isMine && <MessageInteractions anchorRef={anchorRef} />;
  }, [isMine, anchorRef]);

  const messageAttachments = useMemo(() => {
    return showAttachments && <Attachments attachments={attachments} />;
  }, [attachments, showAttachments]);

  const messageInteractions = useMemo(() => {
    return (
      isInteractionsShown &&
      !isMine && (
        <Box
          sx={{
            position: 'absolute',
            right:
              !!messageWidth && messageWidth > MESSAGE_INTERACTIONS_WIDTH
                ? -(MESSAGE_INTERACTIONS_WIDTH / 3)
                : undefined,
            left:
              !!messageWidth && messageWidth < MESSAGE_INTERACTIONS_WIDTH
                ? messageWidth / 3
                : undefined,
            zIndex: theme.zIndex.tooltip,
            top: -26,
          }}
        >
          <MessageInteractions anchorRef={anchorRef} />
        </Box>
      )
    );
  }, [isInteractionsShown, isMine, messageWidth, theme.zIndex.tooltip]);

  return (
    <HoverProvider>
      <Stack
        ref={anchorRef}
        direction={flexDirection}
        alignItems={'flex-end'}
        spacing={2}
        pt={
          groupStyles?.includes('top') || groupStyles?.includes('single')
            ? 4
            : 0
        }
      >
        {userAvatar}
        <Box position="relative" pl={addPadding ? 10 : 0}>
          <Stack direction="row" alignItems="center" spacing={2}>
            {messageInteractionsOwn}
            <Stack
              ref={messageWidthRef}
              direction="column"
              alignItems={alignItems}
              sx={{
                maxWidth: MESSAGE_MAX_WIDTH,
              }}
            >
              {messageAttachments}
              <MessageBubble />
            </Stack>
          </Stack>
          {messageInteractions}
          <MessageActionsMenu />
        </Box>
      </Stack>
    </HoverProvider>
  );
};

export const MessageBody = memo(MessageBodyUnmemoized);

import { useCallback, useEffect, useRef, useMemo } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useDynamic } from '../useDynamic';
import { toPresencePath } from '../../../functions/src/util/presence/toPresencePath';
import {
  PresenceOperation,
  Viewer,
} from '../../../functions/src/util/presence/PresenceProcessor';

export const useTrackPresence = () => {
  const { userDataFull } = useAuth();
  const activeElementRef = useRef<string | null>(null);
  const databaseModule = useDynamic(import('firebase/database'));
  const realtimeDbModule = useDynamic(
    import('../../config/firebase-client/database'),
  );

  const viewer: Viewer | undefined = useMemo(() => {
    if (!userDataFull) {
      return;
    }

    return {
      id: userDataFull.id,
      username: userDataFull.username,
      imgUrl: userDataFull.imgUrl,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDataFull?.id, userDataFull?.username, userDataFull?.imgUrl]);

  const trackPresence = useCallback(
    async (elementId: string, operation: PresenceOperation) => {
      if (!viewer || !databaseModule || !realtimeDbModule) {
        return;
      }

      const { ref, set, remove, onDisconnect } = databaseModule;
      const { database } = realtimeDbModule;

      const dbRef = ref(
        database,
        toPresencePath({
          elementId,
          choice: 'liveViewers',
          viewerId: viewer.id,
        }),
      );

      if (operation === 'add' && !activeElementRef.current) {
        activeElementRef.current = elementId;

        await set(dbRef, viewer);

        return onDisconnect(dbRef).remove();
      }

      if (operation === 'remove' && activeElementRef.current) {
        activeElementRef.current = null;

        return await remove(dbRef);
      }
    },
    [databaseModule, realtimeDbModule, viewer],
  );

  useEffect(() => {
    if (userDataFull?.id || !activeElementRef.current) {
      return;
    }

    const elementId = activeElementRef.current;
    trackPresence(elementId, 'remove');
  }, [userDataFull?.id, trackPresence]);

  return trackPresence;
};

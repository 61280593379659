/* eslint-disable security/detect-object-injection */
import { useTheme } from '@mui/material/styles';
import type { StatusEnum } from '../../../functions/src/types/realtime/status';
import type { SpreadColorKey } from '../../styles/colors';
import { isStatusEnum } from '../../../functions/src/types/realtime/status/util';

export const STATUS_TO_COLOR: Record<StatusEnum, SpreadColorKey | 'disabled'> =
  {
    online: 'secondary',
    offline: 'disabled',
    away: 'warning',
    'do not disturb': 'error',
  } as const;

export const useStatusColor = <TColor extends string>(color: TColor) => {
  const { palette } = useTheme();

  if (!isStatusEnum(color)) {
    return color;
  }

  const colorKey = STATUS_TO_COLOR[color] || 'primary';
  return palette[colorKey].main as TColor;
};

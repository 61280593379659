import { useRef, useEffect, useMemo, useContext } from 'react';
import { MatchAggregated } from '../../../functions/src/types/firestore/Game/Tournament/Bracket';
import { useAuth } from '../../contexts/AuthContext';
import { useGlobalComponentsContext } from '../../contexts/GlobalComponentsContext';
import { useLocateViewport } from '../visibility/useLocateViewport';
import Snackbar from '@mui/material/Snackbar';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDownRounded';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUpRounded';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import { useTheme } from '@mui/material/styles';
import {
  firstScrollableParent,
  scrollToHorizontally,
} from '../../util/scrollToHorizontally';
import { useSocialDrawer } from '../useSocialDrawer';
import { BracketContext } from '../../contexts/docs/BracketContext';
import { useScrollTo } from '../useScrollTo';
import { userInMatch } from '../../util/tournaments/userInMatch';
import { Button } from '@mui/material';

export const WAYFINDER_ID = 'BRACKET_WAYFINDER_BUTTON';

export type WayfinderProps = {
  match: MatchAggregated<Date>;
  isFinal?: boolean;
};

export function useWayfinder({ match, isFinal }: WayfinderProps) {
  const bracket = useContext(BracketContext);
  const roundsLength = bracket?.bracket.length || 0;
  const { team1, team2, winner, nextLoser } = match;
  const { scrollToRef } = useScrollTo();
  const { isSocialDrawerOpen } = useSocialDrawer();
  const { uid } = useAuth();

  const isUserInMatch = userInMatch([team1, team2], uid);
  const hasUserWonFinal = uid && isFinal && winner?.userIds.includes(uid);
  const hasUserBeenEliminated =
    uid && winner && winner.userIds.includes(uid) && !nextLoser;
  const isInProgress = !winner;
  const isStanding =
    isUserInMatch && (hasUserWonFinal || hasUserBeenEliminated || isInProgress);

  const theme = useTheme();

  const target = useRef<HTMLDivElement>(null);
  const scrollableParent = useMemo(() => {
    if (!target || !target.current) {
      return null;
    }
    return firstScrollableParent(target.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target.current]);
  const visibility = useLocateViewport({
    target: isStanding ? target.current : undefined,
    scrollableParent: isStanding ? scrollableParent : undefined,
  });

  const WayfinderButton = useMemo(() => {
    return roundsLength > 5 ? (
      <Snackbar
        open={true}
        anchorOrigin={{
          vertical: visibility.vertical === 'below' ? 'bottom' : 'top',
          horizontal: 'center',
        }}
        sx={{
          borderRadius: '50vh',
          '&.MuiSnackbar-root': {
            left: `calc(50% - ${isSocialDrawerOpen ? '224px' : '38px'})`,
          },
        }}
      >
        <Button
          onClick={() => {
            if (!target.current) {
              return;
            }
            if (scrollableParent) {
              scrollToHorizontally(target.current, scrollableParent);
            }
            scrollToRef(target);
          }}
          variant="contained"
          size="large"
          sx={{
            borderRadius: '50vh',
            boxShadow: theme.shadows[2],
          }}
          endIcon={
            visibility.vertical === 'below'
              ? KeyboardDoubleArrowDownIcon
              : visibility.vertical === 'above'
              ? KeyboardDoubleArrowUpIcon
              : visibility.horizontal === 'left'
              ? KeyboardDoubleArrowLeftIcon
              : visibility.horizontal === 'right'
              ? KeyboardDoubleArrowRightIcon
              : undefined
          }
        >
          JUMP TO PERSONAL STANDING
        </Button>
      </Snackbar>
    ) : (
      <></>
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    target,
    visibility.vertical,
    visibility.horizontal,
    scrollableParent,
    isSocialDrawerOpen,
  ]);

  const { union, remove } = useGlobalComponentsContext();
  useEffect(() => {
    if (!isStanding) {
      return;
    }
    if (
      visibility.vertical === 'visible' &&
      visibility.horizontal === 'visible'
    ) {
      return remove(WAYFINDER_ID);
    }
    union(WAYFINDER_ID, WayfinderButton);
    return () => {
      remove(WAYFINDER_ID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isStanding,
    visibility.vertical,
    visibility.horizontal,
    isSocialDrawerOpen,
  ]);

  return target;
}

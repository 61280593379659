import Box from '@mui/material/Box';
import { ReactNode, useCallback } from 'react';
import { memo } from '../../../util/memo';
import { useFullscreenPreview } from '../../../hooks/messaging/useFullscreenPreview';
import { FanningPreview } from './FanningPreview';
import FullscreenIcon from '@mui/icons-material/FullscreenRounded';
import { useTheme } from '@mui/material/styles';
import { PreviewCount } from './PreviewCount';
import { useMessage } from '../message/MessageContext';

export type FanningPreviewFullscreenableProps = {
  previewImages: ReactNode[];
  fullscreenImages: ReactNode[];
  previewType: string;
};

const FanningPreviewFullscreenableUnmemoized = ({
  fullscreenImages,
  previewImages,
  previewType,
}: FanningPreviewFullscreenableProps) => {
  const { previewFullscreen } = useFullscreenPreview();
  const { isMine, linkConfig } = useMessage();
  const theme = useTheme();

  const previewMargin = Math.min(previewImages.length - 1, 3) * 14;

  const goFullscreen = useCallback(() => {
    return previewFullscreen({
      FullscreenContent: fullscreenImages,
    });
  }, [fullscreenImages, previewFullscreen]);

  return (
    <Box
      sx={{
        mb: 2,
        backgroundColor: !!Object.keys(linkConfig).length
          ? isMine
            ? theme.palette.primary.mid
            : theme.palette.background.elevationSolid[18]
          : undefined,
        borderRadius: '16px',
      }}
    >
      {previewImages?.length > 1 && (
        <PreviewCount
          previewsLength={previewImages.length}
          previewType={previewType}
        />
      )}
      <Box position="relative" mr={isMine ? `${previewMargin}px` : 0}>
        <FanningPreview Previews={previewImages} />
        <Box
          position="absolute"
          bottom={0}
          right={0}
          sx={{ zIndex: theme.zIndex.speedDial }}
        >
          <FullscreenIcon
            sx={{
              mr: '6px',
              p: 0.5,
              width: '24px',
              height: '24px',
              background: theme.palette.background.elevation['24'],
              color: theme.palette.primary.mid,
              borderRadius: '50%',
              ':hover': {
                cursor: 'pointer',
              },
            }}
            onClick={goFullscreen}
          />
        </Box>
      </Box>
    </Box>
  );
};

export const FanningPreviewFullscreenable = memo(
  FanningPreviewFullscreenableUnmemoized,
);

import AvatarGroup, { AvatarGroupProps } from '@mui/material/AvatarGroup';
import { memo } from '../../util/memo';
import { AvatarStatus, AvatarStatusProps } from './AvatarStatus';
import { useOnlineStatus } from '../../hooks/useOnlineStatus';
import { AvatarGroupUser } from '../../../functions/src/types/firestore/User/ChannelGroup/util/avatarGroupUser';
import { SxProps, useTheme } from '@mui/material/styles';
import { AvatarSurplus } from './AvatarSurplus';
import { useCallback, useMemo } from 'react';
import { adjustSize } from '../../util/styles/adjustSize';

export const AVATAR_GROUP_MAX = 5 as const;

export type AvatarGroupNextProps = Omit<AvatarGroupProps, 'sx'> &
  Omit<AvatarStatusProps, 'imgUrl' | 'onlineStatus'> & {
    users: AvatarGroupUser[];
    borderColor?: string;
    surplusSx?: SxProps;
    sx?: SxProps;
  };

const AvatarGroupNextUnmemoized = (props: AvatarGroupNextProps) => {
  const theme = useTheme();

  const {
    users,
    borderColor,
    showStatus = false,
    height = users.length > 1 ? { xs: 30, md: 42 } : { xs: 44, md: 56 },
    width = users.length > 1 ? { xs: 30, md: 42 } : { xs: 44, md: 56 },
    avatarSx = {},
    sx = {},
    surplusSx = {},
    badgeSx = {},
    ...rest
  } = props;

  const userIds = users.map(({ userId }) => {
    return userId;
  });
  const { status } = useOnlineStatus(userIds);

  const onlineStatus = useMemo(() => {
    return userIds.some((userId) => {
      return status[userId as string];
    })
      ? 'online'
      : 'offline';
  }, [userIds, status]);

  const avatars = useMemo(() => {
    return users.map(({ userId, imgUrl }, index) => {
      return (
        <AvatarStatus
          key={userId}
          imgUrl={imgUrl}
          height={height}
          width={width}
          badgeSx={badgeSx}
          avatarSx={{
            border: `2px solid ${
              borderColor || theme.palette.background.elevation[4]
            }`,
            ...avatarSx,
          }}
          onlineStatus={onlineStatus}
          showStatus={index === 0 && showStatus}
        />
      );
    });
  }, [
    users,
    height,
    width,
    badgeSx,
    borderColor,
    theme.palette.background.elevation,
    avatarSx,
    onlineStatus,
    showStatus,
  ]);

  const Surplus = useCallback(
    (surplus: number) => {
      return (
        <AvatarSurplus
          surplus={surplus}
          avatarCount={users.length}
          sx={{
            ...surplusSx,
            fontSize: adjustSize(height, (n: number) => {
              return n / 1.5;
            }),
          }}
        />
      );
    },
    [surplusSx, users.length, height],
  );

  if (users.length > 1) {
    return (
      <AvatarGroup
        {...rest}
        renderSurplus={Surplus}
        sx={{
          ...sx,
          '.MuiAvatar-root': {
            height,
            width,
            ...sx['.MuiAvatar-root'],
          },
        }}
      >
        {avatars}
      </AvatarGroup>
    );
  }

  // eslint-disable-next-line @blumintinc/blumint/no-useless-fragment
  return <>{avatars}</>;
};
export const AvatarGroupNext = memo(AvatarGroupNextUnmemoized);

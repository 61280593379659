import Button from '@mui/material/Button';
import ArrowOutwardRoundedIcon from '@mui/icons-material/ArrowOutwardRounded';
import { useMemo, useCallback } from 'react';
import { useChatContext as useStreamChatContext } from 'stream-chat-react';
import { useActiveTournamentChannel } from './useActiveTournamentChannel';
import { useAuth } from '../../contexts/AuthContext';

export const useChannelNavigationButton = (tournamentId?: string) => {
  const { userData } = useAuth();
  const { channel } = useStreamChatContext();
  const { openTeamChannel, openMatchChannel, teamChannelGroup } =
    useActiveTournamentChannel(tournamentId);

  const matchId = useMemo(() => {
    return userData?.currentTournament?.currentMatch;
  }, [userData?.currentTournament?.currentMatch]);

  const openChannel = useCallback(() => {
    const { type } = channel || {};

    if (!type) {
      return;
    }

    if (type === 'match' || type === 'general') {
      return openTeamChannel();
    }

    if (!matchId) {
      return;
    }

    openMatchChannel(matchId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openMatchChannel, openTeamChannel, matchId, channel?.type]);

  const label = useMemo(() => {
    const { type, data } = channel || {};
    return (type === 'match' || type === 'general') && !!teamChannelGroup
      ? 'Team Chat'
      : type === 'personalGroup' && !!data?.tournamentId && matchId
      ? 'Match Lobby'
      : undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel?.data, channel?.type, matchId, teamChannelGroup]);

  const navigationButton = useMemo(() => {
    return !!label ? (
      <Button
        variant="contained"
        endIcon={<ArrowOutwardRoundedIcon />}
        onClick={openChannel}
        sx={{
          py: 0,
          px: 2,
          mr: 2,
          width: 'fit-content',
          borderRadius: '4px',
        }}
      >
        {label}
      </Button>
    ) : (
      <></>
    );
  }, [label, openChannel]);

  return { navigationButton };
};

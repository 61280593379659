import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { useCallback, useMemo } from 'react';
import { TabsRouted } from './routing/TabsRouted';
import { useRouterState } from '../hooks/routing/useRouterState';
import { useSocialDrawer } from '../hooks/useSocialDrawer';
import { FriendRequestsBadge } from './social-drawer/friends/FriendRequestsBadge';
import { memo } from '../util/memo';
import { useGuardSignIn } from '../hooks/useGuardSignIn';
import { useAuth } from '../contexts/AuthContext';
import { useActiveChannelGroup } from '../contexts/ActiveChannelGroupContext';
import { SOCIAL_DRAWER_WIDTH_DESKTOP } from './Framework';
import { SIDEBAR_WIDTH } from './Sidebar';
import { useRouter } from '../hooks/routing/useRouter';
import { useTheme } from '@mui/material/styles';

export const FRIENDS_SIGN_IN_TITLE = 'Want friends?';
export const MESSAGING_SIGN_IN_TITLE = 'DM your crush?';
export const APP_NAVIGATION_TAB_HEIGHT_MOBILE = 49 as const;
export const APP_NAVIGATION_TAB_HEIGHT = 60 as const;

export const APP_NAV_OPTIONS = ['Events', 'Friends', 'Feed'] as const;
export type AppNavOption = (typeof APP_NAV_OPTIONS)[number];

const AppNavigationTabsUnmemoized = () => {
  const { isSocialDrawerOpen } = useSocialDrawer();
  const { asPath } = useRouter();
  const { uid } = useAuth();
  const { openSignInGuard } = useGuardSignIn();
  const { closeChannelGroup } = useActiveChannelGroup();
  const theme = useTheme();

  const tabState = useRouterState({
    key: 'nav',
    defaultValue: 'Events' as AppNavOption,
  });

  const tabClick = useCallback(
    (go) => {
      go();
      closeChannelGroup();
    },
    [closeChannelGroup],
  );

  const guardedTabClick = useCallback(
    (go) => {
      if (!uid) {
        openSignInGuard(FRIENDS_SIGN_IN_TITLE);
      }
      tabClick(go);
    },
    [uid, tabClick, openSignInGuard],
  );

  const exploreLabel = useMemo(() => {
    if (asPath.includes('/profile/')) {
      return 'Profile';
    }
    if (asPath.includes('/contact')) {
      return 'Contact';
    }
    return 'Events';
  }, [asPath]);

  const appNavigationTabs = useMemo(() => {
    return [
      {
        value: 'Events' as AppNavOption,
        customization: {
          variant: 'large',
          label: exploreLabel,
          sx: { flex: 1, display: { xs: 'flex', md: 'none' } },
        },
        onClick: (_, go) => {
          return tabClick(go);
        },
      },
      {
        value: 'Feed' as AppNavOption,
        customization: {
          variant: 'large',
          label: 'Feed',
          sx: { flex: 1, display: { xs: 'none', md: 'flex' } },
        },
        Wrapper: (children) => {
          return (
            <Stack
              sx={{
                flex: 1,
              }}
            >
              <Tooltip title={'coming soon!'} arrow>
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                  }}
                >
                  {children}
                </div>
              </Tooltip>
            </Stack>
          );
        },
      },
      {
        value: 'Friends' as AppNavOption,
        onClick: (_, go) => {
          return guardedTabClick(go);
        },
        customization: {
          variant: 'large',
          label: 'Friends',
          sx: { flex: 1 },
        },
        Wrapper: (children) => {
          return (
            <FriendRequestsBadge
              sx={{
                flex: 1,
                '.MuiBadge-badge': {
                  display: {
                    xs: 'flex',
                    md: 'none',
                  },
                  mr: 1,
                  mt: 1,
                },
              }}
            >
              {children}
            </FriendRequestsBadge>
          );
        },
      },
    ];
  }, [exploreLabel, guardedTabClick, tabClick]);

  return (
    <Box
      sx={{
        pb: { xs: 2, md: 0 },
        width: {
          xs: '100%',
          md: isSocialDrawerOpen
            ? `${SOCIAL_DRAWER_WIDTH_DESKTOP - SIDEBAR_WIDTH}px`
            : `0px`,
        },
        transition: { xs: undefined, md: 'width 300ms ease-in-out' },
        borderBottom: {
          xs: 'none',
          md: theme.border,
        },
      }}
    >
      <TabsRouted
        tabs={appNavigationTabs}
        variant={'chip'}
        state={tabState}
        sx={{
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          px: 2,
          display: { xs: 'flex', md: isSocialDrawerOpen ? 'flex' : 'none' },
          opacity: { xs: 1, md: isSocialDrawerOpen ? 1 : 0 },
          animation: isSocialDrawerOpen
            ? 'opacity-delayed 250ms ease-in-out'
            : undefined,
          '@keyframes opacity-delayed': {
            '0%': {
              opacity: 0,
            },
            '90%': {
              opacity: 0,
            },
            '100%': {
              opacity: 1,
            },
          },
          '& .MuiChip-root': {
            height: '40px !important',
          },
          '& > *': {
            ml: '0px !important',
            height: '40px !important',
          },
        }}
      />
    </Box>
  );
};
export const AppNavigationTabs = memo(AppNavigationTabsUnmemoized);

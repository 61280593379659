/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Attachment as StreamAttachment } from 'stream-chat-react';
import type { Attachment } from 'stream-chat';
import { useMemo } from 'react';
import { memo } from '../../../util/memo';
import { FanningPreviewFullscreenable } from './FanningPreviewFullscreenable';
import { ImageOptimized } from '../../image/ImageOptimized';
import {
  calculateImageDimensions,
  LINK_IMAGE_HEIGHT,
} from '../../../util/messaging/calculateImageDimensions';
import { MESSAGE_MAX_WIDTH } from '../message/Message';

export type AttachmentsProps = {
  attachments: Attachment[];
};

const AttachmentsUnmemoized = ({ attachments }: AttachmentsProps) => {
  const sortedAttachments = useMemo(() => {
    return attachments
      .map((attachment) => {
        const { original_height, original_width } = attachment;
        const dimensions = calculateImageDimensions({
          width: original_width || MESSAGE_MAX_WIDTH,
          height: original_height || LINK_IMAGE_HEIGHT,
        });
        return {
          ...attachment,
          ...dimensions,
        };
      })
      .sort((a, b) => {
        return b.height - a.height;
      });
  }, [attachments]);

  const Previews = useMemo(() => {
    return sortedAttachments.map((attachment) => {
      const { image_url, height, width, title_link } = attachment;
      const image = (
        <ImageOptimized
          src={image_url!}
          width={width}
          height={height}
          style={{
            borderRadius: '16px',
          }}
        />
      );

      if (!title_link) {
        return <div key={image_url}>{image}</div>;
      }
      return (
        <a key={image_url} href={title_link} target="_blank" rel="noreferrer">
          {image}
        </a>
      );
    });
  }, [sortedAttachments]);

  const Fullscreens = useMemo(() => {
    return sortedAttachments.map(({ image_url }) => {
      return (
        <ImageOptimized
          key={image_url}
          src={image_url!}
          layout="fill"
          objectFit="scale-down"
          sizes="100vw"
        />
      );
    });
  }, [sortedAttachments]);

  return attachments[0].type === 'image' || !!attachments[0].title_link ? (
    <FanningPreviewFullscreenable
      previewImages={Previews}
      fullscreenImages={Fullscreens}
      previewType="attachments"
    />
  ) : (
    <StreamAttachment attachments={attachments} />
  );
};

export const Attachments = memo(AttachmentsUnmemoized);

import Typography from '@mui/material/Typography';
import { ReactNode, useMemo } from 'react';
import { memo } from '../../../util/memo';
import Stack from '@mui/material/Stack';
import { GradientTypography } from 'src/components/gradients/GradientTypography';
import { useTheme } from '@mui/material/styles';
import { Link } from '../../Link';
import { GradientIconButton } from '../../gradients/GradientIconButton';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { useActiveChannelGroup } from '../../../contexts/ActiveChannelGroupContext';
import { PermanenceButton } from '../PermanenceButton';
import { ChannelGroup } from '../../../../functions/src/types/firestore/User/ChannelGroup';
import { useChannelList } from '../../../contexts/ChannelListContext';
import { ELLIPSIS_SX } from '../../../util/string';
import { useChannelNavigationButton } from '../../../hooks/messaging/useChannelNavigationButton';

export const CHANNEL_GROUP_HEADER_HEIGHT = 60 as const;
export const ICON_BUTTON_WIDTH = 30 as const;

const TEXT_SX = { maxWidth: '224px' } as const;

export type ChannelGroupHeaderProps = Pick<
  ChannelGroup,
  'permanence' | 'title' | 'subtitle'
> & {
  gradientColor?: string;
  Figure: ReactNode;
  href?: string;
  tournamentId?: string;
};

const ChannelGroupHeaderUnmemoized = ({
  title,
  subtitle,
  Figure,
  href,
  tournamentId,
  permanence,
  gradientColor = 'primary.vertical',
}: ChannelGroupHeaderProps) => {
  const theme = useTheme();
  const { isChannelListVisible } = useChannelList();
  const { closeChannelGroup } = useActiveChannelGroup();
  const { navigationButton } = useChannelNavigationButton(tournamentId);

  const content = useMemo(() => {
    return (
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={{
          minHeight: `${CHANNEL_GROUP_HEADER_HEIGHT - 1}px`,
        }}
      >
        {Figure}
        <Stack direction="column" sx={TEXT_SX}>
          <GradientTypography
            variant="h6"
            gradientColor={gradientColor}
            textTransform="uppercase"
            sx={ELLIPSIS_SX}
          >
            {title}
          </GradientTypography>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            sx={ELLIPSIS_SX}
          >
            {subtitle}
          </Typography>
        </Stack>
      </Stack>
    );
  }, [Figure, gradientColor, title, subtitle]);

  const header = useMemo(() => {
    return href ? (
      <Link href={href} style={{ textDecoration: 'none', display: 'flex' }}>
        {content}
      </Link>
    ) : (
      content
    );
  }, [content, href]);

  const permanenceButton = useMemo(() => {
    return (
      !!tournamentId &&
      permanence !== 'fixed' && (
        <PermanenceButton permanence={permanence} tournamentId={tournamentId} />
      )
    );
  }, [tournamentId, permanence]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        backgroundColor: theme.palette.background.elevationSolid[0],
        display: { xs: isChannelListVisible ? 'flex' : 'none', md: 'flex' },
        borderBottom: {
          xs: 'none',
          md: theme.border,
        },
        pl: 4,
        pr: 2,
      }}
    >
      {header}
      <Stack direction="row" alignItems="center" sx={{ width: 'fit-content' }}>
        {navigationButton}
        {permanenceButton}
        <GradientIconButton
          IconComponent={CloseIcon}
          onClick={closeChannelGroup}
          sxOuter={{ height: '28px', width: '28px', alignSelf: 'center' }}
          sx={{ height: '24px', width: '24px' }}
        />
      </Stack>
    </Stack>
  );
};

export const ChannelGroupHeader = memo(ChannelGroupHeaderUnmemoized);

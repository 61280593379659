import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { GradientTypography } from '../gradients/GradientTypography';
import { memo } from '../../util/memo';
import { useTheme } from '@mui/material/styles';
import { GradientIconButton } from '../gradients/GradientIconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOffRounded';
import { ELLIPSIS_SX } from '../../util/string';

export type ReplyIndicatorProps = {
  username: string;
  onClick: () => void;
};

const ReplyIndicatorUnmemoized = ({ username, onClick }) => {
  const theme = useTheme();
  return (
    <Stack
      width="100%"
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        px: 8,
        mt: 2,
        background: theme.palette.background.elevationSolid[18],
      }}
    >
      <Stack direction="row" spacing={1}>
        <Typography
          variant="subtitle2"
          color="text.primary"
          sx={{ flexShrink: 0 }}
        >
          Replying to
        </Typography>
        <GradientTypography
          variant="subtitle2"
          gradientColor="primary.vertical"
          sx={ELLIPSIS_SX}
        >
          {username}
        </GradientTypography>
      </Stack>
      <GradientIconButton
        gradientColor="primary.horizontal"
        IconComponent={HighlightOffIcon}
        onClick={onClick}
        sx={{ height: '20px', width: '20px' }}
      />
    </Stack>
  );
};

export const ReplyIndicator = memo(ReplyIndicatorUnmemoized);

import { type TrackReference } from '@livekit/components-core';
import { RemoteParticipant, LocalParticipant } from 'livekit-client';
import { useMemo } from 'react';
import { memo } from '../../util/memo';
import { CallerCardBase } from './CallerCardBase';
import { KickCallerButton } from './KickCallerButton';
import { MuteCallerButton } from './MuteCallerButton';
import { CallerHit } from './CallersVerticalCarousel';
import { useRoledUser } from '../../hooks/messaging/useRoledUser';
import { SpeakerIcon } from './SpeakerIcon';

export type CallerCardActiveProps = CallerHit & {
  caller: RemoteParticipant | LocalParticipant;
  audioTrack: TrackReference;
};

const CallerCardActiveUnmemoized = ({
  caller,
  audioTrack,
  ...hit
}: CallerCardActiveProps) => {
  const { hasAnyRole } = useRoledUser();
  const { id: userId } = hit;

  const muteCallerButton = useMemo(() => {
    return (
      caller instanceof RemoteParticipant && (
        <MuteCallerButton caller={caller} />
      )
    );
  }, [caller]);

  const kickCallerButton = useMemo(() => {
    return (
      hasAnyRole &&
      caller instanceof RemoteParticipant && (
        <KickCallerButton userId={userId} />
      )
    );
  }, [hasAnyRole, userId, caller]);

  const speakerIcon = useMemo(() => {
    return <SpeakerIcon audioTrack={audioTrack} caller={caller} />;
  }, [audioTrack, caller]);

  return (
    <CallerCardBase icon={speakerIcon} {...hit}>
      {muteCallerButton}
      {kickCallerButton}
    </CallerCardBase>
  );
};

export const CallerCardActive = memo(CallerCardActiveUnmemoized);

import { FC } from 'react';
import { memo } from '../../util/memo';
import { AdHorizontalRail } from './AdHorizontalFill';
import { FOOTER_AD } from '../../../functions/src/util/ads/adIds';
import { useTheme } from '@mui/material/styles';
import { useMobileAgent } from '../../hooks/useMobileAgent';

const AdFooterUnmemoized: FC = ({}) => {
  const isMobile = useMobileAgent();

  const theme = useTheme();

  return (
    <AdHorizontalRail
      height={isMobile ? 50 : 100}
      pattern="tile"
      sticky={true}
      id={FOOTER_AD}
      sx={{
        position: undefined,
        top: undefined,
        borderTop: theme.border,
      }}
      borderRadius="0px"
    />
  );
};

export const AdFooter = memo(AdFooterUnmemoized);

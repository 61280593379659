import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { memo } from '../util/memo';
import { useTheme } from '@mui/material/styles';
import { useAuthFlowDialog } from '../hooks/auth/useAuthFlowDialog';
import Button from '@mui/material/Button';

export type SignUpPromptedProps = {
  prompt: string;
};

const SignUpPromptedUnmemoized = ({ prompt }: SignUpPromptedProps) => {
  const theme = useTheme();
  const { open: openAuthDialog } = useAuthFlowDialog();

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      spacing={4}
    >
      {/* <GradientIcon
        IconComponent={LoginIcon}
        sx={{ height: '48px', width: '48px' }}
      /> */}
      <Typography variant="subtitle1" color="text.secondary">
        {prompt}
      </Typography>
      <Button
        variant="contained"
        onClick={() => {
          openAuthDialog();
        }}
        sx={{
          height: '42px',
          width: '181px',
          // borderRadius: '24px',
          // '.MuiTypography-root': {
          //   WebkitTextFillColor: `${theme.palette.text.primary} !important`,
          // },
        }}
      >
        Sign up
      </Button>
    </Stack>
  );
};

export const SignUpPrompted = memo(SignUpPromptedUnmemoized);

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { memo } from '../../../util/memo';
import { GradientIconButton } from '../../gradients/GradientIconButton';
import EditIcon from '@mui/icons-material/EditRounded';
import CheckIcon from '@mui/icons-material/CheckRounded';
import Stack from '@mui/material/Stack';
import { useLoadingHandler } from 'src/hooks/useLoadingHandler';
import { LottieLoader } from 'src/components/LottieLoader';
import { useTournamentRegistration } from '../../../contexts/TournamentRegistrationContext';
import { useUpdateTeamAvatar } from '../../../hooks/registration/useUpdateAvatar';

export const TeamAvatarRegistration = memo(
  function TeamAvatarRegistrationUnmemoized() {
    const theme = useTheme();
    const { phase, foundTeam } = useTournamentRegistration();
    const { avatarUrl } = foundTeam!;
    const { updateAvatar } = useUpdateTeamAvatar();
    const [editMode, setEditMode] = useState(false);

    const allowEdit = useMemo(() => {
      return phase === 'checkIn' || phase === 'registration';
    }, [phase]);

    const toggleEditMode = () => {
      setEditMode((prev) => {
        return !prev;
      });
    };

    const editIcon = editMode ? CheckIcon : EditIcon;

    const uploadAvatar = useCallback(
      async (event: ChangeEvent<HTMLInputElement>) => {
        await updateAvatar(event);
      },
      [updateAvatar],
    );
    const [isLoading, uploadAvatarAsync] = useLoadingHandler(uploadAvatar);

    const removeAvatar = useCallback(async () => {
      await updateAvatar();
    }, [updateAvatar]);

    const avatar = useMemo(() => {
      return (
        <Avatar
          src={avatarUrl}
          alt=""
          sx={{
            width: '100%',
            height: '100%',
            border: `2px solid ${theme.palette.primary.main}`,
            ...theme.glow.panel,
          }}
        />
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [avatarUrl]);

    return (
      <Box
        sx={{
          position: 'relative',
          width: 175,
          height: 175,
          my: 2,
        }}
      >
        {avatar}
        {editMode && (
          <Stack
            spacing={3}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -55%)',
            }}
          >
            <Button
              variant="outlined"
              component="label"
              sx={{ height: 40, width: 100 }}
            >
              {!isLoading ? (
                'Upload'
              ) : (
                <LottieLoader sx={{ height: '24px', width: '24px' }} />
              )}
              <input
                hidden
                accept="image/*"
                multiple
                type="file"
                onChange={uploadAvatarAsync}
              />
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={removeAvatar}
              sx={{ height: 40, width: 100 }}
            >
              Remove
            </Button>
          </Stack>
        )}
        {allowEdit && (
          <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
            <GradientIconButton
              IconComponent={editIcon}
              onClick={toggleEditMode}
            />
          </Box>
        )}
      </Box>
    );
  },
);

import { useAuth } from '../../contexts/AuthContext';
import { useEffect } from 'react';
import { useVoiceChannel } from './useVoiceChannel';

export const useNextVoiceChannel = () => {
  const { voiceChannel, setVoiceChannel } = useVoiceChannel();
  const { userData } = useAuth();

  useEffect(() => {
    const handler = async () => {
      if (!!voiceChannel?.next && voiceChannel.next === voiceChannel.current) {
        const { resetNextVoiceChannel } = await import(
          '../../firebaseCloud/user/resetNextVoiceChannel'
        );
        const { deleteField } = await import('firebase/firestore');

        await resetNextVoiceChannel();

        return setVoiceChannel({ next: deleteField() });
      }

      if (
        !userData?.nextVoiceChannel ||
        userData.nextVoiceChannel === voiceChannel?.next
      ) {
        return;
      }

      setVoiceChannel({ next: userData.nextVoiceChannel });
    };
    handler();
  }, [setVoiceChannel, userData?.nextVoiceChannel, voiceChannel]);
};

import { useState, useCallback, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import { EditableStepsContextType } from '../../../contexts/organizer/EditableStepsContext';
import { MarkdownAccordionInputListItem } from './MarkdownAccordionInputListItem';
import type { DocumentData } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import AddIcon from '@mui/icons-material/AddRounded';
import { Button } from '@mui/material';

type MarkdownAccordionInputProps<
  TDoc extends DocumentData,
  TItem extends Record<string, string> & { id: string } = Record<
    string,
    string
  > & { id: string },
> = {
  items: TItem[];
  titleKey: keyof TItem;
  bodyKey: keyof TItem;
  onChange: EditableStepsContextType<TDoc>['updateField'];
  fieldName: string;
};

// TODO: type errors

const MarkdownAccordionInputUnmemoized = <
  TDoc extends DocumentData,
  TItem extends Record<string, string> & { id: string } = Record<
    string,
    string
  > & { id: string },
>({
  items = [],
  titleKey = 'title',
  bodyKey = 'body',
  onChange,
  fieldName,
}: MarkdownAccordionInputProps<TDoc, TItem>) => {
  const [localItems, setLocalItems] = useState<TItem[]>(items);
  const [editingIndex, setEditingIndex] = useState<number | undefined>(
    undefined,
  );
  useEffect(() => {
    setLocalItems(items);
  }, [items]);
  const handleAdd = useCallback(
    (newIndex: number) => {
      const newSection = {
        [titleKey]: `New Information`,
        [bodyKey]: ``,
        id: uuidv4(),
      } as TItem;
      setLocalItems((prev) => {
        return [...prev, newSection];
      });
      onChange([
        {
          action: 'arrayInsert',
          value: {
            insert: newSection,
            index: newIndex,
          },
          field: `${fieldName}`,
        },
      ]);
    },
    [titleKey, bodyKey, onChange, fieldName],
  );

  const handleDelete = useCallback(
    (index: number) => {
      setLocalItems((prev) => {
        return prev.filter((_, idx) => {
          return idx !== index;
        });
      });
      onChange([
        {
          field: fieldName,
          action: 'arrayDelete',
          value: { insert: undefined, index },
        },
      ]);
    },
    [fieldName, onChange],
  );

  const handleChange = useCallback(
    (index: number, field: keyof TItem, value: TItem[keyof TItem]) => {
      const updatedItems = [...localItems];
      // eslint-disable-next-line security/detect-object-injection
      updatedItems[index][field] = value;
      setLocalItems(updatedItems);
      onChange([
        {
          field: `${fieldName}.${index}.${String(field)}`,
          action: 'overwrite',
          value,
        },
      ]);
    },
    [fieldName, localItems, onChange],
  );

  return (
    <Stack spacing={2}>
      {localItems.map((item, index) => {
        return (
          <MarkdownAccordionInputListItem
            key={item.id || index}
            fieldName={fieldName}
            item={item}
            titleKey={titleKey as string}
            bodyKey={bodyKey as string}
            handleChange={(key, value) => {
              return handleChange(index, key, value);
            }}
            handleDelete={() => {
              return handleDelete(index);
            }}
            isEditing={editingIndex === index}
            setIsEditing={(isEditing: boolean) => {
              return setEditingIndex(isEditing ? index : undefined);
            }}
          />
        );
      })}

      <Button
        variant="outlined"
        sx={{ height: 35 }}
        onClick={() => {
          return handleAdd(localItems.length);
        }}
      >
        <Stack direction="row" alignItems={'center'}>
          <AddIcon fontSize="small"></AddIcon>
          New Section
        </Stack>
      </Button>
    </Stack>
  );
};

export const MarkdownAccordionInput = MarkdownAccordionInputUnmemoized;

import { useEffect, useMemo, useState } from 'react';
import type { DatabaseReference, Unsubscribe } from 'firebase/database';
import { useUserStatus } from '../contexts/UserStatusContext';
import { useDynamic } from './useDynamic';

export const useOnlineStatus = (userIds: (string | undefined)[]) => {
  const { userStatuses, setUserStatuses } = useUserStatus();
  const [relevantUserStatuses, setRelevantUserStatuses] = useState<
    Record<string, boolean>
  >({});

  const databaseModule = useDynamic(
    import('../config/firebase-client/database'),
  );
  const firebaseDatabaseModule = useDynamic(import('firebase/database'));

  useEffect(() => {
    if (!databaseModule || !firebaseDatabaseModule) {
      return;
    }
    const { onValue, off, ref } = firebaseDatabaseModule;
    const { database } = databaseModule;

    const userHandlers = userIds
      .map((userId) => {
        if (userId && !userStatuses.hasOwnProperty(userId)) {
          const realtimeDbRef = ref(database, `status/${userId}`);
          const handler = onValue(realtimeDbRef, (snapshot) => {
            const data = snapshot.val();
            const newUserStatus = data?.status === 'online';
            if (userStatuses[userId as string] !== newUserStatus) {
              setRelevantUserStatuses((prev) => {
                return {
                  ...prev,
                  [userId]: newUserStatus,
                };
              });
            }
          });

          return { userId, realtimeDbRef, handler };
        }
        return null;
      })
      .filter((userHandler) => {
        return userHandler !== null;
      }) as {
      userId: string;
      realtimeDbRef: DatabaseReference;
      handler: Unsubscribe;
    }[];

    return () => {
      userHandlers.forEach(({ userId, realtimeDbRef, handler }) => {
        off(realtimeDbRef, 'value', handler);
        if (userStatuses.hasOwnProperty(userId)) {
          setRelevantUserStatuses((prev) => {
            const updatedStatuses = { ...prev };
            delete updatedStatuses[String(userId)];
            return updatedStatuses;
          });
        }
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIds, databaseModule, firebaseDatabaseModule]);

  useEffect(() => {
    Object.keys(relevantUserStatuses).forEach((userId) => {
      setUserStatuses((prev) => {
        return {
          ...prev,
          [userId]: relevantUserStatuses[String(userId)],
        };
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relevantUserStatuses]);

  const status = useMemo(() => {
    return userIds.reduce((acc, userId) => {
      acc[String(userId)] = !!userStatuses[String(userId)];
      return acc;
    }, {});
  }, [userIds, userStatuses]);

  return { status };
};

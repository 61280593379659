import Box from '@mui/material/Box';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import Typography from '@mui/material/Typography';
import { GradientTypography } from 'src/components/gradients/GradientTypography';
import { useDateFormatter } from 'src/hooks/useDateFormatter';
import { memo } from '../../../../util/memo';
import { DialogBodyStandard } from 'src/components/dialog/DialogBodyStandard';
import Stack from '@mui/material/Stack';
import { useWizard } from 'src/components/wizard/Wizard';
import { formatDateRange } from '../../../../util/dates/Dates';
import { AlertStandard } from '../../../AlertStandard';
import { useTournamentRegistration } from '../../../../contexts/TournamentRegistrationContext';
import { RegisterStore } from './useRegisterDialogs';
import { DialogActionsStandard } from '../../../dialog/DialogActionsStandard';
import { useRegistrationFunctions } from '../../../../hooks/tournaments/useRegistrationFunctions';
import { NoticeParagraphed } from '../../../NoticeParagraphed';
import { useCallback, useMemo } from 'react';
import { useErrorAlert } from '../../../../hooks/useErrorAlert';
import { RSVP_ERROR_LIBRARY } from '../../../../../functions/src/util/error/library/rsvpTeam';

export type RulesRegisterDialogProps = {
  teamId?: string;
  onSubmit?: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  registrationType: string;
};

export const RulesRegisterDialogUnmemoized = ({
  registrationType,
  onCancel,
  onSubmit,
  teamId,
}: RulesRegisterDialogProps) => {
  const { go, get } = useWizard<RegisterStore>();
  const { catchError } = useErrorAlert(RSVP_ERROR_LIBRARY);
  const {
    phase,
    checkInTime,
    readyTime,
    date,
    rulesAndRegulations,
    skipCheckIn,
  } = useTournamentRegistration();

  const { registerSolo, createTeam, acceptTeamInvite } =
    useRegistrationFunctions();

  const details = get('details');

  const checkInWindowFormatted = formatDateRange(checkInTime, readyTime);
  const format = useDateFormatter({
    separator: ', ',
  });
  const startDateFormatted = format(date);

  const submitDetails = useCallback(async () => {
    if (!!teamId) {
      await acceptTeamInvite(details, teamId);
      return;
    }
    registrationType === 'solo'
      ? await registerSolo(details)
      : await createTeam(details);
  }, [
    acceptTeamInvite,
    createTeam,
    details,
    registerSolo,
    registrationType,
    teamId,
  ]);

  const accept = useCallback(async () => {
    await submitDetails();
    await onSubmit?.();
    go(undefined);
  }, [go, onSubmit, submitDetails]);

  const acceptSafely = useCallback(async () => {
    await catchError(accept, () => {
      return go(undefined);
    });
  }, [catchError, go, accept]);

  const buttons = useMemo(() => {
    return [
      {
        children: phase === 'checkIn' ? 'Check In' : 'Accept',
        isAsync: true,
        onClick: acceptSafely,
        color: 'secondary' as const,
        endIcon: <CheckCircleOutlineIcon />,
        sx: { flex: 2 },
      },
      {
        children: 'Cancel',
        isAsync: true,
        onClick: async () => {
          if (!!onCancel) {
            await onCancel();
          }
          return go(undefined);
        },
        sx: { textTransform: 'uppercase', flex: 1, ml: 1 },
        color: 'error' as const,
      },
    ];
  }, [phase, acceptSafely, onCancel, go]);

  return (
    <DialogBodyStandard
      title={'Tournament Rules'}
      description={
        <Stack spacing={4} alignItems={'center'}>
          <Box
            sx={{
              textAlign: 'center',
              backgroundImage: 'none',
              display: 'inline-block',
              maxWidth: '540px',
            }}
          >
            <Typography variant="h6" sx={{ display: 'inline' }}>
              The tournament begins{' '}
            </Typography>
            <GradientTypography
              variant="h6"
              gradientColor="warning.vertical"
              sx={{ display: 'inline' }}
            >
              {startDateFormatted}
            </GradientTypography>
            <Typography variant="h6" sx={{ display: 'inline' }}>
              . In order to participate, you must agree to the rules below:
            </Typography>
          </Box>

          {rulesAndRegulations && (
            <NoticeParagraphed text={rulesAndRegulations} />
          )}
          {phase !== 'checkIn' && !skipCheckIn && (
            <AlertStandard
              severity="warning"
              textColor="warning.light"
              message={`DO NOT FORGET: The check-in window is ${checkInWindowFormatted}. If you do not check-in, you will NOT be able to participate in the tournament.`}
            />
          )}
        </Stack>
      }
    >
      <DialogActionsStandard buttons={buttons} />
    </DialogBodyStandard>
  );
};

export const RulesRegisterDialog = memo(RulesRegisterDialogUnmemoized);

import { memo } from '../../util/memo';
import { MatchAggregated } from 'functions/src/types/firestore/Game/Tournament/Bracket';
import { StatusIcon } from '../status/StatusIcon';
import { useTheme } from '@mui/material/styles';

export type CompetitorStatusIconProps = {
  status: MatchAggregated['status'];
};

export const CompetitorStatusIcon = memo(
  function CompetitorStatusIconUnmemoized({
    status,
  }: CompetitorStatusIconProps) {
    const theme = useTheme();
    const statusColor =
      status === 'active'
        ? theme.palette.secondary.main
        : theme.palette.warning.main;

    return <StatusIcon color={statusColor} />;
  },
);

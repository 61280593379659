import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useReferee } from 'src/contexts/RefereeContext';
import { useEffect } from 'react';
import { useTextInputDebounce } from '../../../../hooks/useTextInputDebounce';

export const SummaryKeyInput = () => {
  const { detailsOptions, setDetailsOptions } = useReferee();
  const [customSummaryKey, _, setCustomSummaryKey] = useTextInputDebounce('');
  useEffect(() => {
    if (setDetailsOptions && !!customSummaryKey) {
      setDetailsOptions((prev) => {
        return {
          ...prev,
          keySummary: customSummaryKey,
        };
      });
    }
  }, [customSummaryKey, setDetailsOptions]);
  return (
    <Stack direction="row" alignItems="center" gap={2}>
      <Typography variant="body1" textTransform="uppercase">
        Or enter a custom key:
      </Typography>
      <TextField
        size="small"
        variant="outlined"
        placeholder={detailsOptions?.keySummary}
        onChange={(e) => {
          return setCustomSummaryKey(e.target.value);
        }}
      />
    </Stack>
  );
};

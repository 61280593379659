import { memo } from '../../util/memo';
import { MemberListItem } from '../cards/friend/MemberListItem';
import { ChannelGroupUser } from '../../../functions/src/types/firestore/User/ChannelGroup';

export type MemberListProps = {
  members: ChannelGroupUser[];
  onRemove: (userId: string) => void;
};

const MemberListUnmemoized = ({ members, onRemove }: MemberListProps) => {
  return (
    <>
      {members
        .filter(({ username }) => {
          return !!username;
        })
        .map((member) => {
          return (
            <MemberListItem
              key={member.userId}
              onRemove={onRemove}
              {...member}
            />
          );
        })}
    </>
  );
};

export const MemberList = memo(MemberListUnmemoized);

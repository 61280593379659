import { memo } from '../../util/memo';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/material/styles';
import { useStatusColor } from '../../hooks/status/useStatusColor';

export type StatusIconProps = {
  color: string;
  size?: number;
  sx?: SxProps;
};

export const StatusIcon = memo(function StatusIconUnmemoized({
  color: colorOrStatus,
  size = 16,
  sx,
}: StatusIconProps) {
  const color = useStatusColor(colorOrStatus);

  return (
    <Box sx={{ lineHeight: 0, width: size, height: size, ...sx }}>
      <svg height={size} width={size} viewBox="0 0 100 100">
        <circle
          cx="50"
          cy="50"
          r="37"
          stroke="white"
          strokeWidth="13"
          fill={color}
        />
      </svg>
    </Box>
  );
});

import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { RefObject, useEffect, useState } from 'react';
import { memo } from '../../../../util/memo';
import { RegistrationOptionCustom } from 'functions/src/types/firestore/Game/Tournament';
import { LottieLoader } from 'src/components/LottieLoader';
import { FieldContainer } from '../FieldContainer';
import { useTextInputDebounce } from '../../../../hooks/useTextInputDebounce';

export type GameRegisterFieldProps = Pick<
  RegistrationOptionCustom,
  | 'optional'
  | 'preamble'
  | 'postambleText'
  | 'postambleLink'
  | 'formatExample'
  | 'name'
> & {
  inputRef: RefObject<HTMLInputElement>;
  error: boolean;
  validator: (field: string) => Promise<void>;
  prefillData?: string;
  isLoading?: boolean;
  errorMsg?: string;
};

const GameRegisterFieldUnmemoized = ({
  inputRef,
  error,
  validator,
  prefillData,
  optional,
  preamble,
  postambleText,
  postambleLink,
  formatExample,
  errorMsg,
  name,
  isLoading = false,
}: GameRegisterFieldProps) => {
  const [_, inputValueDebounced, setInputValue] = useTextInputDebounce(
    prefillData || '',
  );
  const [hasEdited, setHasEdited] = useState<boolean>(false);
  useEffect(() => {
    const validate = async () => {
      await validator(inputValueDebounced);
    };

    if (!isLoading && hasEdited) {
      validate();
    }
    // TODO:
    // whilst `validate` is wrapped in `useCallback` in the parent component,
    // the `validator` function that is passed to this changes on every render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValueDebounced]);
  return (
    <FieldContainer
      preamble={preamble}
      postambleLink={postambleLink}
      postambleText={postambleText}
    >
      <TextField
        defaultValue={prefillData}
        required={!optional}
        error={error}
        onChange={() => {
          if (inputRef.current !== null) {
            setInputValue(inputRef.current.value);
            setHasEdited(true);
          }
        }}
        placeholder={`Enter your ${name} here${optional ? ' (optional)' : ' '}`}
        variant="outlined"
        helperText={
          <Stack
            justifyContent="space-between"
            alignItems="center"
            direction="row"
            width="100%"
          >
            {formatExample && !errorMsg ? (
              <span>{`Example: ${formatExample}`}</span>
            ) : (
              <span>{errorMsg}</span>
            )}
            {isLoading && (
              <LottieLoader sx={{ height: '16px', width: '16px' }} />
            )}
          </Stack>
        }
        inputRef={inputRef}
        sx={{
          minWidth: '200px',
          width: '100%',
          my: 4,
          mx: 'auto',
          '& .MuiInputBase-root': {
            backgroundImage: '',
          },
        }}
      />
    </FieldContainer>
  );
};
export const GameRegisterField = memo(GameRegisterFieldUnmemoized);

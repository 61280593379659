import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ComponentProps, FC, useCallback, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { AlgoliaLayout } from '../../algolia/AlgoliaLayout';
import { FriendCarouselHeader } from './FriendCarouselHeader';
import {
  FriendVerticalCarousel,
  // useFriendVerticalCarouselAds,
} from '../../algolia/catalog-wrappers/FriendVerticalCarousel';
import { FriendCardEdit } from '../../cards/friend/FriendCardEdit';
import { NoContent } from '../../algolia/NoContent';
import { useAuth } from '../../../contexts/AuthContext';
import { useTheme } from '@mui/material/styles';
import { fillTemplate } from '../../../../functions/src/util/algoliaRealtime/fillTemplate';
import { REALTIME_CONFIGURATION_TEMPLATES } from '../../../../functions/src/util/algoliaRealtime/templates';
// import { useMobile } from '../../../hooks/useMobile';
// import { FRIENDS_EDIT_AD } from '../../../../functions/src/util/ads/adIds';
import {
  FRIEND_TABS_HEIGHT_DESKTOP,
  FRIEND_TABS_HEIGHT_MOBILE,
} from './FriendsAdd';
import { Omit } from 'utility-types';

export type FriendsEditProps = {
  onlineFriendsCount: number;
};

const FRIENDS_HITS_PER_PAGE = 50 as const;

const FriendsEditUnmemoized: FC<FriendsEditProps> = ({
  onlineFriendsCount,
}) => {
  const { uid } = useAuth();
  const theme = useTheme();

  const friendFilter = !!uid
    ? fillTemplate(REALTIME_CONFIGURATION_TEMPLATES['friend'], uid)
    : '';
  const friendConfigureOptions = useMemo(() => {
    return {
      hitsPerPage: FRIENDS_HITS_PER_PAGE,
      filters: friendFilter,
    };
  }, [friendFilter]);

  const FriendsEditCatalogWrapper = useCallback(
    (
      props: Omit<
        ComponentProps<typeof FriendVerticalCarousel>,
        'RenderFriendHit' | 'header' | 'NoFriends' | 'containerSx'
      >,
    ) => {
      return (
        <FriendVerticalCarousel
          RenderFriendHit={FriendCardEdit}
          {...props}
          header={
            <FriendCarouselHeader
              title="FRIENDS"
              onlineCount={onlineFriendsCount}
            />
          }
          noFriends={<NoContent variant="friends" isSelf={true} />}
          containerSx={{
            background: theme.palette.background.elevation[2],
          }}
        />
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onlineFriendsCount],
  );

  return (
    <Stack
      justifyContent="space-between"
      sx={{
        height: {
          xs: `calc(100% - ${FRIEND_TABS_HEIGHT_MOBILE}px)`,
          md: `calc(100% - ${FRIEND_TABS_HEIGHT_DESKTOP}px)`,
        },
        pt: 2,
      }}
    >
      <Stack
        spacing={6}
        justifyContent={'space-between'}
        sx={{
          px: 4,
          height: '100%',
        }}
      >
        <Box height="50%">
          <AlgoliaLayout
            CatalogWrapper={FriendsEditCatalogWrapper}
            configureOptions={friendConfigureOptions}
            index="CONTENT"
          />
        </Box>
      </Stack>
    </Stack>
  );
};
export const FriendsEdit = memo(FriendsEditUnmemoized);

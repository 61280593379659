import { Fragment, ReactNode } from 'react';
import { memo } from '../../util/memo';
import { ShareButtonInfo } from './ShareButtonInfo';

export type ShareButtonBaseProps = {
  children?: string | ReactNode;
  guide?: string;
};
export const ShareButtonBase = memo(function ShareButtonBaseUnmemoized({
  children = 'Share',
  guide,
}: ShareButtonBaseProps) {
  return (
    <Fragment>
      {typeof children === 'string' ? (
        <ShareButtonInfo tooltipTitle={guide}>{children}</ShareButtonInfo>
      ) : (
        children
      )}
    </Fragment>
  );
});

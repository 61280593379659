import { useCallback, useMemo } from 'react';
import { useRouterState } from '../routing/useRouterState';
import { ENTRIES_IDS_MATCH, EntryIdsMatch } from './useMatchDetailsWizard';
import {
  MATCH_DIALOG_KEY,
  MATCH_ENTRY_KEY,
  SESSION_DIALOG_KEY,
} from '../../util/tournaments/formScoringUrl';

export const useActiveMatchRouter = () => {
  const [matchId, setMatchId] = useRouterState({
    key: MATCH_DIALOG_KEY,
    location: 'queryParam',
    silent: true,
  });

  const [sessionIndexUnparsed, setSessionIndexUnparsed] = useRouterState({
    key: SESSION_DIALOG_KEY,
    location: 'queryParam',
    defaultValue: '0',
  });
  const [matchEntry, setMatchEntry] = useRouterState({
    key: MATCH_ENTRY_KEY,
    location: 'queryParam',
    defaultValue: 'details',
  });

  const validatedMatchEntry = useMemo(() => {
    return ENTRIES_IDS_MATCH.includes(matchEntry as EntryIdsMatch)
      ? (matchEntry as EntryIdsMatch)
      : 'details';
  }, [matchEntry]);

  const sessionIndex = useMemo(() => {
    return parseInt(sessionIndexUnparsed);
  }, [sessionIndexUnparsed]);

  const open = useCallback(
    // eslint-disable-next-line no-shadow
    (matchId: string, sessionIndex?: number, entry?: EntryIdsMatch) => {
      setMatchId(matchId);
      setSessionIndexUnparsed(sessionIndex?.toString());
      setMatchEntry(entry);
    },
    [setMatchId, setSessionIndexUnparsed, setMatchEntry],
  );

  const close = useCallback(() => {
    setMatchId(undefined);
    setSessionIndexUnparsed(undefined);
    setMatchEntry(undefined);
  }, [setMatchId, setSessionIndexUnparsed, setMatchEntry]);

  const switchSession = useCallback(
    // eslint-disable-next-line no-shadow
    (sessionIndex: number) => {
      setSessionIndexUnparsed(sessionIndex.toString());
    },
    [setSessionIndexUnparsed],
  );

  return {
    matchId,
    sessionIndex,
    open,
    close,
    switchSession,
    validatedMatchEntry,
  };
};

import Box from '@mui/material/Box';
import { memo } from '../../../util/memo';
import MuxPlayer from '@mux/mux-player-react/lazy';
import { MuxPlayerRefAttributes } from '@mux/mux-player-react/.';
import { MUX_VOD_STREAM } from '../../../../functions/src/util/mux/constants';
import { HoverProvider } from '../../../contexts/HoverContext';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { usePictureInPicture } from '../../../contexts/PictureInPictureContext';
import { useLivestream } from '../../../contexts/LivestreamContext';
import { ViewerCountDynamic } from './ViewerCountDynamic';
import { useAutoplayVideoElement } from '../../../hooks/useAutoplayVideoElement';
import { useLocalStorage } from '../../../contexts/LocalStorage';
import { useAuth } from '../../../contexts/AuthContext';
import { useConnectedRoom } from '../../../hooks/voice-chat/useConnectedRoom';
import { useChatContext as useStreamChatContext } from 'stream-chat-react';
import { useRoomContext as useLivekitRoom } from '@livekit/components-react';
import { useDetectPresence } from '../../../hooks/presence/useDetectPresence';
import { ViewerCountProvider } from './ViewerCountProvider';

export const LIVESTREAM_PLAYER_HEIGHT = 274 as const;
export const LIVESTREAM_PLAYER_HEIGHT_DESKTOP = 312 as const;
export const LIVESTREAM_PLAYER_HEIGHT_MOBILE = 232 as const;

const MUX_PLAYER_STYLE = { aspectRatio: 16 / 9 } as const;
const VOLUME_PREFERENCE = 'volumePreference' as const;

const LivestreamPlayerUnmemoized = () => {
  const { uidFull } = useAuth();
  const { getItem, setItem } = useLocalStorage();
  const { playbackId } = useLivestream();
  const { isConnectedRoom } = useConnectedRoom();
  const { isPictureInPicture, setIsPictureInPicture } = usePictureInPicture();
  const room = useLivekitRoom();
  const { channel } = useStreamChatContext();
  const [muxPlayer, setMuxPlayer] = useState<MuxPlayerRefAttributes | null>(
    null,
  );

  useDetectPresence<MuxPlayerRefAttributes>({
    elementId: playbackId,
    target: muxPlayer,
  });

  const videoElement = useMemo(() => {
    return muxPlayer
      ? muxPlayer.shadowRoot
          ?.querySelector('media-theme')
          ?.querySelector('mux-video')
          ?.shadowRoot?.querySelector('video')
      : null;
  }, [muxPlayer]);

  const enableSound = useCallback(() => {
    if (!videoElement) {
      return;
    }

    videoElement.muted = false;

    const volumePreference = getItem<number>(VOLUME_PREFERENCE);

    if (typeof volumePreference !== 'number') {
      return;
    }
    videoElement.volume = volumePreference;
  }, [getItem, videoElement]);

  const saveVolumePreference = useCallback(
    (event: Event) => {
      const { volume, muted } = event.target as HTMLVideoElement;
      const videoElementVolume = muted ? 0 : volume;
      return setItem('volumePreference', videoElementVolume);
    },
    [setItem],
  );

  useAutoplayVideoElement({ videoElement });

  useEffect(() => {
    if (!muxPlayer) {
      return;
    }

    const mediaControllerElement = muxPlayer.shadowRoot
      ?.querySelector('media-theme')
      ?.shadowRoot?.querySelector('media-controller');

    if (!mediaControllerElement) {
      return;
    }

    const observer = new MutationObserver(() => {
      setIsPictureInPicture(mediaControllerElement.hasAttribute('mediaispip'));
    });

    observer.observe(mediaControllerElement, {
      attributes: true,
      attributeFilter: ['mediaispip'],
    });
    return () => {
      observer.disconnect();
    };
  }, [muxPlayer, setIsPictureInPicture]);

  const isInStudio = useMemo(() => {
    if (!channel) {
      return;
    }
    return (
      isConnectedRoom('studio') && room.name.includes(channel.id as string)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnectedRoom, channel?.id, room?.name]);

  return (
    <HoverProvider>
      <ViewerCountProvider>
        <ViewerCountDynamic
          sx={{ position: 'absolute', top: 4, pl: 2, width: '100%' }}
        >
          <Box
            position="relative"
            sx={{ display: isPictureInPicture ? 'none' : 'block' }}
          >
            <MuxPlayer
              ref={setMuxPlayer}
              loading="viewport"
              playbackId={playbackId}
              streamType={MUX_VOD_STREAM}
              style={MUX_PLAYER_STYLE}
              onPlay={enableSound}
              muted={isInStudio}
              onVolumeChange={saveVolumePreference}
              metadata={{ viewer_user_id: uidFull }}
            />
          </Box>
        </ViewerCountDynamic>
      </ViewerCountProvider>
    </HoverProvider>
  );
};
export const LivestreamPlayer = memo(LivestreamPlayerUnmemoized);

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Team } from 'functions/src/types/firestore/Game/Tournament/Guestlist';
import { memo } from '../../../util/memo';
import { AvatarNext } from 'src/components/avatar/AvatarNext';
import { ChipUser } from 'src/components/ChipUser';

export type TeamDisplayProps = Pick<
  Team<Date>,
  'name' | 'avatarUrl' | 'members'
>;

const TeamDisplayUnmemoized = ({
  name,
  avatarUrl,
  members,
}: TeamDisplayProps) => {
  const theme = useTheme();

  return (
    <Stack justifyContent={'center'}>
      <Stack
        direction={'row'}
        spacing={2}
        alignItems={'center'}
        sx={{
          px: 3,
          py: 2,
          backgroundColor: theme.palette.background.elevation[17],
          borderRadius: '10px 10px 0 0',
        }}
      >
        <AvatarNext src={avatarUrl} />
        <Typography variant={'subtitle1'} color="text.primary">
          {name}
        </Typography>
      </Stack>

      <Box
        sx={{
          borderRadius: '0px 0px 10px 10px',
        }}
      >
        <Stack spacing={2} alignItems={'center'} sx={{ p: 2 }}>
          {members.map(({ username, imgUrl, userId }) => {
            return (
              <ChipUser
                key={username}
                username={username}
                avatarUrl={imgUrl}
                href={`/profile/${userId}`}
                sx={{
                  height: '35px',
                  width: '200px',
                  border: '0px',
                  px: 1,
                  justifyContent: 'flex-start',
                  '&:hover': {
                    background: theme.palette.background.elevation[24],
                  },
                  filter: 'none',
                  '.MuiChip-label': {
                    ml: '30px',
                    color: 'text.primary',
                  },
                }}
              />
            );
          })}
        </Stack>
      </Box>
    </Stack>
  );
};
export const TeamDisplay = memo(TeamDisplayUnmemoized);

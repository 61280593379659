export const findPreviousDisputeIn = (
  team1Scores?: number[],
  team2Scores?: number[],
  sessionIndex?: number,
) => {
  if (!sessionIndex) {
    return false;
  }
  const team1Previous = team1Scores?.[Number(sessionIndex) - 1];
  const team2Previous = team2Scores?.[Number(sessionIndex) - 1];
  return (
    team1Previous !== undefined &&
    team2Previous !== undefined &&
    team1Previous === team2Previous
  );
};

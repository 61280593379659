import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useSnackbarCountdown } from './useSnackbarCountdown';
import { useEffect, useCallback } from 'react';
import { useVoiceChannel } from './useVoiceChannel';
import { useActiveChannelGroup } from '../../contexts/ActiveChannelGroupContext';
import { LoadingButton } from '../../components/buttons/LoadingButton';
import { FieldValue } from 'firebase/firestore';

export const CHANNEL_MOVER_SNACKBAR_ID = 'channel-mover';
export const CHANNEL_MOVER_MESSAGE = '🚨 MOVING CHANNELS 🚨';
export const CHANNEL_MOVER_COUNTDOWN_MS = 10000;

export const useChannelMover = () => {
  const { voiceChannel, setVoiceChannel } = useVoiceChannel();
  const { openChannelGroup, channelId: activeChannelId } =
    useActiveChannelGroup();
  const { open, close } = useSnackbarCountdown(CHANNEL_MOVER_SNACKBAR_ID);

  const joinChannelWithVoice = useCallback(() => {
    const { next } = voiceChannel || {};

    if (!next || next instanceof FieldValue) {
      return;
    }

    const [channelGroupId, channelType, channelId] = (next as string).split(
      '-',
    );

    setVoiceChannel({ current: next });

    openChannelGroup(channelGroupId, `${channelType}:${channelId}`);

    close();
  }, [voiceChannel, setVoiceChannel, openChannelGroup, close]);

  const cancelMove = useCallback(async () => {
    const { resetNextVoiceChannel } = await import(
      '../../firebaseCloud/user/resetNextVoiceChannel'
    );
    const { deleteField } = await import('firebase/firestore');

    await resetNextVoiceChannel();
    setVoiceChannel({ next: deleteField() });
    close();
  }, [close, setVoiceChannel]);

  useEffect(() => {
    const handler = async () => {
      const { current, next } = voiceChannel || {};

      if (!next || (!!next && current === next)) {
        return;
      }
      const [_, channelType, channelId] = (next as string).split('-');

      if (`${channelType}:${channelId}` === activeChannelId) {
        return await cancelMove();
      }

      open({
        onComplete: joinChannelWithVoice,
        message: CHANNEL_MOVER_MESSAGE,
        countdownMs: CHANNEL_MOVER_COUNTDOWN_MS,
        action: (
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={joinChannelWithVoice}
            >
              Move
            </Button>
            <LoadingButton
              variant="contained"
              color="error"
              onClick={cancelMove}
            >
              Cancel
            </LoadingButton>
          </Stack>
        ),
      });
    };

    handler();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    close,
    joinChannelWithVoice,
    open,
    cancelMove,
    activeChannelId,
    voiceChannel?.next,
  ]);
};

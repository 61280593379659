/* eslint-disable @typescript-eslint/no-explicit-any */
import { Cache, CacheEvents } from '@algolia/cache-common';

export type LocalStorageCacheItem = {
  /**
   * The cache item creation timestamp.
   */
  readonly timestamp: number;

  /**
   * The cache item value
   */
  readonly value: any;
};

export type LocalStorage = Pick<Storage, 'setItem' | 'removeItem'> & {
  readonly getItem: (key: string) => string | null | undefined;
};

export type LocalStorageOptions = {
  /**
   * The cache key.
   */
  readonly key: string;

  /**
   * The time to live for each cached item in seconds.
   */
  readonly timeToLive?: number;

  /**
   * The native local storage implementation.
   */
  readonly localStorage?: LocalStorage;
};

export function createLocalStorageCache(options: LocalStorageOptions): Cache {
  let storage: LocalStorage;
  // We've changed the namespace to avoid conflicts with v4, as this version is a huge breaking change
  const namespaceKey = `algolia-client-js-${options.key}`;

  function getStorage(): LocalStorage {
    if (storage === undefined) {
      storage = options.localStorage || window.localStorage;
    }

    return storage;
  }

  function getNamespace<TValue>(): Record<string, TValue> {
    return JSON.parse(getStorage().getItem(namespaceKey) || '{}');
  }

  function setNamespace(namespace: Record<string, any>): void {
    getStorage().setItem(namespaceKey, JSON.stringify(namespace));
  }

  function removeOutdatedCacheItems(): void {
    const timeToLive = options.timeToLive ? options.timeToLive * 1000 : null;
    const namespace = getNamespace<LocalStorageCacheItem>();

    const filteredNamespaceWithoutOldFormattedCacheItems = Object.fromEntries(
      Object.entries(namespace).filter(([, cacheItem]) => {
        return cacheItem.timestamp !== undefined;
      }),
    );

    setNamespace(filteredNamespaceWithoutOldFormattedCacheItems);

    if (!timeToLive) {
      return;
    }

    const filteredNamespaceWithoutExpiredItems = Object.fromEntries(
      Object.entries(filteredNamespaceWithoutOldFormattedCacheItems).filter(
        ([, cacheItem]) => {
          const currentTimestamp = new Date().getTime();
          const isExpired = cacheItem.timestamp + timeToLive < currentTimestamp;

          return !isExpired;
        },
      ),
    );

    setNamespace(filteredNamespaceWithoutExpiredItems);
  }

  return {
    get<TValue>(
      key: Record<string, any> | string,
      defaultValue: () => Promise<TValue>,
      events: CacheEvents<TValue> = {
        miss: () => {
          return Promise.resolve();
        },
      },
    ): Promise<TValue> {
      return Promise.resolve()
        .then(() => {
          removeOutdatedCacheItems();

          return getNamespace<Promise<LocalStorageCacheItem>>()[
            JSON.stringify(key)
          ];
        })
        .then((value) => {
          return Promise.all([
            value ? value.value : defaultValue(),
            value !== undefined,
          ]);
        })
        .then(([value, exists]) => {
          return Promise.all([value, exists || events.miss(value)]);
        })
        .then(([value]) => {
          return value;
        });
    },

    set<TValue>(
      key: Record<string, any> | string,
      value: TValue,
    ): Promise<TValue> {
      return Promise.resolve().then(() => {
        const namespace = getNamespace();

        namespace[JSON.stringify(key)] = {
          timestamp: new Date().getTime(),
          value,
        };

        getStorage().setItem(namespaceKey, JSON.stringify(namespace));

        return value;
      });
    },

    delete(key: Record<string, any> | string): Promise<void> {
      return Promise.resolve().then(() => {
        const namespace = getNamespace();

        delete namespace[JSON.stringify(key)];

        getStorage().setItem(namespaceKey, JSON.stringify(namespace));
      });
    },

    clear(): Promise<void> {
      return Promise.resolve().then(() => {
        getStorage().removeItem(namespaceKey);
      });
    },
  };
}

import { memo } from '../../util/memo';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { useStatusColor } from '../../hooks/status/useStatusColor';
import type { Required } from 'utility-types';

export type StatusBadgeProps = Required<BadgeProps, 'color'>;

export const StatusBadge = memo(function StatusBadgeUnmemoized({
  color: colorOrStatus,
  sx,
  ...badgeProps
}: StatusBadgeProps) {
  const color = useStatusColor(colorOrStatus);

  return (
    <Badge
      overlap="circular"
      variant="dot"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      {...badgeProps}
      sx={{
        ...sx,
      }}
      color={color}
    />
  );
});

import ButtonBase from '@mui/material/ButtonBase';
import { AvatarNext } from '../avatar/AvatarNext';
import { useAuth } from '../../contexts/AuthContext';
import { GradientIcon } from '../gradients/GradientIcon';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownRounded';
import { SignInMenu } from './SignInMenu';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';

export const SignInDropdown = () => {
  const theme = useTheme();

  const { userData } = useAuth();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = useCallback(() => {
    setMenuOpen((prev) => {
      return !prev;
    });
  }, []);

  const closeMenu = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const signInMenu = useMemo(() => {
    return (
      menuOpen &&
      menuRef?.current && (
        <SignInMenu
          open={menuOpen}
          anchorEl={menuRef.current}
          onMenuClose={closeMenu}
        />
      )
    );
  }, [closeMenu, menuOpen]);

  return (
    <>
      <ButtonBase
        ref={menuRef}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '25px',
          ...theme.glow.selectable,
        }}
        onClick={toggleMenu}
      >
        <AvatarNext
          src={userData?.imgUrl}
          height={42}
          width={42}
          sx={{ '&:hover': { cursor: 'pointer' } }}
        />
        <GradientIcon IconComponent={ArrowDropDownIcon} />
      </ButtonBase>
      {signInMenu}
    </>
  );
};

import Stack from '@mui/material/Stack';
import { Header, MIN_HEADER_HEIGHT } from './Header';
import { useTheme } from '@mui/material/styles';
import { memo } from '../../util/memo';
import {
  APP_NAVIGATION_TAB_HEIGHT_MOBILE,
  AppNavigationTabs,
} from '../AppNavigationTabs';

const HeaderTabbedUnmemoized = () => {
  const theme = useTheme();

  return (
    <Stack
      sx={{
        flexDirection: { xs: 'column', md: 'row' },
        zIndex: theme.zIndex.appBar,
        height: {
          xs: `${APP_NAVIGATION_TAB_HEIGHT_MOBILE + MIN_HEADER_HEIGHT}px`,
          md: `${MIN_HEADER_HEIGHT}px`,
        },
        width: '100%',
        position: 'fixed',
        background: theme.palette.background.elevationSolid['0'],
        boxShadow: { xs: theme.shadows[4], md: 'none' },
      }}
    >
      <Header />
      <AppNavigationTabs />
    </Stack>
  );
};

export const HeaderTabbed = memo(HeaderTabbedUnmemoized);

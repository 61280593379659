import { ReactNode } from 'react';
import { memo } from '../../util/memo';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

export type TournamentPayoutsProps = {
  Header: ReactNode;
  Summary?: ReactNode;
  PayoutRows: ReactNode;
  showAltContent?: boolean;
  AltContent?: ReactNode;
};

export const TournamentPayoutsLayout = memo(
  function TournamentPayoutsLayoutUnmemoized({
    Header,
    Summary,
    PayoutRows,
    showAltContent = false,
    AltContent,
  }: TournamentPayoutsProps) {
    const theme = useTheme();
    return (
      <Stack
        sx={{
          ...theme.panels[1],
          'overflow-y': showAltContent ? 'hidden' : 'scroll',
          ...theme.scrollbars.invisible,
        }}
        width="100%"
        maxHeight="500px"
      >
        {Header}
        {showAltContent && AltContent ? (
          AltContent
        ) : (
          <Stack sx={{ ...theme.panels[0], overflowY: 'scroll' }} gap={8}>
            {Summary}
            {PayoutRows}
          </Stack>
        )}
      </Stack>
    );
  },
);

import { memo } from '../../util/memo';
import { GradientIconButton } from '../gradients/GradientIconButton';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import { RemoteParticipant } from 'livekit-client';
import { useCallback, useState } from 'react';
import { SxProps } from '@mui/material/styles';

export type MuteCallerButtonProps = {
  caller: RemoteParticipant;
  sx?: SxProps;
};

const MuteCallerButtonUnmemoized = ({
  caller,
  sx = {},
}: MuteCallerButtonProps) => {
  const [isMuted, setIsMuted] = useState(caller.getVolume() === 0);

  const mute = useCallback(() => {
    caller.setVolume(0);
    return setIsMuted(true);
  }, [caller]);

  const unmute = useCallback(() => {
    caller.setVolume(1);
    return setIsMuted(false);
  }, [caller]);

  return (
    <GradientIconButton
      IconComponent={isMuted ? MicOffIcon : MicIcon}
      onClick={isMuted ? unmute : mute}
      sx={{ p: '0px !important', ...sx }}
    />
  );
};

export const MuteCallerButton = memo(MuteCallerButtonUnmemoized);

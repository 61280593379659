import { memo } from '../../../util/memo';
import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import { GradientIconButton } from '../../gradients/GradientIconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOffRounded';
import { ChipUser } from '../../ChipUser';
import { useTheme } from '@mui/material/styles';
import { ChannelGroupUser } from '../../../../functions/src/types/firestore/User/ChannelGroup';
import { useCallback, useMemo } from 'react';
import { Required } from 'utility-types';

export type MemberListItemProps = Required<ChannelGroupUser, 'username'> & {
  onRemove: (userId: string) => void;
};

const MemberListItemUnmemoized = ({
  image,
  username,
  userId,
  onRemove,
  isAdmin = false,
}: MemberListItemProps) => {
  const theme = useTheme();

  const remove = useCallback(() => {
    if (!userId) {
      return;
    }
    onRemove(userId);
  }, [userId, onRemove]);

  const endComponent = useMemo(() => {
    return isAdmin ? (
      <Typography
        color="text.secondary"
        variant="subtitle2"
        sx={{ fontStyle: 'italic', pr: 2 }}
      >
        Admin
      </Typography>
    ) : (
      <GradientIconButton IconComponent={HighlightOffIcon} onClick={remove} />
    );
  }, [isAdmin, remove]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      p={2}
      mb={3}
      sx={{
        background: theme.palette.background.elevationSolid[8],
        borderRadius: '10px',
        height: '52px',
        width: '100%',
      }}
    >
      <ChipUser
        username={username}
        avatarUrl={image}
        href={`/profile/${userId}`}
      />
      <Stack direction="row" spacing={1}>
        {endComponent}
      </Stack>
    </Stack>
  );
};

export const MemberListItem = memo(MemberListItemUnmemoized);

import { memo } from '../../util/memo';
import Typography, { TypographyProps } from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

export type GradientTypographyProps = TypographyProps & {
  gradientColor?: string;
  component?: React.ElementType;
};

export const GradientTypography = memo(function GradientTypographyUnmemoized(
  props: GradientTypographyProps,
) {
  const { gradientColor = 'primary.vertical', ...rest } = props;

  const theme = useTheme();

  const sections = gradientColor.split('.');
  const background: string = sections.reduce((prev, section) => {
    return prev[`${section}`];
  }, theme.palette) as unknown as string;
  // const shadow = ['primary', 'secondary', 'warning', 'error', 'info'].includes(
  //   sections[0],
  // )
  //   ? `drop-shadow(${theme.shadowsHard[`${sections[0]}Elevation1`]})`
  //   : undefined;
  return (
    <Typography
      {...rest}
      sx={{
        ...props.sx,
        background,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundClip: 'text',
        // filter: shadow,
      }}
    >
      {props.children}
    </Typography>
  );
});

import { Range } from '../../functions/src/types/utility-types';

type ElevationSettings = {
  baseHue: number;
  baseSaturation: number;
  baseLightness: number;
  alpha?: number;
  lightnessIncrement?: number;
  saturationIncrement?: number;
};

const generateHsla = (
  hue: number,
  saturation: number,
  lightness: number,
  alpha: number,
) => {
  return `hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`;
};

export type ElevationLevel = `${Range<0, 31>}`;
export type ElevationColors = Record<ElevationLevel, string>;
const generateElevationColors = ({
  baseHue,
  baseSaturation,
  baseLightness,
  alpha = 1,
  lightnessIncrement = 0.8,
  saturationIncrement = 0.1,
}: ElevationSettings) => {
  const colors = {} as ElevationColors;

  colors['0'] = generateHsla(baseHue, baseSaturation, baseLightness, alpha);

  Array.from({ length: 30 }, (_, i) => {
    return i + 1;
  }).forEach((level) => {
    const lightness = baseLightness + level * lightnessIncrement;
    const saturation = baseSaturation + level * saturationIncrement;
    colors[level.toString()] = generateHsla(
      baseHue,
      saturation,
      lightness,
      alpha,
    );
  });
  return colors;
};

/**
 * @remarks
 * If you make any changes here, please also update the background color in global.css.
 */

/**
 * This controls the base color. Should not be tweaked.
 */
export const HUE_BASE = 215;
/**
 * THis controls how "blue" the app feels. The higher the number, the bluer.
 */
export const SATURATION_BASE = 50;
/**
 * This controls how dark the app starts out at in its darkest places. The lower the number, the darker.
 */
export const LIGHTNESS_BASE = 6;

/**
 * This controls how "flat" the app feels. The lower the number, the flatter.
 */
export const LIGHTNESS_INCREMENT = 0.7;

export const ALPHA = 0.8;

export const ELEVATIONS = generateElevationColors({
  baseHue: HUE_BASE,
  baseSaturation: SATURATION_BASE,
  baseLightness: LIGHTNESS_BASE,
  alpha: ALPHA,
  lightnessIncrement: LIGHTNESS_INCREMENT,
});
export const ELEVATIONS_SOLID = generateElevationColors({
  baseHue: HUE_BASE,
  baseSaturation: SATURATION_BASE,
  baseLightness: LIGHTNESS_BASE,
  alpha: 1,
  lightnessIncrement: LIGHTNESS_INCREMENT,
});

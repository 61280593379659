import { memo } from '../../util/memo';
import Slide, { SlideProps } from '@mui/material/Slide';

export const SlideTransitionDown = memo(function SlideTransitionDownUnmemoized(
  props: SlideProps,
) {
  return <Slide {...props} direction="down" />;
});

export const SlideTransitionUp = memo(function SlideTransitionUpUnmemoized(
  props: SlideProps,
) {
  return <Slide {...props} direction="up" />;
});

export const SlideTransitionLeft = memo(function SlideTransitionLeftUnmemoized(
  props: SlideProps,
) {
  return <Slide {...props} direction="left" />;
});

export const SlideTransitionRight = memo(
  function SlideTransitionRightUnmemoized(props: SlideProps) {
    return <Slide {...props} direction="right" />;
  },
);

import Box from '@mui/material/Box';
import { LottieSpeech } from './LottieSpeech';
import MicOffIcon from '@mui/icons-material/MicOff';
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import { memo } from '../../util/memo';
import { RemoteParticipant, LocalParticipant } from 'livekit-client';
import { type TrackReference } from '@livekit/components-core';
import { useIsMuted, useIsSpeaking } from '@livekit/components-react';

export type SpeakerIconProps = {
  audioTrack: TrackReference;
  caller: RemoteParticipant | LocalParticipant;
  width?: number;
  height?: number;
};

const SpeakerIconUnmemoized = ({
  audioTrack,
  caller,
  width = 22,
  height = 22,
}: SpeakerIconProps) => {
  const theme = useTheme();
  const hasDisabledMic = useIsMuted(audioTrack);
  const isSpeaking = useIsSpeaking(caller);

  const isSpeakingNotMuted = useMemo(() => {
    const speakerVolume =
      caller instanceof RemoteParticipant && caller.getVolume();
    return (
      isSpeaking &&
      (caller instanceof LocalParticipant ||
        (!!speakerVolume && speakerVolume > 0))
    );
  }, [isSpeaking, caller]);

  const speakerIcon = useMemo(() => {
    return isSpeakingNotMuted ? (
      <LottieSpeech sx={{ height: height + 2, width: height + 2, ml: -1 }} />
    ) : hasDisabledMic ? (
      <MicOffIcon
        sx={{
          height: height - 4,
          width: width - 4,
          mb: '-2px',
          color: theme.palette.error.main,
        }}
      />
    ) : (
      <></>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDisabledMic, height, isSpeakingNotMuted, width]);

  return (
    <Box
      sx={{
        height,
        width,
      }}
    >
      {speakerIcon}
    </Box>
  );
};

export const SpeakerIcon = memo(SpeakerIconUnmemoized);

export type HSL = {
  hue: number;
  saturation: number;
  lightness: number;
};

export type ColorOptions = {
  lightHue: number;
  darkHue?: number;
  lightness?: number;
};

export type ColorPalette = {
  main: string;
  light: string;
  dark: string;
  mid: string;
  horizontal: string;
  horizontalLight: string;
  horizontalInverse: string;
  horizontalInverseLight: string;
  vertical: string;
  verticalLight: string;
  verticalInverse: string;
  verticalInverseLight: string;
  contrastText: string;
};

export const DEFAULT_LIGHTNESS = 65;
export const DEFAULT_SATURATION = 100;
export const DEFAULT_HUE_INCREMENT = -15;
export const DEFAULT_LIGHTNESS_DECREMENT = 20;

export const DEFAULT_LIGHT_INCREMENT = 5;

export class ColorSpreader {
  private light: HSL;
  private dark: HSL;
  private mid: HSL;
  private readonly lighterIncrement = DEFAULT_LIGHT_INCREMENT;
  constructor(options: ColorOptions) {
    const baseLightness = options.lightness ?? DEFAULT_LIGHTNESS;

    this.light = {
      hue: options.lightHue,
      saturation: DEFAULT_SATURATION,
      lightness: baseLightness,
    };

    this.dark = {
      hue: options.darkHue ?? options.lightHue + DEFAULT_HUE_INCREMENT,
      saturation: DEFAULT_SATURATION,
      lightness: baseLightness - DEFAULT_LIGHTNESS_DECREMENT,
    };

    this.mid = {
      hue: (this.light.hue + this.dark.hue) / 2,
      saturation: DEFAULT_SATURATION,
      lightness: (this.light.lightness + this.dark.lightness) / 2,
    };
  }

  public static apply(options: ColorOptions): ColorPalette {
    return new ColorSpreader(options).spread();
  }

  spread(): ColorPalette {
    const lightColor = ColorSpreader.asCssHsl(this.light);
    const midColor = ColorSpreader.asCssHsl(this.mid);
    const darkColor = ColorSpreader.asCssHsl(this.dark);

    const lightColorLighter = this.lighten(this.light);
    const midColorLighter = this.lighten(this.mid);

    return {
      main: lightColor,
      light: lightColor,
      dark: darkColor,
      mid: midColor,
      horizontal: ColorSpreader.asCssGradient(lightColor, midColor, 90),
      horizontalLight: ColorSpreader.asCssGradient(
        lightColorLighter,
        midColorLighter,
        90,
      ),
      horizontalInverse: ColorSpreader.asCssGradient(lightColor, midColor, 270),
      horizontalInverseLight: ColorSpreader.asCssGradient(
        lightColorLighter,
        midColorLighter,
        270,
      ),
      vertical: ColorSpreader.asCssGradient(midColor, lightColor, 0),
      verticalLight: ColorSpreader.asCssGradient(
        midColorLighter,
        lightColorLighter,
        0,
      ),
      verticalInverse: ColorSpreader.asCssGradient(midColor, lightColor, 180),
      verticalInverseLight: ColorSpreader.asCssGradient(
        midColorLighter,
        lightColorLighter,
        180,
      ),
      contrastText: '#fff',
    };
  }

  static asCssHsl(hsl: HSL): string {
    return `hsl(${hsl.hue}, ${hsl.saturation}%, ${hsl.lightness}%)`;
  }

  private lighten(hsl: HSL): string {
    return ColorSpreader.asCssHsl({
      ...hsl,
      lightness: hsl.lightness + this.lighterIncrement,
    });
  }

  private static asCssGradient(
    startColor: string,
    endColor: string,
    direction: number,
  ): string {
    return `linear-gradient(${direction}deg, ${startColor} 0%, ${endColor} 100%)`;
  }
}

import { useCallback, useState } from 'react';
import { memo } from '../../../util/memo';
import { TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';

export type EditLeaderboardCellProps = GridRenderEditCellParams & {
  scores: number[];
  valueIndex: number;
};

export const EditLeaderboardCell = memo(function EditLeaderboardCellUnmemoized({
  id,
  value,
  field,
  scores,
  valueIndex,
}: EditLeaderboardCellProps) {
  const apiRef = useGridApiContext();
  const theme = useTheme();

  const [valueFinal, setValueFinal] = useState(value);

  const changeValue = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      setValueFinal(newValue);
      const newValueNumber = parseInt(newValue);
      const newScoresArray = scores.map((item, index) => {
        return index === valueIndex ? newValueNumber : item;
      });
      await apiRef.current.setEditCellValue({
        id,
        field,
        value: [...newScoresArray],
        debounceMs: 1,
      });
    },

    [apiRef, field, id, scores, valueIndex],
  );

  return (
    <TextField
      type="text"
      variant="standard"
      value={valueFinal}
      onChange={changeValue}
      sx={{
        height: '100%',
        '.MuiInputBase-root': {
          backgroundColor: `${theme.palette.background.elevation['1']}`,
          boxShadow: 'none',
          outline: 'none',
          height: '100%',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: `${theme.palette.border} !important`,
          borderWidth: 0,
        },
        ' & .MuiInput-underline:hover:before': {
          border: 'none !important',
        },
      }}
    />
  );
});

import type { Timestamp } from 'firebase-admin/firestore';

export const STATUSES = [
  'online',
  'offline',
  'away',
  'do not disturb',
] as const;
export type StatusEnum = (typeof STATUSES)[number];

export type OnlineStatus<TTime = Timestamp> = {
  status: StatusEnum;
  lastChanged: TTime;
};

export type StatusMap = Record<string, OnlineStatus>;

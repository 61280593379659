import {
  createContext,
  ReactNode,
  useContext,
  MutableRefObject,
  useMemo,
} from 'react';
import { memo } from '../util/memo';
import {
  ChannelGroup,
  GroupFilterMap,
  GroupFilter,
} from '../../functions/src/types/firestore/User/ChannelGroup';
import { useAuth } from './AuthContext';
import { CollectionReference } from 'firebase/firestore';
import { useRealtimeInfiniteScroll } from '../hooks/infinite-scroll/useRealtimeInfiniteScroll';
import { ConverterFactory } from '../../functions/src/util/firestore/ConverterFactory';
import { Identifiable } from '../../functions/src/types/Identifiable';
import { useFilterTemporaryChannelGroups } from '../hooks/messaging/useFilterTemporaryChannelGroups';

export const DEFAULT_PAGE_SIZE = 20;

export type IdentifiableGroupFilter = Identifiable & {
  groupFilter: GroupFilter;
};

export type RealtimeChannelGroupsContextProps = {
  realtimeChannelGroups: ChannelGroup<keyof GroupFilterMap, Date>[];
  containerRef: MutableRefObject<HTMLDivElement | null>;
};

export const RealtimeChannelGroupsContext = createContext<
  RealtimeChannelGroupsContextProps | undefined
>(undefined);

export type RealtimeChannelGroupsProps = {
  children: ReactNode;
};

export const useRealtimeChannelGroups = () => {
  const context = useContext(RealtimeChannelGroupsContext);
  if (!context) {
    throw new Error(
      'useRealtimeChannelGroups must be used within a RealtimeChannelGroupsProvider',
    );
  }
  return context;
};

const RealtimeChannelGroupsProviderUnmemoized = ({
  children,
}: RealtimeChannelGroupsProps) => {
  const { uidFull } = useAuth();
  const { filterTemporaryChannelGroups } = useFilterTemporaryChannelGroups();

  const queryPromise = useMemo(async () => {
    if (!uidFull) {
      return;
    }
    const { collection } = await import('firebase/firestore');
    const { firestore } = await import('../config/firebase-client/firestore');
    return (
      collection(
        firestore,
        `User/${uidFull}/ChannelGroup`,
      ) as CollectionReference<ChannelGroup>
    ).withConverter<ChannelGroup<keyof GroupFilterMap, Date>>(
      ConverterFactory.buildDateConverter(),
    );
  }, [uidFull]);

  const { containerRef, documents } = useRealtimeInfiniteScroll<
    ChannelGroup<keyof GroupFilterMap, Date>
  >({
    query: queryPromise,
    pageSize: DEFAULT_PAGE_SIZE,
    timeKey: 'lastUpdated',
  });

  const realtimeChannelGroups = useMemo(() => {
    return filterTemporaryChannelGroups(documents);
  }, [documents, filterTemporaryChannelGroups]);

  const memoizedValue = useMemo(() => {
    return {
      realtimeChannelGroups,
      containerRef,
    };
  }, [containerRef, realtimeChannelGroups]);

  return (
    <RealtimeChannelGroupsContext.Provider value={memoizedValue}>
      {children}
    </RealtimeChannelGroupsContext.Provider>
  );
};

export const RealtimeChannelGroupsProvider = memo(
  RealtimeChannelGroupsProviderUnmemoized,
);

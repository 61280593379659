import {
  useState,
  useRef,
  FC,
  ReactNode,
  useEffect,
  useMemo,
  ChangeEvent,
  useCallback,
} from 'react';
import { memo } from '../util/memo';
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
import { GradientIconButton } from './gradients/GradientIconButton';
import { GradientIcon } from './gradients/GradientIcon';
import SearchIcon from '@mui/icons-material/SearchRounded';
import { useClickAwayListener } from '../hooks/useClickAwayListener';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';

export type TextFieldDropdownProps = Omit<TextFieldProps, 'variant'> & {
  PopoverContent: ReactNode;
};

const TextFieldDropdownUnmemoized: FC<TextFieldDropdownProps> = ({
  PopoverContent,
  value,
  ...textFieldProps
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [popoverWidth, setPopoverWidth] = useState<number | undefined>();
  const textFieldRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);
  const [textFieldValue, setTextFieldValue] = useState<unknown>('');
  const textFieldInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (textFieldRef.current) {
      setPopoverWidth(textFieldRef.current.clientWidth);
    }
  }, [textFieldRef]);

  useEffect(() => {
    setTextFieldValue(value || '');
    if (!!value) {
      textFieldInputRef.current?.blur();
      setAnchorEl(null);
    }
  }, [value]);

  const onClickAway = () => {
    setAnchorEl(null);
  };

  useClickAwayListener({
    containerRefs: [textFieldRef, popoverRef],
    onClickAway,
  });

  const togglePopover = useCallback(() => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(textFieldRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!anchorEl]);

  const ArrowIcon = useMemo(() => {
    return anchorEl ? ArrowDropUp : ArrowDropDown;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!anchorEl]);

  const clearText = () => {
    textFieldProps.onChange?.({ target: { value: '' } } as ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement
    >);
    setTextFieldValue('');
  };

  const MemoizedPopover = useMemo(() => {
    if (!anchorEl) {
      return undefined;
    }
    return (
      <Popover
        open={Boolean(anchorEl)}
        disableAutoFocus
        disableEnforceFocus
        sx={{
          maxHeight: '310px',
        }}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box>{PopoverContent}</Box>
      </Popover>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PopoverContent, anchorEl, open, popoverWidth]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      ref={textFieldRef}
      width="100%"
      onSubmit={(e) => {
        return e.preventDefault();
      }}
    >
      <TextField
        {...textFieldProps}
        value={textFieldValue}
        onChange={(e) => {
          if (!anchorEl) {
            setAnchorEl(textFieldRef.current);
          }
          textFieldProps.onChange?.(e);
          setTextFieldValue(e.target.value);
        }}
        sx={{
          width: '100%',
        }}
        variant="outlined"
        inputRef={textFieldInputRef}
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <GradientIcon IconComponent={SearchIcon} />
            </InputAdornment>
          ),
          endAdornment: (
            <>
              {textFieldValue && (
                <GradientIconButton
                  onClick={clearText}
                  IconComponent={CloseIcon}
                />
              )}
              <GradientIconButton
                onClick={togglePopover}
                IconComponent={ArrowIcon}
              />
            </>
          ),
        }}
      />
      {MemoizedPopover}
    </Stack>
  );
};

export const TextFieldDropdown = memo(TextFieldDropdownUnmemoized);

import { useCallback } from 'react';
import type { Attachment as StreamAttachment } from 'stream-chat';
import {
  useChannelActionContext as useStreamChannelActionContext,
  useChannelStateContext as useStreamChannelStateContext,
  type MessageToSend,
  DefaultStreamChatGenerics,
  LocalAttachmentUploadMetadata,
} from 'stream-chat-react';
import { useMessageDraft } from './useMessageDraft';
import {
  MessageReply,
  useReply,
} from '../../components/messaging/ReplyContext';
import { MESSAGE_ATTACHMENT_SUPPORTED_FORMATS } from '../../../functions/src/util/edit/mimeConstants';

export const ONE_MEGABYTE = 1024 * 1024;
export const UPLOAD_SIZE_LIMIT = 100;

export type UseProcessMessageParams = {
  replyingTo?: MessageReply;
};

export const useProcessMessage = ({ replyingTo }: UseProcessMessageParams) => {
  const { sendMessage } = useStreamChannelActionContext();
  const { channel } = useStreamChannelStateContext();
  const { addNotification } = useStreamChannelActionContext();
  const { deleteDraft } = useMessageDraft();
  const { setReplyingTo } = useReply();

  const processMessage = useCallback(
    (message: MessageToSend, isReply: boolean) => {
      const { text, attachments, ...messageRest } = message;
      const attachmentsImage: StreamAttachment<DefaultStreamChatGenerics>[] =
        [];
      const attachmentsOther: StreamAttachment<DefaultStreamChatGenerics>[] =
        [];
      const messageMetadata = isReply
        ? { quoted_message_id: replyingTo!.id }
        : undefined;

      attachments?.forEach((attachment) => {
        if (attachment.type === 'image') {
          attachmentsImage.push(attachment);
        } else {
          attachmentsOther.push(attachment);
        }
      });
      if (!!attachmentsOther.length) {
        sendMessage(
          {
            attachments: attachmentsOther,
            ...messageRest,
          },
          { ...messageMetadata },
        );
      }

      sendMessage(
        {
          text,
          attachments: attachmentsImage,
          ...messageRest,
        },
        { ...messageMetadata },
      );

      if (isReply) {
        setReplyingTo(undefined);
      }
    },
    [replyingTo, sendMessage, setReplyingTo],
  );

  const processFile = useCallback(
    (file: LocalAttachmentUploadMetadata['file']) => {
      if (!file) {
        return;
      }

      const { size, type, name, lastModified } = file;

      if (!!type && !MESSAGE_ATTACHMENT_SUPPORTED_FORMATS.includes(type)) {
        return addNotification(`File type ${type} is not supported.`, 'error');
      }
      if (!!size && size / ONE_MEGABYTE > UPLOAD_SIZE_LIMIT) {
        return addNotification('File size exceeds 100MB.', 'error');
      }
      const newFile = new File([file as unknown as Blob], name, {
        type,
        lastModified,
      });
      return newFile;
    },
    [addNotification],
  );

  const sendMessageOverride = useCallback(
    (message: MessageToSend) => {
      if (!!replyingTo || !!message.attachments) {
        processMessage(message, !!replyingTo);
      }
      deleteDraft(message, channel.cid);
    },
    [channel.cid, deleteDraft, processMessage, replyingTo],
  );

  return { processFile, sendMessageOverride };
};

export const SCROLLBARS = {
  primary: {
    '::-webkit-scrollbar': {
      width: '16px',
    },
    '::-webkit-scrollbar-track': {
      background: '#19293e',
      borderRadius: '10px',
      // This border + background-clip achieves the effect of the track being thinner than the thumb
      backgroundClip: 'padding-box',
      border: '6px solid transparent',
    },
    '::-webkit-scrollbar-thumb': {
      background: 'linear-gradient(180deg, #4DC8FF 0%, #0064E9 100%)',
      backgroundClip: 'padding-box',
      border: '4px solid transparent',
      borderRadius: '10px',
    },
    '::-webkit-scrollbar-track-piece:end': {
      background: 'transparent',
      marginBottom: '4px',
    },
    '::-webkit-scrollbar-track-piece:start': {
      background: 'transparent',
      marginTop: '4px',
    },
    '::-webkit-scrollbar-corner': {
      background: 'transparent',
    },
  },
  invisible: {
    '::-webkit-scrollbar': {
      display: 'none',
    },
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
  },
} as const;

export type Scrollbars = typeof SCROLLBARS;

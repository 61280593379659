import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import ReplyIcon from '@mui/icons-material/ReplyRounded';
import ReplayIcon from '@mui/icons-material/ReplayRounded';
import MoreHorizIcon from '@mui/icons-material/MoreHorizRounded';
import { useRef, useState, useEffect, RefObject, useCallback } from 'react';
import { memo } from '../../../util/memo';
import { useReply } from '../ReplyContext';
import { useMessage } from './MessageContext';
import { useHover } from '../../../contexts/HoverContext';
import {
  useRetryHandler as useStreamRetryHandler,
  useChannelStateContext as useStreamChannelStateContext,
} from 'stream-chat-react';
import { useTheme } from '@mui/material/styles';
import { QuickReactions } from './QuickReactions';
import AddReactionOutlinedIcon from '@mui/icons-material/AddReactionOutlined';
import Popover from '@mui/material/Popover';
import Picker from '@emoji-mart/react';
import { LottieLoader } from '../../LottieLoader';
import { useClickAwayListener } from '../../../hooks/useClickAwayListener';

export type MessageInteractionsProps = {
  anchorRef: RefObject<SVGSVGElement | null>;
};

const MessageInteractionsUnmemoized = ({
  anchorRef,
}: MessageInteractionsProps) => {
  const retrySend = useStreamRetryHandler();
  const { setReplyingTo } = useReply();
  const { message, isMine, openMenu } = useMessage();
  const { id: messageId, user: messageUser, status, own_reactions } = message;
  const { channel } = useStreamChannelStateContext();
  const { isHovered } = useHover();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<SVGSVGElement | null>(null);
  const [emojiData, setEmojiData] = useState(null);
  const emojiPickerRef = useRef<HTMLDivElement | null>(null);

  useClickAwayListener({
    containerRefs: [emojiPickerRef],
    onClickAway: () => {
      setAnchorEl(null);
    },
  });

  useEffect(() => {
    const fetchEmojiData = async () => {
      const response = await fetch(
        'https://cdn.jsdelivr.net/npm/@emoji-mart/data',
      );
      const data = await response.json();
      setEmojiData(data);
    };

    fetchEmojiData();
  }, []);

  const interactionButtonSx = {
    p: 1,
    color: theme.palette.primary.mid,
    height: '28px',
    width: '28px',
    borderRadius: isMine ? '50%' : undefined,
    ':hover': {
      background: theme.palette.action.hover,
      cursor: 'pointer',
    },
  };

  const reply = useCallback(() => {
    return setReplyingTo({
      id: messageId,
      username: messageUser?.name,
    });
  }, [messageId, messageUser?.name, setReplyingTo]);

  const resendMessage = useCallback(() => {
    retrySend(message);
  }, [message, retrySend]);

  const openMenuOptions = useCallback(
    (event: React.MouseEvent<SVGSVGElement>) => {
      openMenu(event);
    },
    [openMenu],
  );

  const openEmojiPicker = useCallback(() => {
    return setAnchorEl(anchorRef.current);
  }, [anchorRef]);

  const toggleReaction = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (props: any) => {
      const { id: type } = props;
      const hasReacted =
        !!own_reactions?.length &&
        !!own_reactions.some((reactionOwn) => {
          return reactionOwn.type === type;
        });
      if (hasReacted) {
        await channel.deleteReaction(messageId, type);
      } else {
        await channel.sendReaction(messageId, { type });
      }
      return setAnchorEl(null);
    },
    [channel, messageId, own_reactions],
  );

  return (
    <>
      <Stack
        ref={anchorRef}
        className="str-chat__message-simple__actions str-chat__message-options"
        direction={isMine ? 'row-reverse' : 'row'}
        sx={{
          opacity: isHovered ? 1 : 0,
          background: !isMine
            ? theme.palette.background.elevation['0']
            : undefined,
          borderRadius: !isMine ? '10px' : undefined,
          border: !isMine ? `1px solid ${theme.palette.border}` : undefined,
          px: !isMine ? 2 : 0,
          alignItems: 'center',
        }}
      >
        {!isMine && (
          <>
            <QuickReactions />
            <AddReactionOutlinedIcon
              onClick={openEmojiPicker}
              sx={interactionButtonSx}
            />
            <ReplyIcon onClick={reply} sx={interactionButtonSx} />
          </>
        )}
        <MoreHorizIcon onClick={openMenuOptions} sx={interactionButtonSx} />
        {status === 'failed' && (
          <Tooltip title="Failed to send. Try again?" placement="top">
            <span>
              <ReplayIcon
                onClick={resendMessage}
                sx={{ ...interactionButtonSx, color: theme.palette.error.main }}
              />
            </span>
          </Tooltip>
        )}
      </Stack>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{
          style: {
            boxShadow: 'none',
            backgroundColor: theme.palette.background.elevation[8],
            borderRadius: '10px',
            scrollbarGutter: 'none',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
          },
        }}
        sx={{
          ml: 1,
          'em-emoji-picker': {
            '--category-icon-size': '20px',
            '--font-size': '16px',
          },
          'em-emoji-picker > #shadow-root > section': {
            backgroundColor: `${theme.palette.background.elevation[8]} !important`,
          },
        }}
      >
        {!emojiData ? (
          <LottieLoader sx={{ height: '24px', width: '24px' }} />
        ) : (
          <Box ref={emojiPickerRef}>
            <Picker
              enableFrequentEmojiSort
              maxFrequentRows={1}
              data={emojiData}
              perLine={9}
              emojiButtonSize={36}
              emojiSize={24}
              onEmojiSelect={toggleReaction}
            />
          </Box>
        )}
      </Popover>
    </>
  );
};

export const MessageInteractions = memo(MessageInteractionsUnmemoized);

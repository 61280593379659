import { ViewerCount } from './ViewerCount';
import { memo } from '../../../util/memo';
import { useHover } from '../../../contexts/HoverContext';
import { useMemo } from 'react';

const ViewerCountHoverableUnmemoized = () => {
  const { isHovered } = useHover();

  const viewerCount = useMemo(() => {
    return isHovered && <ViewerCount />;
  }, [isHovered]);

  // eslint-disable-next-line @blumintinc/blumint/no-useless-fragment
  return <>{viewerCount}</>;
};

export const ViewerCountHoverable = memo(ViewerCountHoverableUnmemoized);

import { useMemo } from 'react';
import { GradientTypography } from '../../gradients/GradientTypography';
import Stack from '@mui/material/Stack';
import { SearchAlgolia } from '../../algolia/search/SearchAlgolia';
import Badge from '@mui/material/Badge';
import { useTheme } from '@mui/material/styles';
import { memo } from '../../../util/memo';

export type FriendCarouselHeaderProps = {
  title: string;
  onlineCount?: number;
  notificationCount?: number;
  separateSearch?: boolean;
};

const FriendCarouselHeaderUnmemoized = ({
  title,
  onlineCount,
  notificationCount = 0,
  separateSearch = false,
}: FriendCarouselHeaderProps) => {
  const integratedSearch = useMemo(() => {
    if (separateSearch) {
      return undefined;
    }
    return <SearchAlgolia />;
  }, [separateSearch]);

  const theme = useTheme();
  const separateSearchCard = useMemo(() => {
    return separateSearch ? (
      <SearchAlgolia
        collapsable={false}
        sx={{ backgroundColor: theme.palette.background.elevation[9] }}
      />
    ) : undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [separateSearch]);

  const onlineText = useMemo(() => {
    if (onlineCount === undefined) {
      return undefined;
    }
    return (
      <GradientTypography
        variant="subtitle1"
        gradientColor={'secondary.vertical'}
        noWrap
      >
        {`${onlineCount} Online`}
      </GradientTypography>
    );
  }, [onlineCount]);

  return (
    <Stack spacing={2} width="100%" pb={2}>
      <Stack
        direction="row"
        width="100%"
        spacing={2}
        sx={{ justifyContent: 'space-between', alignItems: 'center' }}
      >
        <Stack direction="row" spacing={2} alignItems="center" flex={1}>
          <Badge badgeContent={notificationCount}>
            <GradientTypography
              variant="h6"
              gradientColor={'primary.vertical'}
              width="fit-content"
              noWrap
              sx={{ flexShrink: 0 }}
            >
              {title}
            </GradientTypography>
          </Badge>
          {integratedSearch}
        </Stack>
        {onlineText}
      </Stack>
      {separateSearchCard}
    </Stack>
  );
};

export const FriendCarouselHeader = memo(FriendCarouselHeaderUnmemoized);

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { GradientIcon } from '../../gradients/GradientIcon';
import { memo } from '../../../util/memo';
import CheckIcon from '@mui/icons-material/CheckRounded';
import PriorityHighIcon from '@mui/icons-material/PriorityHighRounded';
import { useMessage } from './MessageContext';
import {
  AVATAR_GROUP_MAX,
  AvatarGroupNext,
} from '../../avatar/AvatarGroupNext';
import { useTheme } from '@mui/material/styles';
import { MessageTimestamp as StreamMessageTimestamp } from 'stream-chat-react';
import { AvatarGroupUser } from '../../../../functions/src/types/firestore/User/ChannelGroup/util/avatarGroupUser';
import { useMemo } from 'react';

const ICON_SX = {
  height: { xs: '20px', lg: '14px' },
  width: { xs: '20px', lg: '14px' },
};

const MessageStatusUnmemoized = () => {
  const theme = useTheme();
  const { message, readBy } = useMessage();

  const avatarUsers = readBy
    ?.map(({ image, userId }) => {
      return { imgUrl: image, userId };
    })
    ?.filter(({ imgUrl }) => {
      return !!imgUrl;
    }) as AvatarGroupUser[];

  const isSurplus = avatarUsers.length > AVATAR_GROUP_MAX;

  const status = useMemo(() => {
    return !!readBy?.length ? (
      <AvatarGroupNext
        users={avatarUsers}
        height={{ xs: 24, lg: 18 }}
        width={{ xs: 24, lg: 18 }}
        sx={{
          '.MuiAvatar-root:nth-of-type(1)': {
            height: 18,
            width: 18,
            mt: isSurplus ? '2px' : undefined,
            ml: isSurplus ? '-4px' : '-8px',
            border: 'none !important',
            background: isSurplus
              ? theme.palette.background.elevation[24]
              : undefined,
          },
        }}
      />
    ) : (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box
          sx={{
            time: {
              fontSize: { xs: '18px', lg: '12px' },
              color: theme.palette.text.secondary,
            },
          }}
        >
          <StreamMessageTimestamp />
        </Box>
        {message.status === 'received' && (
          <GradientIcon IconComponent={CheckIcon} sx={ICON_SX} />
        )}
        {message.status === 'failed' && (
          <PriorityHighIcon
            sx={{ ...ICON_SX, color: theme.palette.error.main }}
          />
        )}
      </Stack>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSurplus, message.status, readBy?.length]);

  return (
    <Box
      pt={1}
      sx={{
        '.MuiAvatar-root': {
          border: `2px solid ${theme.palette.background.elevationSolid[6]} !important`,
        },
        alignSelf: 'flex-end',
      }}
    >
      {status}
    </Box>
  );
};

export const MessageStatus = memo(MessageStatusUnmemoized);

import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SxProps } from '@mui/material/styles';
import {
  cloneElement,
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useMemo,
} from 'react';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { GradientIconButton } from '../gradients/GradientIconButton';
import Box from '@mui/material/Box';
import { memo } from '../../util/memo';

export type SignInMenuItemProps = {
  label: string;
  onClick: () => void | Promise<void>;
  IconComponent?: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>;
  disableIcon?: boolean;
  Wrapper?: ReactElement<
    {
      sx?: SxProps | undefined;
      children?: ReactNode;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    string | JSXElementConstructor<any>
  >;
};

export const SignInMenuItem = memo(function SignInMenuItemUnmemoized({
  label,
  onClick,
  IconComponent,
  disableIcon,
  Wrapper,
}: SignInMenuItemProps) {
  const menuItem = useMemo(() => {
    return (
      <MenuItem>
        <Stack
          direction={'row'}
          spacing={2}
          alignItems={'center'}
          onClick={onClick}
        >
          {!!IconComponent && (
            <GradientIconButton
              IconComponent={IconComponent}
              size="small"
              sxOuter={{ p: 0 }}
              disabled={disableIcon}
            />
          )}
          <Box>{label}</Box>
        </Stack>
      </MenuItem>
    );
  }, [label, onClick, IconComponent, disableIcon]);

  if (!Wrapper) {
    return menuItem;
  }
  return cloneElement(Wrapper, {}, menuItem);
});

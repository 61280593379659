/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useRef, useState } from 'react';
import { memo } from '../../util/memo';
import Image, { ImageProps } from 'next/image';
import { Breakpoint } from '@mui/material/styles';

const OBSERVER_OPTIONS = {
  attributes: true,
  attributeFilter: ['class'],
  childList: false,
  subtree: false,
};

const NEAR_GLIDER_REGEX = /(?:^|\s)(left-[1-6]|right-[1-6])(?:\s|$)/;

export type ResponsiveSize = {
  [key in Breakpoint]?: number;
};

const getLargestSize = (sizes: ResponsiveSize): number => {
  return Math.max(...Object.values(sizes));
};

export type ImageOptimizedProps = Omit<
  ImageProps,
  'lazyBoundary' | 'lazyRoot' | 'width' | 'height'
> & {
  width?: ImageProps['width'] | ResponsiveSize;
  height?: ImageProps['height'] | ResponsiveSize;
};

const ImageOptimizedUnmemoized: React.FC<ImageOptimizedProps> = ({
  src,
  loading: loadingDefault,
  width,
  height,
  ...props
}) => {
  const imageRef = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState<ImageProps['loading']>(loadingDefault);

  const findTargetNode = () => {
    let parent = imageRef.current && imageRef.current.parentElement;
    while (parent) {
      const isSlide = parent.className.includes('glider-slide');
      if (isSlide) {
        return parent;
      }
      parent = parent.parentElement;
    }
    return null;
  };

  useEffect(() => {
    if (loadingDefault === 'eager') {
      return;
    }

    const targetNode = findTargetNode();
    if (!targetNode) {
      return;
    }

    const observerCallback = (mutationsList: MutationRecord[]) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes') {
          const target = mutation.target as HTMLElement;
          if (NEAR_GLIDER_REGEX.test(target.className)) {
            setLoading('eager');
            observer.disconnect();
            break;
          }
        }
      }
    };

    const observer = new MutationObserver(observerCallback);
    observer.observe(targetNode, OBSERVER_OPTIONS);

    return () => {
      observer.disconnect();
    };
  }, [loadingDefault]);

  const finalWidth = typeof width === 'object' ? getLargestSize(width) : width;
  const finalHeight =
    typeof height === 'object' ? getLargestSize(height) : height;

  return (
    <div ref={imageRef} id="inline-span" style={{ aspectRatio: 'auto' }}>
      <Image
        {...props}
        src={src}
        quality={60}
        loading={loading}
        width={finalWidth}
        height={finalHeight}
        style={{
          objectFit: 'contain',
          borderRadius: props.style?.borderRadius || 'inherit',
          ...props.style,
        }}
      />
    </div>
  );
};

export const ImageOptimized = memo(ImageOptimizedUnmemoized);

// const firebaseEndpoint = 'https://firebasestorage.googleapis.com/';

// const imageLoader = ({ src, width, quality }: ImageProps) => {
//   let imagekitEndpoint = 'https://ik.imagekit.io/blumint';

//   src = src.slice(firebaseEndpoint.length);

//   if (src[0] === '/') src = src.slice(1);
//   const params = [`w-${width}`];
//   if (quality) {
//     params.push(`q-${quality}`);
//   }
//   const paramsString = params.join(',');
//   if (imagekitEndpoint[imagekitEndpoint.length - 1] === '/')
//     imagekitEndpoint = imagekitEndpoint.substring(
//       0,
//       imagekitEndpoint.length - 1,
//     );
//   return `${imagekitEndpoint}/tr:${paramsString}/${src}`;
// };

// export const ImageOptimized: React.FC<ImageProps> = (props) => {
//   /**
//    * if (props.src?.includes(firebaseEndpoint)) {
//     return <Image loader={imageLoader} {...props} />;
//   }
//    */
//   // eslint-disable-next-line jsx-a11y/alt-text
//   return <Image {...props} />;
// };

// export { imageLoader };

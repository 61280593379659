import Stack from '@mui/material/Stack';
import { useMemo } from 'react';
import { memo } from '../../util/memo';
import { AuthenticationPasswordValidation } from './AuthenticationPasswordValidation';
import { useAuthSubmit } from 'src/contexts/AuthSubmitContext';
import { Password } from './Password';

export type AuthenticationPasswordStackProps = {
  autoFocus?: boolean;
};

export const AuthenticationPasswordStack = memo(
  function AuthenticationPasswordStackUnmemoized({
    autoFocus = true,
  }: AuthenticationPasswordStackProps) {
    const { signUp, errorMessage, hasUserTyped, passwordStrengthErrors } =
      useAuthSubmit();
    const {
      tooShort,
      tooLong,
      needsUppercase,
      needsLowercase,
      needsNumber,
      needsSymbol,
    } = passwordStrengthErrors;
    const {
      password: passwordErrorMessage,
      confirmPassword: confirmPasswordErrorMessage,
    } = errorMessage;
    const requirementsMet = useMemo(() => {
      if (
        tooShort ||
        tooLong ||
        needsLowercase ||
        needsUppercase ||
        needsNumber ||
        needsSymbol
      ) {
        return false;
      }
      return true;
    }, [
      needsLowercase,
      needsNumber,
      needsSymbol,
      needsUppercase,
      tooLong,
      tooShort,
    ]);

    return (
      <Stack spacing={4} width="100%">
        <Stack spacing={1} width="100%">
          <Password
            passwordType={'password'}
            errorMessage={passwordErrorMessage}
            autoFocus={autoFocus}
            color={!requirementsMet ? 'error' : undefined}
            sx={{ width: '100%' }}
          />
          {hasUserTyped.password && <AuthenticationPasswordValidation />}
        </Stack>
        <Password
          passwordType={'confirmPassword'}
          errorMessage={confirmPasswordErrorMessage}
          onSubmit={signUp}
          sx={{ width: '100%' }}
        />
      </Stack>
    );
  },
);

import { memo } from '../../util/memo';
import { useCallback } from 'react';
import { useChatContext as useStreamChatContext } from 'stream-chat-react';
import { RoleMap } from '../../../functions/src/types/Roles';
import { LoadingGradientIconButton } from '../buttons/LoadingGradientIconButton';
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded';
import { useRoomContext as useLivekitRoom } from '@livekit/components-react';
import { SxProps } from '@mui/material/styles';

export type KickCallerButtonProps = {
  userId: string;
  sx?: SxProps;
};

const KickCallerButtonUnmemoized = ({
  userId,
  sx = {},
}: KickCallerButtonProps) => {
  const { channel } = useStreamChatContext();
  const room = useLivekitRoom();

  const kickParticipant = useCallback(async () => {
    const roles = channel?.data?.roles as RoleMap<
      'contributor' | 'moderator' | 'admin' | 'streamer' | 'registrant'
    >;
    if (!roles) {
      return;
    }

    const { removeCaller } = await import(
      '../../firebaseCloud/liveKit/removeCaller'
    );
    await removeCaller({
      roomName: room.name,
      callerId: userId,
      roles,
    });
  }, [channel?.data?.roles, room.name, userId]);

  return (
    <LoadingGradientIconButton
      gradientColor="error.horizontal"
      loadingColor="error"
      IconComponent={PersonRemoveRoundedIcon}
      onClick={kickParticipant}
      sx={{ p: '0px !important', ...sx }}
    />
  );
};

export const KickCallerButton = memo(KickCallerButtonUnmemoized);

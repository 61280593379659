import Typography from '@mui/material/Typography';
import { memo } from '../../../util/memo';
import { useMessage } from './MessageContext';
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';
import Button from '@mui/material/Button';

const MessageRepliesPreviewUnmemoized = () => {
  const { replyCount, handleOpenThread: openThread } = useMessage();
  const theme = useTheme();

  const replyText = useMemo(() => {
    return (
      !!replyCount && `${replyCount} repl${replyCount !== 1 ? 'ies' : 'y'}`
    );
  }, [replyCount]);

  return (
    <Button
      variant="text"
      sx={{
        boxShadow: 'none',
        p: 0,
        '& .MuiTypography-root': {
          py: 0,
          px: 1,
        },
        '&.MuiButtonBase-root': {
          width: 'fit-content',
          minWidth: '0px !important',
        },
      }}
      onClick={async (event) => {
        await openThread(event);
      }}
    >
      <Typography
        variant="subtitle2"
        color={theme.palette.primary.mid}
        sx={{
          textTransform: 'lowercase',
        }}
      >
        {replyText}
      </Typography>
    </Button>
  );
};

export const MessageRepliesPreview = memo(MessageRepliesPreviewUnmemoized);

import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { memo } from '../../../../util/memo';
import { AvatarNext } from 'src/components/avatar/AvatarNext';
import { GradientIconButton } from 'src/components/gradients/GradientIconButton';
import { InfoIconNoBorder } from 'src/components/icons/InfoIconNoBorder';

export type MatchCellButtonProps = {
  Icon?: OverridableComponent<SvgIconTypeMap>;
  onClick: () => void;
};

const MatchCellButtonUnmemoized: React.FC<MatchCellButtonProps> = ({
  Icon = InfoIconNoBorder,
  onClick,
}) => {
  return (
    <AvatarNext variant="gradientOutlined" sx={{ width: 20, height: 20 }}>
      <GradientIconButton
        onClick={onClick}
        IconComponent={Icon}
        sx={{ width: 12, height: 12 }}
      />
    </AvatarNext>
  );
};
export const MatchCellButton = memo(
  MatchCellButtonUnmemoized,
) as typeof MatchCellButtonUnmemoized;

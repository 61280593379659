import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { GradientTypography } from '../gradients/GradientTypography';
import { DialogCloseButton } from '../dialog/DialogCloseButton';
import { AuthenticationStack } from './auth-stack/AuthenticationStack';
import { memo } from '../../util/memo';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';
export type AuthenticationActionableProps = {
  onClose: () => void;
  title: string;
  subtitle?: string | ReactNode;
};

export const AuthenticationActionable = memo(
  function AuthenticationActionableUnmemoized({
    onClose,
    title,
    subtitle,
  }: AuthenticationActionableProps) {
    return (
      <Stack spacing={4} sx={{ height: '100%' }}>
        <Box
          width={'100%'}
          display={'flex'}
          flexDirection={'row'}
          alignItems="center"
          justifyContent={'space-between'}
        >
          <GradientTypography
            gradientColor="primary.vertical"
            typography={'h5'}
            textAlign={'center'}
            textTransform={'uppercase'}
            whiteSpace="nowrap"
            sx={{ width: '100%', mr: '-30px' }}
          >
            {title}
          </GradientTypography>
          <DialogCloseButton onClose={onClose} />
        </Box>
        {typeof subtitle === 'string' ? (
          <Typography variant="body1" textAlign="center" textOverflow="wrap">
            {subtitle}
          </Typography>
        ) : (
          subtitle
        )}
        <AuthenticationStack />
      </Stack>
    );
  },
);

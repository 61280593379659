import { useAuthFlowDialog } from '../../hooks/auth/useAuthFlowDialog';
import Button from '@mui/material/Button';
import { MouseEvent } from 'react';

export const SignUpButton = () => {
  const { open: openAuthDialog } = useAuthFlowDialog();
  const triggerClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    openAuthDialog();
  };
  return (
    <Button variant="text" size="large" onClick={triggerClick}>
      Sign Up
    </Button>
  );
};

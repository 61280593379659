import { useCallback, useMemo } from 'react';
import { memo } from '../../../../util/memo';
import Stack from '@mui/material/Stack';
import { MatchDetailsTeams } from './MatchDetailsTeams';
import { Member } from 'functions/src/types/firestore/Game/Tournament/Guestlist';
import { CompetitorOutcomeProps } from '../../CompetitorOutcome';
import { Competitor } from '../MatchCompetitor';
import { MatchLiveBadge } from '../MatchLiveBadge';
import { useRouterState } from '../../../../hooks/routing/useRouterState';
import { SessionDetails } from './SessionDetails';
import { TabPane, TabPanes } from '../../../routing/TabPanes';
import { Round } from '../../../../../functions/src/types/firestore/Game/Tournament/Bracket';
import { useMatch } from '../MatchProvider';
import { MatchRewards } from './MatchRewards';
import { useActiveMatchRouter } from '../../../../hooks/tournaments/useActiveMatchRouter';
import { findPreviousDisputeIn } from '../../../../../functions/src/util/tournaments/moderation/findPreviousDisputeIn';

export const DATE_FORMAT: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
};

export type MatchCompetitor = Competitor &
  Pick<CompetitorOutcomeProps, 'outcome' | 'score'>;

export type CompetitorWithMembers = MatchCompetitor & {
  members: Member[];
};

export type MatchDetailsProps = { rounds: Round<Date>[] };

export const MatchDetails = memo(function MatchDetailsUnmemoized({
  rounds,
}: MatchDetailsProps) {
  const { games, lastSessionIndex, team1Scores, team2Scores } = useMatch();
  const { switchSession } = useActiveMatchRouter();
  const tabPanesState = useRouterState({
    key: 'session-dialog',
    location: 'queryParam',
  });

  const gameSessions = useMemo(() => {
    return games.map((game) => {
      return `GAME ${game}`;
    });
  }, [games]);

  const lockSessionIf = useCallback(
    (index: number) => {
      const hasPreviousDispute = findPreviousDisputeIn(
        team1Scores,
        team2Scores,
        index,
      );
      return index > lastSessionIndex || hasPreviousDispute;
    },
    [lastSessionIndex, team1Scores, team2Scores],
  );

  const sessionTabPanes: TabPane<string>[] = useMemo(() => {
    return gameSessions.map((session, index) => {
      return {
        value: `${index}`,
        component: <SessionDetails />,
        customization: {
          label: session,
          disabled: lockSessionIf(index),
        },
        onClick: () => {
          return switchSession(index);
        },
      };
    });
  }, [gameSessions, lockSessionIf, switchSession]);

  return (
    <MatchLiveBadge>
      <Stack direction="column" p={4} width="100%">
        <TabPanes
          tabPanes={sessionTabPanes}
          state={tabPanesState}
          variant="chip"
        />
        <MatchDetailsTeams />
        <MatchRewards rounds={rounds} />
      </Stack>
    </MatchLiveBadge>
  );
});

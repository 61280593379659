import {
  MessageInput as StreamMessageInput,
  MessageInputProps as StreamMessageInputProps,
  LocalAttachmentUploadMetadata,
} from 'stream-chat-react';
import Box from '@mui/material/Box';
import { ReplyIndicator } from '../ReplyIndicator';
import type { Channel as StreamChannel } from 'stream-chat';
import { useCallback, useMemo, useRef, useEffect } from 'react';
import { memo } from '../../../util/memo';
import { useReply } from '../ReplyContext';
import { useMessageDraft } from '../../../hooks/messaging/useMessageDraft';
import { useProcessMessage } from '../../../hooks/messaging/useProcessMessage';
import { useMessageInputHeight } from '../../../hooks/messaging/useMessageInputHeight';
import { useMessageInputFocus } from '../../../contexts/get-stream/MessageInputFocusContext';
import { MESSAGE_INPUT_ID } from '../channel/ChannelInner';
import type { SxProps } from '@mui/material/styles';

export const MESSAGE_INPUT_MAX_ROWS = 14;

export type MessageInputProps = StreamMessageInputProps & {
  id: string;
  sx?: SxProps;
};

const MessageInputUnmemoized = (props: MessageInputProps) => {
  const { id, sx = {}, ...restProps } = props;
  const { replyingTo, setReplyingTo } = useReply();
  const { getMainInputDraft, onInputChange } = useMessageDraft();
  const { sendMessageOverride, processFile } = useProcessMessage({
    replyingTo,
  });
  const inputRef = useRef<HTMLElement | null>(null);
  const messageInputHeight = useMessageInputHeight(inputRef);
  const { isFocused, setFocus, unsetFocus } = useMessageInputFocus();

  useEffect(() => {
    if (!replyingTo) {
      return;
    }
    setFocus(id);
  }, [replyingTo, setFocus, id]);

  const focus = useMemo(() => {
    return isFocused(id);
  }, [isFocused, id]);

  const textAreaProps = useMemo(() => {
    return {
      onChange: id === MESSAGE_INPUT_ID ? onInputChange : undefined,
      onFocus: (_) => {
        return setFocus(id);
      },
      onBlur: (_) => {
        return unsetFocus(id);
      },
    };
  }, [onInputChange, setFocus, unsetFocus, id]);

  const imageUpload = useCallback(
    async (
      file: LocalAttachmentUploadMetadata['file'],
      channel: StreamChannel,
    ) => {
      const newFile = processFile(file);
      if (!newFile) {
        return;
      }
      return await channel.sendImage(newFile);
    },
    [processFile],
  );

  const fileUpload = useCallback(
    async (
      file: LocalAttachmentUploadMetadata['file'],
      channel: StreamChannel,
    ) => {
      const newFile = processFile(file);
      if (!newFile) {
        return;
      }
      return await channel.sendFile(newFile);
    },
    [processFile],
  );

  const replyIndicator = useMemo(() => {
    return (
      !!replyingTo && (
        <ReplyIndicator
          username={replyingTo.username}
          onClick={() => {
            setReplyingTo(undefined);
          }}
        />
      )
    );
  }, [replyingTo, setReplyingTo]);

  return (
    <Box zIndex={!!replyingTo ? 10 : 0} width="100%" position="relative">
      {replyIndicator}
      <Box
        ref={inputRef}
        sx={{
          '& .str-chat__send-button': {
            height: `${messageInputHeight}px !important`,
          },
          ...sx,
        }}
      >
        <StreamMessageInput
          grow
          focus={focus}
          maxRows={MESSAGE_INPUT_MAX_ROWS}
          overrideSubmitHandler={sendMessageOverride}
          getDefaultValue={getMainInputDraft}
          additionalTextareaProps={textAreaProps}
          doFileUploadRequest={fileUpload}
          doImageUploadRequest={imageUpload}
          {...restProps}
        />
      </Box>
    </Box>
  );
};

export const MessageInput = memo(MessageInputUnmemoized);

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ReactNode } from 'react';
import { memo } from '../util/memo';
import { Footer } from './footer/Footer';
import { useTheme } from '@mui/material/styles';
import { APP_NAVIGATION_TAB_HEIGHT_MOBILE } from './AppNavigationTabs';
import { MIN_HEADER_HEIGHT } from './header/Header';

export const EXPLORE_ELEMENT_ID = 'explore' as const;

export type ExploreProps = {
  children: ReactNode;
};

const ExploreUnmemoized = ({ children }: ExploreProps) => {
  const theme = useTheme();

  return (
    <Stack
      id={EXPLORE_ELEMENT_ID}
      sx={{
        justifyContent: 'space-between',
        height: '100%',
        overflowY: 'scroll',
        pt: {
          xs: `${MIN_HEADER_HEIGHT + APP_NAVIGATION_TAB_HEIGHT_MOBILE}px`,
          md: `${MIN_HEADER_HEIGHT}px`,
        },
        ...theme.scrollbars.invisible,
      }}
    >
      <Box px={{ xs: 2, md: 0 }}>
        <main>{children}</main>
      </Box>
      <Box mt="60px">
        <Footer />
      </Box>
    </Stack>
  );
};
export const Explore = memo(ExploreUnmemoized);

import { useEffect, useRef, useMemo, useCallback } from 'react';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { GradientIcon } from '../gradients/GradientIcon';
import Typography from '@mui/material/Typography';
import { SxProps, useTheme } from '@mui/material/styles';
import {
  firstScrollableParent,
  scrollToHorizontally,
} from '../../util/scrollToHorizontally';
import Stepper from '@mui/material/Stepper';
import { memo } from '../../util/memo';

export type PhaseStepsProps = {
  phases: (
    | {
        Icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>> & {
          muiName: string;
        };
        label: string;
      }
    | undefined
  )[];
  activeIndex: number;
};

export const PhaseSteps = memo(function PhaseStepsUnmemoized({
  phases,
  activeIndex,
}: PhaseStepsProps) {
  const theme = useTheme();
  const activeStepRef = useRef<HTMLDivElement | null>(null);

  const stepperSx = useMemo(() => {
    return {
      '& .MuiStepConnector-line': {
        minWidth: '24px',
      },
      '& .Mui-disabled .MuiStepConnector-line': {
        borderColor: theme.palette.disabled.main,
      },
      ...theme.scrollbars.invisible,
      marginBottom: 4,
      marginTop: 2,
      overflowX: 'scroll',
      overflowY: 'hidden',
    };
  }, [theme]);

  const getStepLabelSx = useMemo(() => {
    return (isActive: boolean) => {
      return {
        height: 40,
        borderRadius: '100px',
        padding: '8px 24px',
        background: isActive
          ? 'rgba(11, 65, 110, 1)'
          : theme.palette.background.elevation[12],
      };
    };
  }, [theme]);

  const typographySx = useMemo(() => {
    return {
      ...theme.lineClamp(1, {
        textOverflow: 'unset',
        wrap: false,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }),
    } as SxProps;
  }, [theme]);

  const StepIcon = useCallback(
    (
      isActive: boolean,
      Icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>,
    ) => {
      if (isActive) {
        return (
          <GradientIcon
            gradientColor="primary.horizontal"
            IconComponent={Icon}
          />
        );
      }
      return <Icon htmlColor="rgba(204, 226, 255, 0.7)" />;
    },
    [],
  );

  useEffect(() => {
    const activeStepElement = activeStepRef.current;

    const scrollableParent = firstScrollableParent(activeStepElement);
    if (scrollableParent && activeStepElement) {
      scrollToHorizontally(activeStepElement, scrollableParent);
    }
  }, [activeIndex]);

  const steps = useMemo(() => {
    return phases.filter(Boolean).map((phase, index) => {
      const { label, Icon } = phase!;
      const isActive = index === activeIndex;

      return (
        <Step
          sx={{ whitespace: 'nowrap' }}
          ref={isActive ? activeStepRef : null}
          key={label}
        >
          <StepLabel
            sx={getStepLabelSx(isActive)}
            icon={StepIcon(isActive, Icon)}
          >
            <Typography
              variant="body1"
              color={isActive ? 'text.primary' : 'text.secondary'}
              sx={{
                ...typographySx,
                fontWeight: isActive ? '500' : undefined,
              }}
            >
              {label}
            </Typography>
          </StepLabel>
        </Step>
      );
    });
  }, [phases, activeIndex, getStepLabelSx, StepIcon, typographySx]);

  return (
    <Stepper activeStep={activeIndex} sx={stepperSx}>
      {steps}
    </Stepper>
  );
});

import Typography from '@mui/material/Typography';
import { AvatarNext } from 'src/components/avatar/AvatarNext';
import Stack from '@mui/material/Stack';
import { memo } from '../../../../util/memo';
import { TypographyTruncated } from 'src/components/TypographyTruncated';

export type CompetitorInfoProps = {
  avatarSrc: string;
  name?: string;
  countFill?: number;
  countMax?: number;
  isSoloTeam: boolean;
};

const CompetitorInfoUnmemoized: React.FC<CompetitorInfoProps> = ({
  avatarSrc,
  name = 'Auto Team',
  countFill,
  countMax,
}) => {
  return (
    <Stack direction="row">
      <AvatarNext
        height={50}
        width={50}
        sx={{
          mr: 1,
          height: { xs: '44px', md: '50px' },
          width: { xs: '44px', md: '50px' },
        }}
        alt={`${name}'s Avatar`}
        src={avatarSrc}
      />

      <TypographyTruncated
        variant="subtitle1"
        sx={{
          textTransform: 'uppercase',
          alignSelf: 'center',
          pl: { xs: 1, md: 2 },
        }}
        text={name}
        textMaxLength={25}
      />
      {!countFill && !countMax && (
        <Typography
          variant="subtitle1"
          sx={{
            alignSelf: 'center',
            color: 'rgba(204, 226, 255, 0.7)',
            pl: 2,
          }}
        >{`${countFill}/${countMax}`}</Typography>
      )}
    </Stack>
  );
};

export const CompetitorInfo = memo(
  CompetitorInfoUnmemoized,
) as typeof CompetitorInfoUnmemoized;

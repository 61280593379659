import { useEffect, useState, useMemo } from 'react';
import { memo } from '../../../util/memo';
import { AvatarGroupNext } from '../../avatar/AvatarGroupNext';
import { ReplyProvider } from '../ReplyContext';
import dynamic from 'next/dynamic';
import { useTheme } from '@mui/material/styles';
import { toKebabCase } from '../../../util/string';
import { useAuth } from '../../../contexts/AuthContext';
import { LottieLoader } from '../../LottieLoader';
import Stack from '@mui/material/Stack';
import type { GroupFilter } from '../../../../functions/src/types/firestore/User/ChannelGroup';
import { toChannelId } from '../../../../functions/src/util/messaging/toChannelId';
import { ChannelGroupHeader } from './ChannelGroupHeader';
import { ActiveChannelGroup } from '../../../../functions/src/util/messaging/toActiveChannelGroup';
import { isPersonal } from '../../../../functions/src/types/firestore/User/ChannelGroup/util/isPersonal';
import { sortGroups } from '../../../util/messaging/sortGroups';
import { useStream } from '../../../contexts/get-stream/StreamContext';
import { ChannelListProvider } from '../../../contexts/ChannelListContext';
import { MessageInputFocusProvider } from '../../../contexts/get-stream/MessageInputFocusContext';

const ChannelGroup = dynamic(async () => {
  return (await import('./ChannelGroup')).ChannelGroup;
});

export type ChannelGroupActiveProps = {
  channelGroup: ActiveChannelGroup;
};

const ChannelGroupActiveUnmemoized = ({
  channelGroup,
}: ChannelGroupActiveProps) => {
  const theme = useTheme();
  const { userData } = useAuth();
  const [profileId, setProfileId] = useState<string | undefined>();
  const { chatClient } = useStream();

  const {
    groupFilter,
    title,
    subtitle,
    hasMultipleChannels,
    isDm,
    isSupport,
    isTournament,
    isChannelCreated,
    permanence,
    memberIds,
    avatarUsers,
  } = channelGroup;

  const channelGroupTitleColor = `${
    isTournament ? 'warning' : 'primary'
  }.vertical`;

  const avatarBorderColor = isTournament
    ? theme.palette.warning.main
    : isSupport
    ? theme.palette.primary.main
    : undefined;

  const avatarBorder = !!avatarBorderColor
    ? undefined
    : `2px solid ${theme.palette.background.elevationSolid[6]} !important`;

  useEffect(() => {
    const userId = memberIds?.filter((memberId) => {
      return memberId !== userData?.id;
    });

    if (!title || !isDm || !userId) {
      return;
    }

    setProfileId(userId[0]);
  }, [isDm, memberIds, title, userData?.id]);

  const tournamentId = groupFilter[0]?.tournamentId;

  const href = isDm
    ? `/profile/${profileId}`
    : isTournament
    ? `/?event=Game/${toKebabCase(subtitle)}/Tournament/${tournamentId}`
    : undefined;

  const isPersonalChannelGroup = useMemo(() => {
    return isPersonal({
      ...channelGroup,
      imgUrl: avatarUsers[0].imgUrl,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelGroup, avatarUsers[0].imgUrl]);

  const groupFilterModified = useMemo(() => {
    const { type } = groupFilter[0];
    return (
      isPersonalChannelGroup
        ? [
            {
              cid: `${type}:${toChannelId(groupFilter[0])}`,
              type,
            },
          ]
        : groupFilter
    ) as GroupFilter;
  }, [groupFilter, isPersonalChannelGroup]);

  return (
    <ReplyProvider>
      <ChannelListProvider defaultOpen={hasMultipleChannels}>
        <ChannelGroupHeader
          title={title}
          subtitle={subtitle}
          gradientColor={channelGroupTitleColor}
          href={href}
          tournamentId={tournamentId}
          permanence={permanence}
          Figure={
            <AvatarGroupNext
              max={2}
              height={42}
              width={42}
              users={avatarUsers}
              borderColor={avatarBorderColor}
              showStatus={!isTournament && !isSupport}
              sx={{
                '.MuiAvatar-root': {
                  border: avatarBorder,
                  backgroundColor: theme.palette.action.disabled,
                  fontSize: '16px',
                },
              }}
              badgeSx={{
                '& .MuiBadge-badge': {
                  borderRadius: '50%',
                  height: '14px',
                  width: '14px',
                  border: `2px solid ${theme.palette.background.elevation[6]}`,
                  boxShadow: `inset 0 0 0 1px ${theme.palette.text.primary}`,
                },
              }}
            />
          }
        />
        {isChannelCreated && !!chatClient?.user ? (
          <MessageInputFocusProvider>
            <ChannelGroup
              groupFilter={groupFilterModified}
              hasMultipleChannels={hasMultipleChannels}
              sortGroups={sortGroups}
            />
          </MessageInputFocusProvider>
        ) : (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{ height: '100%', width: '100%' }}
          >
            <LottieLoader sx={{ height: '40px', width: '40px' }} />
          </Stack>
        )}
      </ChannelListProvider>
    </ReplyProvider>
  );
};

export const ChannelGroupActive = memo(ChannelGroupActiveUnmemoized);

import Stack from '@mui/material/Stack';
import { memo } from '../../../util/memo';
import { useMemo } from 'react';
import { OUTCOME_CLAIMS } from '../../../../functions/src/types/firestore/Game/Tournament/Bracket';
import { useScoring } from '../../../contexts/ScoringContext';
import { SelectableOption } from '../../SelectableOption';
import Typography from '@mui/material/Typography';

export const ResultAction = memo(function ResultActionUnmemoized() {
  const { selectOutcome, outcome, teamToScore } = useScoring();

  const resultButtons = useMemo(() => {
    return OUTCOME_CLAIMS.map((outcomeEach) => {
      const isSelected = outcomeEach === outcome;
      const pronoun =
        !!teamToScore &&
        teamToScore.acceptedUserIds &&
        teamToScore.acceptedUserIds.length > 1
          ? 'We '
          : 'I ';
      const outcomeText = outcomeEach === 'win' ? 'Won' : 'Lost';
      const text = `${pronoun}${outcomeText}`;
      return (
        <>
          <SelectableOption
            key={text}
            isSelected={isSelected}
            onClick={() => {
              return selectOutcome(outcomeEach);
            }}
            justifyContent={'center'}
            width={750}
            height={42}
          >
            <Typography variant="body1" color={'text.primary'}>
              {text}
            </Typography>
          </SelectableOption>
        </>
      );
    });
  }, [outcome, selectOutcome, teamToScore]);
  return (
    <Stack
      direction={'row'}
      gap={2}
      alignItems={'center'}
      width={'100%'}
      justifyContent={'center'}
    >
      {resultButtons}
    </Stack>
  );
});

import { useMemo, useContext } from 'react';
import { memo } from '../../util/memo';
import { TabPanes, TabPane } from '../routing/TabPanes';
import { TournamentContext } from '../../contexts/docs/TournamentContext';
import { BracketPane } from './brackets/BracketPane';
import { HeatsPane } from './heats-leaderboards/HeatsPane';
import { useRouterState } from '../../hooks/routing/useRouterState';
import { ParticipantsPane } from './participants/ParticipantsPane';
//import { ResultsPane } from './ResultsPane';
import { OverviewPane } from './overview/OverviewPane';
import { TournamentRegistrationProvider } from '../../contexts/TournamentRegistrationContext';
import { RegistrationPane } from './registration/RegistrationPane';
import { BracketContext } from '../../contexts/docs/BracketContext';
import {
  TOURNAMENT_PHASE_PUBLISHED,
  TournamentPhasePublished,
} from '../../../functions/src/types/firestore/Game/Tournament';
// import { useOpenGeneralChannelOnLoad } from '../../hooks/messaging/useOpenGeneralChannelOnLoad';
import { useActiveTournamentChannel } from '../../hooks/messaging/useActiveTournamentChannel';

export const PHASES_BEFORE_READY = [
  'unpublished',
  'review',
  'registration',
  'checkIn',
];

function TournamentTabsUnmemoized() {
  const tournament = useContext(TournamentContext);
  const bracket = useContext(BracketContext);
  const { phase, bracketType, participantsAggregated = [] } = tournament;
  const { openGeneralChannel } = useActiveTournamentChannel(tournament.id);

  // useOpenGeneralChannelOnLoad(tournament.id);

  const defaultTab = useMemo(() => {
    const defaultTabsMap = {
      unpublished: 'overview',
      review: 'overview',
      registration: 'registration',
      checkIn: 'registration',
      ready: 'participants',
      'no-bracket': 'participants',
      'single-elimination': bracket?.bracket.length
        ? 'bracket'
        : 'participants',
      'double-elimination': bracket?.bracket.length
        ? 'bracket'
        : 'participants',
      heats: 'heats',
    };

    return (
      defaultTabsMap[String(phase)] ||
      defaultTabsMap[String(bracketType)] ||
      'registration'
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phase, bracketType, !!bracket?.bracket.length]);

  const tabPanesState = useRouterState({
    key: 'tournamentTab',
    defaultValue: defaultTab,
  });

  const isBeforeReady = PHASES_BEFORE_READY.includes(phase);
  const isUnpublished = phase === 'unpublished';

  const tabPanes: TabPane<string>[] = useMemo(() => {
    const baseTabs: TabPane<string>[] = [
      {
        value: 'overview',
        component: <OverviewPane />,
      },
      {
        value: 'registration',
        component: isUnpublished ? (
          <></>
        ) : (
          <TournamentRegistrationProvider tournament={tournament}>
            <RegistrationPane />
          </TournamentRegistrationProvider>
        ),
        customization: {
          disabled: isUnpublished,
        },
      },
      {
        value: 'participants',
        component: <ParticipantsPane />,
        customization: {
          disabled: isUnpublished,
        },
      },
    ];

    if (bracketType === 'heats') {
      baseTabs.push({
        value: 'heats',
        component: <HeatsPane />,
        customization: {
          disabled: isBeforeReady || participantsAggregated.length <= 1,
        },
      });
    } else if (
      ['single-elimination', 'double-elimination'].includes(bracketType)
    ) {
      baseTabs.push(
        {
          value: 'bracket',
          component: !!bracket ? <BracketPane bracket={bracket} /> : <></>,
          customization: {
            disabled:
              isBeforeReady || !bracket || participantsAggregated.length <= 1,
          },
        },
        // {
        //   value: 'results',
        //   component: <ResultsPane />,
        //   customization: {
        //     disabled: isBeforeLive,
        //   },
        // },
      );
    }
    if (
      TOURNAMENT_PHASE_PUBLISHED.includes(phase as TournamentPhasePublished)
    ) {
      baseTabs.push({
        value: 'lobby',
        component: <></>,
        onClick: openGeneralChannel,
      });
    }
    return baseTabs;
  }, [
    isUnpublished,
    tournament,
    bracketType,
    phase,
    isBeforeReady,
    participantsAggregated.length,
    bracket,
    openGeneralChannel,
  ]);

  return (
    <TabPanes tabPanes={tabPanes} state={tabPanesState} variant="chip-large" />
  );
}

export const TournamentTabs = memo(TournamentTabsUnmemoized);
export const MAX_TOURNAMENT_PANE_WIDTH = '970px' as const;

import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { memo } from '../util/memo';
import type {
  Event as StreamEvent,
  Channel as StreamChannel,
  FormatMessageResponse,
} from 'stream-chat';
import {
  GroupFilter,
  PERSONAL_TYPES,
  PersonalType,
} from '../../functions/src/types/firestore/User/ChannelGroup';
import { useStream } from './get-stream/StreamContext';
import { toChannelGroupId } from '../../functions/src/util/messaging/toChannelGroupPath';
import { ChannelFetcher } from '../../functions/src/util/messaging/ChannelFetcher';
import { toChannelId } from '../../functions/src/util/messaging/toChannelId';
import { useRealtimeChannelGroups } from './RealtimeChannelGroupContext';
import { toGroupId } from '../../functions/src/util/messaging/toGroupId';
import { TournamentPhase } from '../../functions/src/types/firestore/Game/Tournament';
import { extractMessages } from '../util/messaging/extractMessages';
import { latestMessageUserId } from '../util/messaging/latestMessageUserId';
import { useGetStreamId } from '../hooks/messaging/useGetStreamId';
import { findExistingUnreadCount } from '../util/findExistingUnreadCount';
import { ChannelData } from '../../functions/src/util/messaging/ChannelSetter';

export const ONE = 1;

export type ChannelUnsubscribes = Record<string, (() => void)[]>;

export type UnreadMessageCountGroup = {
  total: number;
  channels: {
    [cid: string]: {
      unreadCount: number;
      unseen: boolean;
      phase?: TournamentPhase;
      matchId?: string;
      tournamentId?: string;
    };
  };
};

export type UnreadMessageCountStructure = Record<
  string,
  UnreadMessageCountGroup
>;

export type UnreadMessageCountProps = {
  unreadMessageCount: UnreadMessageCountStructure;
  subscribedGroupFilters: GroupFilter[];
  unionChannel: (channel: StreamChannel, channelGroupId: string) => void;
};

export type UnreadMessageCountProviderProps = {
  children: ReactNode;
};

export type UpdateUnreadCountParams = {
  unreadCountPrevious: UnreadMessageCountStructure;
  groupId: string;
  unseen: boolean;
  cid: string;
  count: number;
  phase?: TournamentPhase;
  matchId?: string;
  tournamentId?: string;
};

const UnreadMessageCountContext = createContext<UnreadMessageCountProps | null>(
  null,
);

export const useUnreadMessageCount = () => {
  const context = useContext(UnreadMessageCountContext);
  if (!context) {
    throw new Error(
      'useUnreadMessageCount must be used within a UnreadMessageCountProvider',
    );
  }
  return context;
};

const UnreadMessageCountProviderUnmemoized = ({ children }) => {
  const [unreadMessageCount, setUnreadMessageCount] =
    useState<UnreadMessageCountStructure>({});
  const groupUnsubscribesRef = useRef<ChannelUnsubscribes>({});
  const { chatClient } = useStream();
  const { realtimeChannelGroups } = useRealtimeChannelGroups();
  const userId = useGetStreamId();

  const subscribedGroupFilters = useMemo(() => {
    return realtimeChannelGroups.map(({ groupFilter }) => {
      return groupFilter;
    });
  }, [realtimeChannelGroups]);

  const updateUnreadCount = useCallback(
    ({
      unreadCountPrevious,
      groupId,
      cid,
      count,
      phase,
      unseen,
      matchId,
      tournamentId,
    }: UpdateUnreadCountParams) => {
      const groupCount = unreadCountPrevious[groupId as string] ?? {
        total: 0,
        channels: {},
      };
      const existingChannelCount = groupCount.channels[cid as string] ?? {};
      const channelCount = existingChannelCount?.unreadCount ?? 0;
      const channelCountNew = channelCount + count;
      const totalCountNew = Math.max(0, groupCount.total + count);

      return {
        ...unreadCountPrevious,
        [groupId]: {
          total: totalCountNew,
          channels: {
            ...groupCount.channels,
            [cid]: {
              ...existingChannelCount,
              ...(!!phase && { phase }),
              ...(!!matchId && { matchId }),
              ...(!!tournamentId && { tournamentId }),
              unreadCount: channelCountNew,
              unseen,
            },
          },
        },
      };
    },
    [],
  );

  const incrementUnreadCount = useCallback(
    (
      groupId: string,
      cid: string,
      unseen: boolean,
      messages: FormatMessageResponse[],
    ) => {
      const messageLatestUserId = latestMessageUserId(messages);
      const isOwnMessage = userId === messageLatestUserId;

      if (isOwnMessage) {
        return;
      }

      setUnreadMessageCount((prev) => {
        return updateUnreadCount({
          unreadCountPrevious: prev,
          count: ONE,
          groupId,
          unseen,
          cid,
        });
      });
    },
    [updateUnreadCount, userId],
  );

  const resetUnreadCount = useCallback(
    (
      event: StreamEvent,
      groupId: string,
      cid: string,
      messages: FormatMessageResponse[],
    ) => {
      const { user: eventUser, type } = event;
      const messageLatestUserId = latestMessageUserId(messages);
      const readOwnMessage =
        eventUser?.id === messageLatestUserId && type === 'message.read';

      if (readOwnMessage) {
        return;
      }

      setUnreadMessageCount((prev) => {
        const unreadCount =
          prev[groupId as string]?.channels[cid as string].unreadCount ?? 0;
        const unreadCountSubtract = -unreadCount;
        return updateUnreadCount({
          unreadCountPrevious: prev,
          count: unreadCountSubtract,
          unseen: false,
          groupId,
          cid,
        });
      });
    },
    [updateUnreadCount],
  );

  const addChannel = useCallback(
    (channel: StreamChannel, channelGroupId: string) => {
      const {
        data,
        cid,
        state: { unreadCount, read },
      } = channel;

      const { matchId, tournamentId, groupName } = data as ChannelData<
        'match' | 'general'
      >;
      const groupId = toGroupId(channelGroupId, groupName, matchId);

      const unreadCountChannel = findExistingUnreadCount({
        unreadMessageCount,
        groupId,
        cid,
      });
      if (!!unreadCountChannel) {
        return;
      }

      const phase = realtimeChannelGroups.find((channelGroup) => {
        return channelGroup.id === channelGroupId;
      })?.phase;
      const unseen = !!read[userId as string]?.unread_messages;

      setUnreadMessageCount((prev) => {
        return updateUnreadCount({
          unreadCountPrevious: prev,
          count: unreadCount,
          groupId,
          cid,
          phase,
          matchId,
          tournamentId,
          unseen,
        });
      });

      const onMessageNew = () => {
        const messages = extractMessages(channel);
        incrementUnreadCount(groupId, cid, unseen, messages);
      };

      const onMessageRead = (event: StreamEvent) => {
        const messages = extractMessages(channel);
        resetUnreadCount(event, groupId, cid, messages);
      };

      channel.on('message.new', onMessageNew);
      channel.on('message.read', onMessageRead);

      return () => {
        channel.off('message.new', onMessageNew);
        channel.off('message.read', onMessageRead);
      };
    },
    [
      incrementUnreadCount,
      realtimeChannelGroups,
      resetUnreadCount,
      unreadMessageCount,
      updateUnreadCount,
      userId,
    ],
  );

  const unionChannel = useCallback(
    (channel: StreamChannel, channelGroupId: string) => {
      const { data, cid } = channel;
      const { matchId, groupName } = data as ChannelData<'match' | 'general'>;
      const groupId = toGroupId(channelGroupId, groupName, matchId);

      const unreadCountChannel = findExistingUnreadCount({
        unreadMessageCount,
        groupId,
        cid,
      });
      if (!!unreadCountChannel) {
        return;
      }
      addChannel(channel, channelGroupId);
    },
    [addChannel, unreadMessageCount],
  );

  useEffect(() => {
    if (!chatClient.user) {
      return;
    }

    const handler = async () => {
      const channelUnsubscribes: ChannelUnsubscribes = {};
      const channelPromises = subscribedGroupFilters.map(
        async (groupFilter) => {
          const channelGroupId = toChannelGroupId(groupFilter);

          if (!!groupUnsubscribesRef.current[channelGroupId as string]) {
            return;
          }

          const channelType = groupFilter[0].type;

          const isPersonal = PERSONAL_TYPES.includes(
            channelType as PersonalType,
          );
          const channelId = `${channelType}:${toChannelId(groupFilter[0])}`;

          const fetcher = new ChannelFetcher(chatClient);

          const channels = isPersonal
            ? [await fetcher.fetch(channelId)]
            : await fetcher.fetchGroup(groupFilter);

          channelUnsubscribes[channelGroupId as string] = channels.reduce(
            (prev, curr) => {
              if (curr) {
                const unsubscribe = addChannel(curr, channelGroupId);
                if (!unsubscribe) {
                  return prev;
                }
                prev.push(unsubscribe);
              }
              return prev;
            },
            [] as ChannelUnsubscribes[string],
          );
        },
      );
      await Promise.all(channelPromises);

      groupUnsubscribesRef.current = {
        ...groupUnsubscribesRef.current,
        ...channelUnsubscribes,
      };
    };

    handler();
    return () => {
      Object.values(groupUnsubscribesRef.current).forEach(
        (channelUnsubscribes) => {
          channelUnsubscribes.forEach((unsubscribe) => {
            unsubscribe?.();
          });
        },
      );
      groupUnsubscribesRef.current = {};
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatClient, subscribedGroupFilters.length]);

  const memoizedValue = useMemo(() => {
    return {
      unreadMessageCount,
      subscribedGroupFilters,
      unionChannel,
    };
  }, [subscribedGroupFilters, unionChannel, unreadMessageCount]);

  return (
    <UnreadMessageCountContext.Provider value={memoizedValue}>
      {children}
    </UnreadMessageCountContext.Provider>
  );
};

export const UnreadMessageCountProvider = memo(
  UnreadMessageCountProviderUnmemoized,
);

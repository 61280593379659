import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { Link } from '../Link';
import { useCallback } from 'react';
import Box from '@mui/material/Box';
import { memo } from '../../util/memo';

export const Footer = memo(function FooterUnmemoized() {
  const theme = useTheme();

  const linkTypographySx = {
    color: theme.palette.text.secondary,
    textDecorationColor: theme.palette.text.secondary,
  };

  const currentYear = new Date().getFullYear();

  const displayUspUi = useCallback(() => {
    if ('__uspapi' in window) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).__uspapi('displayUspUi');
    }
  }, []);

  return (
    <Box>
      {/* Top Divider */}
      <Box
        sx={{
          height: '1px',
          width: '100%',
          background: theme.palette.border,
        }}
      />
      <Stack
        direction="row"
        justifyContent="flex-end"
        height="50px"
        width="100%"
        sx={{ pr: 3 }}
      >
        <Stack
          sx={{
            flexDirection: { xs: 'column', md: 'row-reverse' },
            alignItems: { xs: 'flex-end', md: 'center' },
          }}
        >
          <Stack direction="row">
            <Link
              href="/docs/tos.html"
              style={{
                color: theme.palette.text.primary,
                textDecoration: 'none',
              }}
            >
              <span style={{ textDecoration: 'underline' }}>Copyright</span> ©
              {currentYear} BluMint Inc. All rights reserved.
            </Link>
          </Stack>
          <Stack direction="row" spacing={2} mx={{ xs: 0, md: 4 }}>
            <Link href="/docs/tos.html" style={linkTypographySx}>
              Terms Of Use
            </Link>
            <Link href="/docs/privacy.html" style={linkTypographySx}>
              Privacy Policy
            </Link>
            <Tooltip title="We use cookies and other data collection technologies to provide the best experience for our customers. You may request that your data not be shared with third parties here.">
              <Link
                href="#"
                onClick={displayUspUi}
                style={{ ...linkTypographySx, textDecoration: 'underline' }}
              >
                Do Not Sell My Info
              </Link>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
});

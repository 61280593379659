import { useMemo } from 'react';
import { memo } from '../../util/memo';
import { CallerHit } from './CallersVerticalCarousel';
import { CallerCardActive } from './CallerCardActive';
import { useCallerStatus } from '../../hooks/voice-chat/useCallerStatus';
import { CallerCardBase } from './CallerCardBase';
import { Track } from 'livekit-client';
import { useVoiceParticipant } from '../../hooks/voice-chat/useVoiceParticipant';
import {
  useLocalParticipant,
  useParticipantTracks,
} from '@livekit/components-react';

export type CallerCardProps = CallerHit;

const CallerCardUnmemoized = (hit: CallerCardProps) => {
  const { id: userId } = hit;
  const { status } = useCallerStatus(userId);
  const participant = useVoiceParticipant(userId);
  const { localParticipant } = useLocalParticipant();

  const caller = useMemo(() => {
    return participant || localParticipant;
  }, [participant, localParticipant]);

  const participantTracks = useParticipantTracks(
    [Track.Source.Microphone],
    userId,
  );

  const audioTrack = useMemo(() => {
    return participantTracks[0];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participantTracks.length]);

  const callerCard = useMemo(() => {
    return status === 'accepted' && !!caller && !!audioTrack ? (
      <CallerCardActive caller={caller} audioTrack={audioTrack} {...hit} />
    ) : (
      <CallerCardBase {...hit} />
    );
  }, [status, caller, audioTrack, hit]);

  // eslint-disable-next-line @blumintinc/blumint/no-useless-fragment
  return <>{callerCard}</>;
};

export const CallerCard = memo(CallerCardUnmemoized);

export const NEXTJS_SUPPORTED_IMG_FORMATS = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp',
  'image/avif',
] as const;

export const NEXTJS_SUPPORTED_IMG_ACCEPT =
  NEXTJS_SUPPORTED_IMG_FORMATS.join(',');

export const MESSAGE_ATTACHMENT_SUPPORTED_FORMATS = [
  'application/pdf',
  'audio/mp3',
  'audio/wav',
  'audio/m4a',
  'audio/flac',
  'audio/aac',
  'file/doc',
  'file/docx',
  'file/pdf',
  'file/ppt',
  'file/pptx',
  'file/txt',
  'file/xls',
  'file/xlsx',
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/webp',
  'image/heic',
  'image/heic-sequence',
  'image/heif',
  'image/heif-sequence',
  'image/svg+xml',
  'video/mp4',
  'video/ogg',
  'video/webm',
  'video/quicktime',
];

import React, {
  useMemo,
  FC,
  useEffect,
  useRef,
  useState,
  useCallback,
  type CSSProperties,
  type Ref,
} from 'react';
import { memo } from '../../util/memo';
import { SxProps } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import {
  AdDimension,
  AdHeight,
  AdWidth,
} from '../../../functions/src/util/ads/AdDimension';
import { useAdBlock } from '../../contexts/AdBlockContext';
import { sortedHash } from '../../../functions/src/util/hash/sortedHash';
import {
  EngagementTrackingProps,
  useEngagementTracking,
} from '../../hooks/engagement/useEngagementTracking';
import { useMobile } from '../../hooks/useMobile';
import { StopwatchCallbackMap } from '../../hooks/visibility/useVisibilityStopwatch';
import { useParentDimensions } from '../../hooks/useParentDimensions';
import { useVisibilityObserver } from '../../hooks/visibility/useVisibilityObserver';

export const MOBILE_AD_DISPLAY_MS = 30000 as const;
export const DESKTOP_AD_DISPLAY_MS = 30000 as const;

const LOAD_VISIBILITY_OPTIONS = {
  threshold: 0,
  rootMargin: '1000px',
} as const;

export type ResponsiveWidth = {
  width: '100%';
  height: AdHeight;
};

export type ResponsiveHeight = {
  width: AdWidth;
  height: '100%';
};

export type ResponsiveAdDimensions =
  | AdDimension
  | ResponsiveWidth
  | ResponsiveHeight;

export type AdSize = {
  adUnitId: string;
  width: number;
  height: number;
};

export type WithRef<TComponent> = TComponent & { ref: Ref<HTMLDivElement> };

export type AdContainerProps<TProps extends AdSize> = Pick<
  EngagementTrackingProps,
  'id'
> & {
  width: number | '100%';
  height: number | '100%';
  sx?: SxProps;
  borderRadius?: string;
  alignItems?: CSSProperties['alignItems'];
  justifyContent?: CSSProperties['justifyContent'];
  findAdSize: (dimensions: {
    width: number;
    height: number;
  }) => AdSize | undefined;
  AdInsert: FC<WithRef<TProps>>;
  refreshAd: (unit: AdSize) => Promise<TProps>;
  doIntervalRefresh?: boolean;
  visibilityOptions?: Omit<IntersectionObserverInit, 'root'>;
};

const AdContainerUnmemoized = <TProps extends AdSize>({
  id,
  width,
  height,
  sx,
  alignItems = 'center',
  justifyContent = 'center',
  borderRadius = '10px',
  findAdSize,
  AdInsert,
  refreshAd,
  doIntervalRefresh = true,
  visibilityOptions,
}: AdContainerProps<TProps>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const dimensions = useParentDimensions(width, height, containerRef);

  const adSize = useMemo(() => {
    if (dimensions.width === 0 || dimensions.height === 0) {
      return undefined;
    }
    return findAdSize(dimensions);
  }, [dimensions, findAdSize]);

  const { spyAd } = useAdBlock();

  const [adProps, setAdProps] = useState<TProps | null>(null);

  const loadAd = useCallback(async () => {
    if (!adSize) return;

    let spying: string | undefined;

    try {
      const props = await refreshAd(adSize);
      setAdProps(props);
    } catch (error) {
      console.error('Error refreshing ad:', error);
      setAdProps(null);

      const errorMessage = String(
        !!error && typeof error === 'object' && 'message' in error
          ? error.message
          : error,
      );
      spying = errorMessage;
    }

    setTimeout(() => {
      spyAd(spying);
    }, 100);
  }, [adSize, refreshAd, spyAd]);

  useEffect(() => {
    loadAd();
  }, [loadAd]);

  const containerSx = useMemo(() => {
    return {
      width: width === '100%' ? '100%' : `${adSize?.width || width}px`,
      height: height === '100%' ? '100%' : `${adSize?.height || height}px`,
      alignItems,
      justifyContent,
      ...sx,
    };
  }, [
    adSize?.height,
    adSize?.width,
    width,
    height,
    alignItems,
    justifyContent,
    sx,
  ]);

  const innerSx = useMemo(() => {
    return {
      overflow: 'hidden',
      borderRadius,
      width: adSize?.width ? `${adSize.width}px` : '100%',
      height: adSize?.height ? `${adSize.height}px` : '100%',
      alignItems,
      justifyContent,
    };
  }, [borderRadius, adSize?.width, adSize?.height, alignItems, justifyContent]);

  const [trackingTarget, setTrackingTarget] = useState<HTMLDivElement | null>(
    null,
  );
  const [idealTrackingTarget, setIdealTrackingTarget] =
    useState<HTMLDivElement | null>(null);

  const [visibilityTarget, setVisibilityTarget] =
    useState<HTMLDivElement | null>(null);
  const { isIntersecting: isVisible } = useVisibilityObserver({
    target: visibilityTarget,
    options: visibilityOptions || LOAD_VISIBILITY_OPTIONS,
  });
  const [becameVisible, setBecameVisible] = useState(false);
  useEffect(() => {
    if (isVisible) {
      setBecameVisible(true);
    }
  }, [isVisible]);

  const adInsert = useMemo(() => {
    if (!adProps || !becameVisible) {
      return undefined;
    }
    return (
      <div ref={setIdealTrackingTarget}>
        <AdInsert ref={setTrackingTarget} {...adProps} />
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdInsert, sortedHash(adProps || {}), becameVisible]);

  const isMobile = useMobile();
  const adDisplayMs = isMobile ? MOBILE_AD_DISPLAY_MS : DESKTOP_AD_DISPLAY_MS;

  const engagementStopwatchCallbacks = useMemo((): StopwatchCallbackMap => {
    return {
      [adDisplayMs]: doIntervalRefresh
        ? loadAd
        : (reset) => {
            reset();
          },
    };
  }, [adDisplayMs, doIntervalRefresh, loadAd]);
  const idealEngagementStopwatchCallbacks =
    useMemo((): StopwatchCallbackMap => {
      return {
        [adDisplayMs]: (reset) => {
          reset();
        },
      };
    }, [adDisplayMs]);

  const trackingProps = useMemo(() => {
    return {
      id,
      data: {
        adUnitId: adSize?.adUnitId,
      },
      target: trackingTarget,
      idealTarget: idealTrackingTarget,
      callbacks: engagementStopwatchCallbacks,
      idealCallbacks: idealEngagementStopwatchCallbacks,
    };
  }, [
    adSize?.adUnitId,
    engagementStopwatchCallbacks,
    idealEngagementStopwatchCallbacks,
    id,
    trackingTarget,
    idealTrackingTarget,
  ]);
  useEngagementTracking(trackingProps);

  return (
    <Stack sx={containerSx} ref={containerRef}>
      <Stack sx={innerSx} ref={setVisibilityTarget}>
        {adInsert}
      </Stack>
    </Stack>
  );
};

export const AdContainer = memo(AdContainerUnmemoized);

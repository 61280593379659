import { LoadingButton } from './LoadingButton';
import { memo } from '../../util/memo';
import { SxProps } from '@mui/material/styles';
import { GradientTypography } from '../gradients/GradientTypography';
import { usePersonalChannelGroup } from '../../hooks/messaging/usePersonalChannelGroup';
import { useCallback } from 'react';
import { useActiveChannelGroup } from '../../contexts/ActiveChannelGroupContext';

export type SupportChatButtonProps = {
  sx?: SxProps;
  channelData?: Record<string, string>;
  gradientColor?: string;
};

const SupportChatButtonUnmemoized = ({
  sx,
  gradientColor = 'primary.vertical',
}: SupportChatButtonProps) => {
  const { openChannelGroup } = useActiveChannelGroup();
  const { set: setPersonalChannelGroup } = usePersonalChannelGroup();

  const createSupport = useCallback(async () => {
    const { id } = await setPersonalChannelGroup({
      type: 'support',
      messagesInitial: [
        {
          text: 'Welcome to BluMint Support. Leave a message and a member of our team will get back to you shortly :)',
        },
      ],
      friendIds: [],
    });

    openChannelGroup(id);
  }, [setPersonalChannelGroup, openChannelGroup]);
  return (
    <LoadingButton variant="text" sx={sx} onClick={createSupport}>
      <GradientTypography variant="buttonMedium" gradientColor={gradientColor}>
        SUPPORT
      </GradientTypography>
    </LoadingButton>
  );
};

export const SupportChatButton = memo(SupportChatButtonUnmemoized);

import Stack from '@mui/material/Stack';
import { Logo } from '../Logo';
import { Link } from '../Link';
import { ImageOptimized } from '../image/ImageOptimized';
import { memo } from '../../util/memo';
import useMediaQuery from '@mui/material/useMediaQuery';

export type LogoSloganProps = {
  displaySloganMinWidth?: string;
};

const LogoSloganUnmemoized = ({
  displaySloganMinWidth = '600px',
}: LogoSloganProps) => {
  const showSlogan = useMediaQuery(`(min-width:${displaySloganMinWidth})`);

  return (
    <Link href="/" style={{ textDecoration: 'none', flexShrink: 0 }}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Logo width={40} height={40} priority loading="eager" asLink={false} />
        {showSlogan && (
          <ImageOptimized
            src={'/assets/images/logo_no_slogan.png'}
            alt={'BluMint - Become a Champion'}
            priority={true}
            height={30}
            width={160}
            style={{ marginTop: -1 }}
          />
        )}
      </Stack>
    </Link>
  );
};

export const LogoSlogan = memo(LogoSloganUnmemoized);

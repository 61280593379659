import { memo } from '../../util/memo';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { AvatarNext } from '../avatar/AvatarNext';
import { GradientIcon } from '../gradients/GradientIcon';
import ShieldIcon from '@mui/icons-material/ShieldRounded';
import Typography from '@mui/material/Typography';
import { useMobile } from 'src/hooks/useMobile';
import { GradientTypography } from '../gradients/GradientTypography';
import { GradientBorder } from 'src/components/gradients/GradientBorder';
import { useTheme } from '@mui/material/styles';
import { TypographyTruncated } from '../TypographyTruncated';
import { FormattedPayoutSummary } from '../tournaments/FormattedPayoutSummary';
import { Token } from '../../../functions/src/types/firestore/User/Payout';

export type FeaturedCompetitorProps = {
  imgUrl?: string;
  name: string;
  rank: number;
  payout?: Token[];
  score?: string;
  isSolo?: boolean;
};

export const FeaturedCompetitor = memo(function FeaturedCompetitorUnmemoized({
  imgUrl,
  name,
  rank,
  payout = [],
  score,
  isSolo,
}: FeaturedCompetitorProps) {
  const isMobile = useMobile();
  const theme = useTheme();
  const hasScore = !!score && Number(score) > 0;

  return (
    <GradientBorder
      disableBorder
      borderRadius="16px"
      p={2}
      sx={{ width: '100%', height: '150px' }}
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        sx={{
          m: 2,
        }}
      >
        <Box
          sx={{
            borderRadius: '100%',
            border: 'solid 4px transparent',
            backgroundOrigin: 'border-box',
            backgroundClip: 'content-box, border-box',
            position: 'relative',
            mb: hasScore && !!payout ? 7 : undefined,
          }}
        >
          <AvatarNext src={imgUrl} alt={name} height={90} width={90} />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              bottom: -24,
              right: -6,
            }}
          >
            <GradientIcon
              IconComponent={ShieldIcon}
              gradientColor={
                rank === 1
                  ? theme.palette.warning.horizontal
                  : rank === 2
                  ? 'linear-gradient(90deg, #CBCBCB 0%, #7A7A7A 100%)'
                  : theme.palette.error.horizontal
              }
              sx={{
                height: '62px',
                width: '45.5px',
              }}
            />
            {rank <= 3 && rank !== 0 && (
              <Typography
                variant="h4"
                color="text.primary"
                sx={{
                  position: 'absolute',
                  mr: rank === 1 ? '2px' : undefined,
                  mb: rank === 2 ? '2px' : undefined,
                }}
              >
                {rank}
              </Typography>
            )}
          </Box>
        </Box>
        <Box sx={{ ml: 4, mt: isMobile ? 4 : 0 }}>
          <TypographyTruncated
            text={name}
            textMaxLength={15}
            color="text.primary"
            variant="h6"
            sx={{
              textAlign: 'center',
              pointerEvents: isSolo ? undefined : 'none',
            }}
          />
          <Stack
            flexDirection="row"
            justifyContent={hasScore && !!payout ? 'space-between' : 'center'}
            alignItems="center"
          >
            {payout.length > 0 && (
              <FormattedPayoutSummary
                payoutSingle={payout}
                direction={'column'}
                variant="h6"
              />
            )}
            {!!score && (
              <GradientTypography
                gradientColor="primary.vertical"
                variant="subtitle1"
                sx={{ textAlign: 'center' }}
              >
                {score}
              </GradientTypography>
            )}
          </Stack>
        </Box>
      </Stack>
    </GradientBorder>
  );
});

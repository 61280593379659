import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { useMemo, useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import { GradientTypography } from '../gradients/GradientTypography';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { CallersPreview } from './CallersPreview';
import { CallControls } from './CallControls';
import { Callers } from './Callers';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';
import { GradientIcon } from '../gradients/GradientIcon';
import { useAuth } from '../../contexts/AuthContext';
import { useCallerConnection } from '../../hooks/voice-chat/useCallerConnection';
import { useHover } from '../../contexts/HoverContext';
import { useRoledUser } from '../../hooks/messaging/useRoledUser';
import { useCallerStatus } from '../../hooks/voice-chat/useCallerStatus';
import { useVoiceChat } from '../../contexts/voice-chat/VoiceChatContext';
import { useConnectionStatus } from '../../hooks/voice-chat/useConnectionStatus';
import { useChatContext as useStreamChatContext } from 'stream-chat-react';
import { useVoiceChannel } from '../../hooks/voice-chat/useVoiceChannel';
import { useActiveChannelGroup } from '../../contexts/ActiveChannelGroupContext';
import { toRoomId } from '../../../functions/src/util/liveKit/toRoomId';
import { toRoomPath } from '../../../functions/src/util/liveKit/toRoomPath';
import { LottieLoader } from '../LottieLoader';
import { useRoomContext as useLivekitRoom } from '@livekit/components-react';
import { memo } from '../../util/memo';

export const VOICE_WIDGET_TITLE = 'voice';

const VoiceChatToolbarUnmemoized = () => {
  const theme = useTheme();
  const { userData } = useAuth();
  const { isHovered } = useHover();
  const { hasAnyRole, hasRole: isStreamer } = useRoledUser(['streamer']);
  const { request } = useCallerConnection();
  const { mode } = useVoiceChat();
  const { setVoiceChannel } = useVoiceChannel();
  const { status } = useCallerStatus();
  const { isConnected, isConnecting } = useConnectionStatus();
  const { channel } = useStreamChatContext();
  const { channelGroupId } = useActiveChannelGroup();
  const room = useLivekitRoom();

  const name = useMemo(() => {
    const { type } = channel || {};
    return type === 'general' ? mode : VOICE_WIDGET_TITLE;
  }, [channel, mode]);

  const setCurrentVoiceChannel = useCallback(async () => {
    if (
      !!status ||
      !channelGroupId ||
      !channel ||
      (name === 'arena' && isStreamer)
    ) {
      return;
    }

    if (mode === 'studio' && !hasAnyRole) {
      return await request();
    }

    setVoiceChannel({
      current: toRoomId(
        toRoomPath({
          segments: [channelGroupId, channel.cid, mode],
        }),
      ),
    });
  }, [
    status,
    channelGroupId,
    channel?.cid,
    name,
    isStreamer,
    mode,
    hasAnyRole,
    setVoiceChannel,
    request,
  ]);

  const expandedContent = useMemo(() => {
    return isHovered && <Callers />;
  }, [isHovered]);

  const isCorrectRoom = room?.name.includes(mode);

  const callControls = useMemo(() => {
    return status === 'accepted' && isConnected && isCorrectRoom ? (
      <CallControls />
    ) : isConnecting && isCorrectRoom ? (
      <Stack
        direction="row"
        width="100%"
        alignItems="center"
        justifyContent="flex-end"
      >
        <LottieLoader sx={{ height: '24px', width: '24px' }} />
      </Stack>
    ) : null;
  }, [status, isConnected, isCorrectRoom, isConnecting]);

  const lockIcon = useMemo(() => {
    return (
      !hasAnyRole &&
      mode === 'studio' &&
      (status === 'accepted' && isConnected && isCorrectRoom ? (
        <GradientIcon
          IconComponent={LockOpenRoundedIcon}
          sx={{ width: '18px' }}
        />
      ) : (
        <GradientIcon IconComponent={LockRoundedIcon} sx={{ width: '18px' }} />
      ))
    );
  }, [hasAnyRole, isConnected, isCorrectRoom, mode, status]);

  const joinInstruction = useMemo(() => {
    return (
      !isConnecting &&
      !status &&
      isHovered &&
      !(mode === 'arena' && isStreamer) && (
        <Chip
          sx={{ py: 0 }}
          label={
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{ justifySelf: 'flex-end' }}
            >
              {!userData?.id
                ? 'Sign In To Join'
                : mode === 'arena' || hasAnyRole
                ? 'Join'
                : 'Request'}
            </Typography>
          }
        />
      )
    );
  }, [
    status,
    isHovered,
    userData?.id,
    mode,
    isConnecting,
    isStreamer,
    hasAnyRole,
  ]);

  const tooltipTitle = useMemo(() => {
    return mode === 'studio'
      ? isStreamer
        ? 'This is your studio. A private chat for guests to join and chat with you.'
        : 'Request to chat with the streamer.'
      : isStreamer
      ? 'Sorry, you cannot join the arena. If you wish to talk to participants, have them request to join your studio!'
      : undefined;
  }, [mode, isStreamer]);

  const toolbarContent = useMemo(() => {
    return (
      <Stack
        spacing={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={setCurrentVoiceChannel}
        sx={{
          px: 2,
          height: '45px',
          width: '100%',
          borderRadius: `${isHovered ? 0 : 10}px ${
            isHovered ? 0 : 10
          }px 10px 10px`,
          background: theme.palette.background.elevationSolid[12],
          ':hover': {
            cursor:
              (mode === 'arena' && isStreamer) || !!status ? 'auto' : 'pointer',
            background:
              (mode === 'arena' && isStreamer) || !!status
                ? undefined
                : theme.palette.background.elevationSolid[24],
          },
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <CallersPreview />
          <GradientTypography variant="buttonLarge">{name}</GradientTypography>
          {lockIcon}
        </Stack>
        {callControls}
        {joinInstruction}
      </Stack>
    );
  }, [
    isHovered,
    theme,
    mode,
    isStreamer,
    status,
    setCurrentVoiceChannel,
    name,
    lockIcon,
    callControls,
    joinInstruction,
  ]);

  return (
    <Stack>
      {expandedContent}
      {!!tooltipTitle && !status ? (
        <Tooltip arrow title={tooltipTitle} placement="bottom">
          {toolbarContent}
        </Tooltip>
      ) : (
        toolbarContent
      )}
    </Stack>
  );
};

export const VoiceChatToolbar = memo(VoiceChatToolbarUnmemoized);

import { CalendarDay } from '../../calendar/calendar-daily/CalendarDay';
import { toLocalMidnightDate } from '../../../../functions/src/util/date/paramsUtc';
import { EventKeyed } from '../../../contexts/EventsLazyContext';
import { FC, ReactNode, useCallback, useMemo } from 'react';
import { memo } from '../../../util/memo';
// import { HighlightWrapper } from './HighlightWrapper';

// const isCurrentDay = (dateFormatted: string): boolean => {
//   const today = new Date();
//   const todayFormatted =
//     today.getFullYear() +
//     '-' +
//     String(today.getMonth() + 1).padStart(2, '0') +
//     '-' +
//     String(today.getDate()).padStart(2, '0');

//   return dateFormatted === todayFormatted;
// };

export type CalendarDayTodaylightProps = {
  dateString: string;
  eventsOfDate: EventKeyed[];
  height: string;
  extension: ReactNode;
  onIntersect: (dateString: string) => void;
  intersected: boolean;
};

const CalendarDayTodaylightUnmemoized: FC<CalendarDayTodaylightProps> = ({
  dateString,
  eventsOfDate,
  height,
  extension,
  onIntersect,
  intersected,
}) => {
  // const highlightToday = isCurrentDay(dateString);

  const onIntersectThisDate = useCallback(() => {
    return onIntersect(dateString);
  }, [onIntersect, dateString]);

  const calendarDay = useMemo(() => {
    return (
      <CalendarDay
        date={toLocalMidnightDate(dateString)}
        events={eventsOfDate}
        height={height}
        extension={extension}
        onIntersect={onIntersectThisDate}
        intersected={intersected}
      />
    );
  }, [
    dateString,
    eventsOfDate,
    height,
    extension,
    onIntersectThisDate,
    intersected,
  ]);

  return calendarDay;
  // if (!highlightToday) return calendarDay;
  // return <HighlightWrapper>{calendarDay}</HighlightWrapper>;
};

export const CalendarDayTodaylight = memo(CalendarDayTodaylightUnmemoized);

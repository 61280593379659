import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { memo } from '../../../util/memo';
import { ReactNode, useMemo } from 'react';
import { ViewerCount } from './ViewerCount';
import { ViewerCountHoverable } from './ViewerCountHoverable';
import { SxProps } from '@mui/material/styles';
import { DownloadLivestreamButton } from './DownloadLivestreamButton';
import { useActiveChannelGroup } from '../../../contexts/ActiveChannelGroupContext';

export type ViewerCountDynamicProps = {
  children?: ReactNode;
  sx?: SxProps;
};

const ViewerCountDynamicUnmemoized = ({
  children,
  sx = {},
}: ViewerCountDynamicProps) => {
  const { channelGroupActive } = useActiveChannelGroup();
  const fileName = `${channelGroupActive?.title}-livestream.mp4`;

  const downloadLivestream = useMemo(() => {
    return (
      !!channelGroupActive && <DownloadLivestreamButton fileName={fileName} />
    );
  }, [channelGroupActive, fileName]);

  if (children) {
    return (
      <>
        {children}
        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          sx={sx}
        >
          <ViewerCountHoverable />
          {downloadLivestream}
        </Stack>
      </>
    );
  }

  return (
    <Box sx={sx}>
      <ViewerCount />
    </Box>
  );
};

export const ViewerCountDynamic = memo(ViewerCountDynamicUnmemoized);

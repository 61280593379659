import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { memo } from '../../../util/memo';
import { useAuthFlowDialog } from '../../../hooks/auth/useAuthFlowDialog';
import { INPUT_AREA_HEIGHT } from '../channel/ChannelInner';
import Button from '@mui/material/Button';

const NoticeSignInUnmemoized = () => {
  const { open: openAuthDialog } = useAuthFlowDialog();

  return (
    <Stack
      height={INPUT_AREA_HEIGHT}
      justifyContent="center"
      alignItems="center"
      direction="row"
      spacing={1}
      sx={{ pb: { xs: 2, md: 4 } }}
    >
      <Button
        variant="text"
        onClick={() => {
          openAuthDialog();
        }}
        sx={{
          boxShadow: 'none',
          '.MuiTypography-root': {
            p: 0,
          },
        }}
      >
        Sign Up / Sign In
      </Button>
      <Typography variant="body1" color="text.secondary">
        to join the chat!
      </Typography>
    </Stack>
  );
};

export const NoticeSignIn = memo(NoticeSignInUnmemoized);

import { useState, useMemo, useCallback } from 'react';
import { memo } from '../../util/memo';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/AddRounded';
import HelpOutlineIcon from '@mui/icons-material/HelpOutlineRounded';
import { GradientIcon } from '../gradients/GradientIcon';
import { useAuth } from '../../contexts/AuthContext';
import { LottieLoader } from '../LottieLoader';
import { usePersonalChannelGroup } from '../../hooks/messaging/usePersonalChannelGroup';
import { useActiveChannelGroup } from '../../contexts/ActiveChannelGroupContext';
import { useSubgroupPermissions } from '../../hooks/group/useSubgroupPermissions';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { GroupChannelCreationDialog } from '../tournaments/registration/dialogs/GroupChannelCreateDialog';
import { useDialog } from '../../hooks/useDialog';

export type ChatMenuProps = Omit<MenuProps, 'open'> & {
  createChat: () => Promise<void> | void;
  onCreateSupport?: () => Promise<void> | void;
};

const ChatMenuUnmemoized = ({
  anchorEl,
  createChat,
  onCreateSupport,
  ...props
}: ChatMenuProps) => {
  const { uid } = useAuth();
  const { set: setPersonalChannelGroup } = usePersonalChannelGroup();
  const { openChannelGroup } = useActiveChannelGroup();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const subgroupPermissions = useSubgroupPermissions();

  const canManageChannels = subgroupPermissions.includes('manageChannels');

  const createSupportChat = useCallback(async () => {
    if (!uid) {
      return;
    }
    setIsLoading(true);

    const { id } = await setPersonalChannelGroup({
      type: 'support',
      friendIds: [],
      messagesInitial: [
        {
          text: 'Welcome to BluMint Support. Drop your message below and a member of our team will get back to you shortly :)',
        },
      ],
    });
    openChannelGroup(id);
    await onCreateSupport?.();
    setIsLoading(false);
  }, [uid, setPersonalChannelGroup, openChannelGroup, onCreateSupport]);

  const { open: openChannelCreationDialog, close: closeChannelCreationDialog } =
    useDialog('groupChannelCreation');

  const createGroupChannel = useMemo(() => {
    return () => {
      openChannelCreationDialog({
        children: (
          <GroupChannelCreationDialog
            onSubmit={() => {
              closeChannelCreationDialog();
            }}
            onCancel={closeChannelCreationDialog}
          />
        ),
      });
    };
  }, [openChannelCreationDialog, closeChannelCreationDialog]);

  const menuItems = useMemo(() => {
    const items = [
      {
        onClick: createChat,
        label: 'Create Chat',
        Icon: AddIcon,
      },
      {
        onClick: createSupportChat,
        label: 'Create Support Chat',
        Icon: HelpOutlineIcon,
      },
    ];

    if (canManageChannels) {
      items.push({
        onClick: createGroupChannel,
        label: 'Create Group Channel',
        Icon: GroupAddIcon,
      });
    }

    return items;
  }, [createChat, createSupportChat, canManageChannels, createGroupChannel]);

  const menuItemElements = useMemo(() => {
    return menuItems.map(({ onClick, label, Icon }) => {
      return (
        <MenuItem onClick={onClick} sx={{ maxWidth: '230px' }} key={label}>
          <Stack
            direction="row"
            width="100%"
            alignItems="center"
            justifyContent="flex-start"
            spacing={2}
          >
            {isLoading && label === 'Create Support Chat' ? (
              <LottieLoader sx={{ width: '20px', height: '20px' }} />
            ) : (
              <GradientIcon
                gradientColor="primary.horizontal"
                IconComponent={Icon}
                sx={{
                  height: '24px',
                  width: '24px',
                  background: 'transparent',
                }}
              />
            )}
            <Typography
              variant="body1"
              color="text.primary"
              sx={{ pr: 3, textTransform: 'capitalize' }}
            >
              {label}
            </Typography>
          </Stack>
        </MenuItem>
      );
    });
  }, [isLoading, menuItems]);

  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={onCreateSupport}
      {...props}
    >
      {menuItemElements}
    </Menu>
  );
};

export const ChatMenu = memo(ChatMenuUnmemoized);

import { ELEVATIONS } from '../../elevations';
import { PALETTE } from '../../palette';
import { SHADOWS_HARD, highlight } from '../../shadows';

const BUTTON_PALETTE_COLORS = [
  'primary',
  'secondary',
  'warning',
  'error',
  'success',
  'info',
  'royal',
];

const buildContainedVariant = (color: string, direction: string) => {
  return {
    props: {
      variant: 'contained' as const,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      color: color as any,
      disabled: false,
    },
    style: {
      background: PALETTE[`${color}`][`${direction}`],
      boxShadow: SHADOWS_HARD[`${color}Elevation2`],
      '&:hover': {
        background: `${PALETTE[`${color}`][`${direction}Light`]}`,
        boxShadow: SHADOWS_HARD[`${color}Elevation2`],
      },
      transition: '0.15s ease-out all',
    },
  };
};

const buildOutlinedVariant = (color: string) => {
  return {
    props: {
      variant: 'outlined' as const,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      color: color as any,
      disabled: false,
    },
    style: {
      lineHeight: 0,
      border: `double 1px ${PALETTE[`${color}`].main}`,
      borderRadius: '5px',
      background: `transparent`,
      padding: '5px 10px',
      '&:hover': {
        background: ELEVATIONS['15'], // Removed unnecessary template literal
        transform: 'translateY(-1px)',
      },
      transition: '0.15s ease-out all',
    },
  };
};

const buildTextVariant = (color: string) => {
  return {
    props: {
      variant: 'text' as const,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      color: color as any,
      disabled: false,
    },
    style: {
      background: PALETTE[`${color}`].vertical,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      backgroundClip: 'text',
      ...highlight(PALETTE[`${color}`].main),
    },
  };
};

export const BUTTON_VARIANTS_CONTAINED = [
  ...BUTTON_PALETTE_COLORS.map((color) => {
    return buildContainedVariant(color, 'horizontal');
  }),
];

export const BUTTON_VARIANTS_OUTLINED = BUTTON_PALETTE_COLORS.map((color) => {
  return buildOutlinedVariant(color);
});

export const BUTTON_VARIANTS_TEXT = BUTTON_PALETTE_COLORS.map((color) => {
  return buildTextVariant(color);
});

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Diversity3Rounded from '@mui/icons-material/Diversity3Rounded';
import { GradientIcon } from '../gradients/GradientIcon';
import { toNoContentText } from '../../util/algolia/toNoContentText';
import { memo } from '../../util/memo';
import { FC, useMemo } from 'react';

export type NoContentProps = {
  variant: string;
  isSelf: boolean;
  username?: string;
  extra?: string;
};

const NoContentUnmemoized: FC<NoContentProps> = ({
  variant,
  isSelf,
  username,
  extra,
}) => {
  const title = useMemo(() => {
    if (isSelf || !username) {
      return (
        <Typography variant="h6">{`No ${variant.replace(
          '_',
          ' ',
        )}!`}</Typography>
      );
    }
    return <></>;
  }, [isSelf, username, variant]);

  const body = useMemo(() => {
    const textForVariant = toNoContentText(username, extra, variant);
    const text = isSelf ? textForVariant.self : textForVariant.other;
    return <Typography variant="body1">{text}</Typography>;
  }, [username, extra, variant, isSelf]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 2,
      }}
    >
      <Box
        sx={{
          width: '40%',
          display: 'flex',
          justifyContent: 'center',
          flexShrink: 1,
        }}
      >
        <GradientIcon
          IconComponent={Diversity3Rounded}
          sx={{
            width: '100%',
            height: '100%',
            maxWidth: 150,
            maxHeight: 150,
            p: 4,
          }}
        />
      </Box>
      <Box sx={{ width: '60%', textAlign: 'center' }}>
        {title}
        {body}
      </Box>
    </Box>
  );
};

export const NoContent = memo(NoContentUnmemoized);
